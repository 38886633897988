import React from "react"
import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button } from "@mui/material"

export const PEOPMSATMOSaveChangesWarningModal = ({ open, setOpen, saveChanges, msg }) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{msg}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          All rows in the table will have their User Case ID & Line ID associated unless the row has already been associated. Rows marked for removal
          will have their User Case ID & Line ID disassociated.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ color: "black", border: "2px solid black" }}
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
        <Button
          style={{ backgroundColor: "red", color: "white", fontWeight: "bold", border: "2px solid black" }}
          onClick={() => {
            saveChanges()
            setOpen(false)
          }}
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { Dialog, DialogTitle, DialogContent, CircularProgress } from "@mui/material"
import { useRef } from "react"
import { CSVUploadProgressMessageTypes } from "../../Utils"
import GradientCircularProgress from "../elements/GradientCircularProgress"
// import '../RFIs/RFIs.css'

const UploadProgressModal = ({ open, title, setTitle, fileSize, uploadCompleted, trackProgress }) => {
  const [loadingProgress, setLoadingProgress] = useState(0)
  let loadingIntervalId = useRef(null)

  const incrementLoadingProgress = () => {
    // Increment loading spinner progress by 2%
    setLoadingProgress((prevLoadingProgress) => {
      if (prevLoadingProgress < 100) return prevLoadingProgress + 2
      return 100
    })
  }

  const initiateLoadingSpinner = () => {
    if (title !== CSVUploadProgressMessageTypes.FINISH) {
      setLoadingProgress(0)
      // The estimatedUploadSpeed will be an estimate of how long it takes the upload to progress by 2% in milliseconds
      let estimatedTimeBetweenProgressUpdates = fileSize / 100000
      switch (title) {
        case CSVUploadProgressMessageTypes.PREPARE:
          loadingIntervalId.current = setInterval(incrementLoadingProgress, estimatedTimeBetweenProgressUpdates * 0.05)
          break
        case CSVUploadProgressMessageTypes.DELETE:
          loadingIntervalId.current = setInterval(incrementLoadingProgress, estimatedTimeBetweenProgressUpdates * 0.25)
          break
        case CSVUploadProgressMessageTypes.POST:
          loadingIntervalId.current = setInterval(incrementLoadingProgress, estimatedTimeBetweenProgressUpdates)
          break
        case CSVUploadProgressMessageTypes.RESTORE:
          loadingIntervalId.current = setInterval(incrementLoadingProgress, estimatedTimeBetweenProgressUpdates)
          break
        case CSVUploadProgressMessageTypes.EXPORT:
          loadingIntervalId.current = setInterval(incrementLoadingProgress, estimatedTimeBetweenProgressUpdates)
          break
        default:
          break
      }
    }
  }

  useEffect(() => {
    if (!trackProgress) return
    // If the upload completes before our loading spinner completes its cycle, automatically set the loadingProgress to 100% and clear the interval
    if (uploadCompleted) {
      setLoadingProgress(100)
      clearInterval(loadingIntervalId.current)
    }
    // If the loading spinner completes its cycle before the upload completes, clear the inverval and update the title to let the user know the upload is finishing up
    // The goal is to avoid this scenario as much as possible
    if (loadingProgress >= 100) {
      if (title === CSVUploadProgressMessageTypes.POST || title === CSVUploadProgressMessageTypes.RESTORE) {
        setTitle(CSVUploadProgressMessageTypes.FINISH)
      }
      setLoadingProgress(100)
      clearInterval(loadingIntervalId.current)
    }
  }, [loadingProgress, uploadCompleted])

  useEffect(() => {
    if (!trackProgress) return
    if (open || fileSize > 0) {
      clearInterval(loadingIntervalId.current)
      initiateLoadingSpinner()
    }
    console.log(title)
  }, [title, open])

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent className="circular-progress">
        {/* {trackProgress ? 
                    <CircularProgress variant='determinate' value={loadingProgress} />
                :
                    <CircularProgress variant='indeterminate' />
                } */}
        <GradientCircularProgress
        // variant="determinate"
        // value={90}
        />
      </DialogContent>
    </Dialog>
  )
}

export default UploadProgressModal

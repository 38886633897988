import { Box, CircularProgress } from "@mui/material"

// Styles
import "./LoadingViewStyles.css"

const LoadingView = () => {
  return (
    <Box className="lvBox">
      <svg
        width={0}
        height={0}
      >
        <defs>
          <linearGradient
            id="my_gradient"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop
              offset="0%"
              stopColor="rgba(28, 42, 108, 1)"
            />
            <stop
              offset="100%"
              stopColor="rgba(28, 42, 108, .3)"
            />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        size={50}
        thickness={2}
        sx={{ "svg circle": { stroke: "url(#my_gradient)" }, "& .MuiCircularProgressBar": "8s" }}
      />
    </Box>
  )
}

export default LoadingView

import { Box } from "@mui/material"
import { PieChart } from "@mui/x-charts/PieChart"

const CustomPieChart = ({ data }) => {
  const getSeries = () => {
    const labels = {
      casesInDevelopment: "Cases in Development",
      casesInExecution: "Cases in Execution",
      linesInExecution: "Lines in Execution",
      casesInClosure: "Cases in Closure",
    }
    let keys = Object.keys(data)
    keys.shift()

    let mapped = keys.map((item, index) => {
      return {
        id: index,
        value: data[item],
        label: labels[item],
      }
    })

    return mapped
  }

  const getHasData = () => {
    if (data?.casesInClosure > 0 || data?.casesInExecution > 0 || data?.linesInExecution > 0 || data?.casesInDevelopment > 0) {
      return true
    }
  }

  if (getHasData()) {
    return (
      <PieChart
        series={[
          {
            data: getSeries(),
          },
        ]}
        width={900}
        height={250}
      />
    )
  } else {
    return <Box>No Data</Box>
  }
}

export default CustomPieChart

import ConfigData from "../config.json"
import axios from "axios"

const BASE_URL = ConfigData.BASE_URL
const ODATA = ConfigData.ODATA

const addQueryParam = (paramType, paramValue, firstQueryInURL) => {
  if (firstQueryInURL) {
    return `&%24${paramType}=${paramValue}`
  }
  return `?%24${paramType}=${paramValue}`
}

const addQueryParams = (url, queryParams) => {
  let tmpUrl = url
  let firstQueryInURL = false
  if (queryParams?.[QueryParamTypes.TOP]) {
    tmpUrl += addQueryParam(QueryParamTypes.TOP, queryParams[QueryParamTypes.TOP], firstQueryInURL)
    firstQueryInURL = true
  }
  if (queryParams?.[QueryParamTypes.SKIP]) {
    tmpUrl += addQueryParam(QueryParamTypes.SKIP, queryParams[QueryParamTypes.SKIP], firstQueryInURL)
    firstQueryInURL = true
  }
  if (queryParams?.[QueryParamTypes.FILTER]) {
    tmpUrl += addQueryParam(QueryParamTypes.FILTER, queryParams[QueryParamTypes.FILTER], firstQueryInURL)
    firstQueryInURL = true
  }
  if (queryParams?.[QueryParamTypes.SELECT]) {
    tmpUrl += addQueryParam(QueryParamTypes.SELECT, queryParams[QueryParamTypes.SELECT], firstQueryInURL)
    firstQueryInURL = true
  }
  if (queryParams?.[QueryParamTypes.ORDER_BY]) {
    tmpUrl += addQueryParam(QueryParamTypes.ORDER_BY, queryParams[QueryParamTypes.ORDER_BY], firstQueryInURL)
    firstQueryInURL = true
  }
  if (queryParams?.[QueryParamTypes.EXPAND]) {
    tmpUrl += addQueryParam(QueryParamTypes.EXPAND, queryParams[QueryParamTypes.EXPAND], firstQueryInURL)
    firstQueryInURL = true
  }
  return tmpUrl
}

export const QueryParamTypes = {
  TOP: "top",
  SKIP: "skip",
  FILTER: "filter",
  SELECT: "select",
  ORDER_BY: "orderby",
  EXPAND: "expand",
}

// This method handles server-side pagination, sorting and filtering (soon)
// based on the MUI DataGrid's paginationModel, sortModel and filterModel properties.
const getAllServer = async (apiType, paginationModel=null, sortModel=null, filterModel=null) => {
  let url = `${ODATA}/${apiType}`
  
  let params = { "$count": "true" };
  if(paginationModel) {
    params = { "$top": paginationModel.pageSize, "$skip": paginationModel.pageSize * paginationModel.page, ...params}
  }

  // Add sorting 
  if(sortModel) {
    for (const sort of sortModel) {
      let orderBy = sort.field + " " + sort.sort;
      params = { "$orderBy": orderBy, ...params }
    }
  }

  // TODO: Add filtering
  if(filterModel) {
    console.log("Not implemented yet.")
  }

  let response = await axios.get(url, { params: params });
  console.log(response);
  //return the response data itself and the total count

  return [response.data.value, response.data["@odata.count"]];
}

const axiosGetAllCount = (apiType, queryParams) => {
  let url = addQueryParams(`${ODATA}/${apiType}/$count`, queryParams)
  return axios.get(url).then((res) => res.data)
}

const axiosGetAll = (apiType, queryParams) => {
  let url = addQueryParams(`${BASE_URL}/${apiType}`, queryParams)
  return axios.get(url).then((res) => res.data)
}

const fetchData = (apiType, query) => {
  let url = query ? `${BASE_URL}/${apiType}/${query}` : `${BASE_URL}/${apiType}`
  return axios.get(url)
}

const axiosGetById = (apiType, id) => {
  if (id) {
    return axios.get(`${BASE_URL}/${apiType}/${id}`).then((res) => res.data)
  }
  return axios.get(`${BASE_URL}/${apiType}`).then((res) => res.data)
}

//
const testPost = (url, body) => {
  return axios.post(`${BASE_URL}/${url}`, body)
}

const testPut = (url, body, id) => {
  if (id) {
    return axios.put(`${BASE_URL}/${url}/${id}`, body)
  }
}

const cleanPut = async (url, body, id) => {
  if (id) {
    return axios.put(`${BASE_URL}/${url}/${id}`, body)
  }
};

const testDelete = (url, id) => {
  return axios.delete(`${BASE_URL}/${url}/${id}`)
}
//

const axiosPost = (url, data, reqHeaders) => {
  if (data) {
    if (reqHeaders) return axios.post(`${BASE_URL}/${url}`, data, reqHeaders)
    else return axios.post(`${BASE_URL}/${url}`, data)
  }
  return axios.post(`${BASE_URL}/${url}`)
}

const axiosPut = (url, data) => {
  if (data) {
    return axios.put(`${BASE_URL}/${url}`, data)
  }
  return axios.put(`${BASE_URL}/${url}`)
}

const axiosPatch = (apiType, id, data) => {
  return axios.put(`${BASE_URL}/${apiType}/${id}`, data)
}

const axiosDelete = (url) => {
  return axios.delete(`${BASE_URL}/${url}`)
}

export const apiCalls = {
  testDelete,
  testPut,
  testPost,
  cleanPut,
  getAllCount: axiosGetAllCount,
  getAll: axiosGetAll,
  getAllServer: getAllServer,
  getById: axiosGetById,
  post: axiosPost,
  put: axiosPut,
  patch: axiosPatch,
  delete: axiosDelete,
  fetchData,
}

import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
    return (
        <div>
            <h1>Page Not Found</h1>
            <p>The page you are trying to navigate to cannot be found. Please return to the Dashboard.</p>
            <Link to="/" className="btn btn-primary ml-2">Dashboard</Link>
        </div>
    )
}

export default PageNotFound

import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'

export const AddFCConfirmation = ({open, setOpen, formInput, handleCreateFiscalCycle}) => {

    if (formInput?.year.length <= 0) return null

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
            fullWidth={true}
        >
            <DialogTitle id="alert-dialog-title">
                Create Fiscal Cycle
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText id="alert-dialog-description">
                    {`You are about to create a new fiscal cycle for ${formInput.year} with a start date of ${formInput?.startDate?.slice(0, 10)} and an end date of ${formInput?.endDate?.slice(0, 10)}.`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button onClick={() => {
                    handleCreateFiscalCycle()
                    setOpen(false)
                }} autoFocus>
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    )
}

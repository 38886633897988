import React from "react"
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material"

export const ReferenceWarningDialog = ({ open, setOpen, handleDelete }) => {
  return (
    <Dialog open={open}>
      <DialogTitle>Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you wish to delete this entry?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ color: "black", border: "1px solid black" }}
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
        <Button
          style={{ backgroundColor: "red", color: "white", fontWeight: "bold", border: "1px solid black" }}
          onClick={() => {
            handleDelete()
            setOpen(false)
          }}
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import {
	withStyles
} from "@mui/styles";
import { Tooltip } from '@mui/material';
import { Warning, Cancel } from '@mui/icons-material';

const WarningsErrorsTooltip = withStyles({
    tooltip: {
      color: 'red',
      backgroundColor: 'white',
      fontSize: 12,
    }
})(Tooltip);

class TDAUtils {
    static TDATableFields = {
        FY: 'FY',
        CMD: 'CMD',
        DOCNO: 'DOCNO',
        UIC: 'UIC',
        CCNUM: 'CCNUM',
        PARNO: 'PARNO',
        LN: 'LN',
        PARATITLE: 'PARATITLE',
        UICDR: 'UICDR',
        TITLE: 'TITLE',
        GRADE: 'GRADE',
        POSCO: 'POSCO',
        POSTITLE: 'POSTITLE',
        IDENT: 'IDENT',
        PPSST: 'PPSST',
        PSIRQ: 'PSIRQ',
        PPSRQ: 'PPSRQ',
        REQSTR: 'REQSTR',
        AUTHSTR: 'AUTHSTR',
        PRMK1: 'PRMK1',
        PRMK2: 'PRMK2',
        PRMK3: 'PRMK3',
        TDUIC: 'TDUIC',
        AMSCO: 'AMSCO',
        MDEP: 'MDEP',
    }

    static TDACSVFileName = 'TdaRecords.csv'

    static TDADataFields = {
        FY: 'fiscalYear',
        CMD: 'armyCommandCode',
        DOCNO: 'documentNumber',
        UIC: 'unitIdentificationCode',
        CCNUM: 'commandControlNumber',
        PARNO: 'paragraphNumber',
        LN: 'lineNumber',
        PARATITLE: 'paragraphTitle',
        UICDR: 'derivativeUnitIdentificationCode',
        TITLE: 'title',
        GRADE: 'gradeCode',
        POSCO: 'positionCode',
        POSTITLE: 'positionTitle',
        IDENT: 'identityCode',
        PPSST: 'positionPersonnelSecurityStatus',
        PSIRQ: 'personnelSecurityInvestigationRequired',
        PPSRQ: 'positionPersonnelSecurityRequirement',
        REQSTR: 'requiredStrength',
        AUTHSTR: 'authorizedStrength',
        PRMK1: 'personnelRemarksCodeOne',
        PRMK2: 'personnelRemarksCodeTwo',
        PRMK3: 'personnelRemarksCodeThree',
        TDUIC: 'tdaDerivativeUnitIdentificationCode',
        AMSCO: 'armyManagementStructureCode',
        MDEP: 'managementDecisionPackage',
    }

    static UrlApiTypes = {
        record: 'TdaRecords',
		rfi: 'TdaRfis',
		rfiId: 'tdaRfiId',
    }

    static ExpectedTDAHeaders = [this.TDATableFields.FY, this.TDATableFields.CMD, this.TDATableFields.DOCNO, this.TDATableFields.UIC, this.TDATableFields.CCNUM, this.TDATableFields.PARNO, this.TDATableFields.LN, this.TDATableFields.PARATITLE, this.TDATableFields.UICDR, this.TDATableFields.TITLE, this.TDATableFields.GRADE, this.TDATableFields.POSCO, this.TDATableFields.POSTITLE, this.TDATableFields.IDENT, this.TDATableFields.PPSST, this.TDATableFields.PSIRQ, this.TDATableFields.PPSRQ, this.TDATableFields.REQSTR, this.TDATableFields.AUTHSTR, this.TDATableFields.PRMK1, this.TDATableFields.PRMK2, this.TDATableFields.PRMK3, this.TDATableFields.TDUIC, this.TDATableFields.AMSCO, this.TDATableFields.MDEP]

    static NonNullableFields = {
		TDA_DATA: [this.TDATableFields.FY, this.TDATableFields.CMD, this.TDATableFields.DOCNO, this.TDATableFields.UIC, this.TDATableFields.CCNUM, this.TDATableFields.PARNO, this.TDATableFields.LN, this.TDATableFields.PARATITLE, this.TDATableFields.TITLE, this.TDATableFields.GRADE, this.TDATableFields.POSCO, this.TDATableFields.POSTITLE, this.TDATableFields.IDENT, this.TDATableFields.PPSST, this.TDATableFields.PSIRQ, this.TDATableFields.PPSRQ, this.TDATableFields.REQSTR, this.TDATableFields.AUTHSTR, this.TDATableFields.AMSCO, this.TDATableFields.MDEP],
	}

    static DateFields = {
        TDA_DATA: []
    }

	static NumericFields = {
		TDA_DATA: [],
	}

    static TDA_COLS = [
        {
            field: 'Id',
            headerName: 'Id',
            hide: true,
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.TDATableFields.FY, 
            headerName: 'FY', 
            flex: 1,
            minWidth: 50, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.TDATableFields.CMD, 
            headerName: 'CMD', 
            flex: 1,
            minWidth: 60, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.TDATableFields.DOCNO, 
            headerName: 'DOCNO', 
            flex: 1,
            minWidth: 100, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.TDATableFields.UIC, 
            headerName: 'UIC', 
            flex: 1,
            minWidth: 100, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.TDATableFields.CCNUM, 
            headerName: 'CCNUM', 
            flex: 1,
            minWidth: 100, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.TDATableFields.PARNO, 
            headerName: 'PARNO', 
            flex: 1,
            minWidth: 100, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.TDATableFields.LN, 
            headerName: 'LN', 
            flex: 1,
            minWidth: 50, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.TDATableFields.PARATITLE, 
            headerName: 'PARATITLE', 
            flex: 2,
            minWidth: 200, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.TDATableFields.UICDR, 
            headerName: 'UICDR', 
            flex: 1,
            minWidth: 100, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.TDATableFields.TITLE, 
            headerName: 'TITLE', 
            flex: 1,
            minWidth: 125, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.TDATableFields.GRADE, 
            headerName: 'GRADE', 
            flex: 1,
            minWidth: 100, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.TDATableFields.POSCO, 
            headerName: 'POSCO', 
            flex: 1,
            minWidth: 100, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.TDATableFields.POSTITLE, 
            headerName: 'POSTITLE', 
            flex: 2,
            minWidth: 200, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.TDATableFields.IDENT, 
            headerName: 'IDENT', 
            flex: 1,
            minWidth: 90, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.TDATableFields.PPSST, 
            headerName: 'PPSST', 
            flex: 1,
            minWidth: 100, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.TDATableFields.PSIRQ, 
            headerName: 'PSIRQ',
            flex: 1, 
            minWidth: 100, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.TDATableFields.PPSRQ, 
            headerName: 'PPSRQ', 
            flex: 1,
            minWidth: 100, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.TDATableFields.REQSTR, 
            headerName: 'REQSTR', 
            flex: 1,
            minWidth: 100, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.TDATableFields.AUTHSTR, 
            headerName: 'AUTHSTR', 
            flex: 1,
            minWidth: 100, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.TDATableFields.PRMK1, 
            headerName: 'PRMK1', 
            flex: 1,
            minWidth: 150, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.TDATableFields.PRMK2, 
            headerName: 'PRMK2', 
            flex: 1,
            minWidth: 150, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.TDATableFields.PRMK3, 
            headerName: 'PRMK3', 
            flex: 1,
            minWidth: 150, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.TDATableFields.TDUIC, 
            headerName: 'TDUIC', 
            flex: 1,
            minWidth: 100, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.TDATableFields.AMSCO, 
            headerName: 'AMSCO', 
            flex: 1,
            minWidth: 100, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.TDATableFields.MDEP, 
            headerName: 'MDEP', 
            flex: 1,
            minWidth: 100, 
            headerClassName: '--RFI-grid-header',
        },
        {
            field: 'Warnings_Errors',
            headerName: 'Warnings/Errors',
            sortable: false,
            flex: 2,
            minWidth: 350,
            headerClassName: '--RFI-grid-warning-header',
            headerAlign: 'center',
            renderCell: (params) => {
                if (params?.value) {
                    let tooltipMsg = ''
                    let lineNumber = null
                    if (params?.value?.errors?.length > 0) {
                        lineNumber = params?.value?.errors[0]?.rowNumber
                        tooltipMsg += `Line Number ${lineNumber}) `
                    } else if (params?.value?.warnings?.length > 0) {
                        lineNumber = params?.value?.warnings[0]?.rowNumber
                        tooltipMsg += `Line Number ${lineNumber}) `
                    }
                    params?.value?.errors?.forEach((error, index) => tooltipMsg += `E${index + 1}) ${error.message}\n`)
                    params?.value?.warnings?.forEach((warning, index) => tooltipMsg += `W${index + 1}) ${warning.message}\n`)
                    return (
                        <WarningsErrorsTooltip title={tooltipMsg} placement='bottom'>
                            <div className="--RFI-warning-errors-icons">
                                {params?.value?.warnings?.length > 0 && <Warning/>}
                                {params?.value?.errors?.length > 0 && <Cancel/>}
                            </div>
                        </WarningsErrorsTooltip>
                    )
                } 
                return null
            },
        },
    ]
}

export default TDAUtils

export const ApplicationName = 'CWAT.Api';

export const QueryParameterNames = {
    ReturnUrl: 'returnUrl',
    Message: 'message'
};

export const LogoutActions = {
    LogoutCallback: 'logout-callback',
    Logout: 'logout',
    LoggedOut: 'logged-out'
};

export const LoginActions = {
    Login: 'login',
    LoginCallback: 'login-callback',
    LoginFailed: 'login-failed',
    Profile: 'profile',
    Register: 'register'
};

const prefix = `/authentication`
const identityPrefix = `/Identity/Account`

export const ApplicationPaths = {
    DefaultLoginRedirectPath: `${process.env.PUBLIC_URL}/organization-POCs`,
    ApiAuthorizationClientConfigurationUrl: `${process.env.PUBLIC_URL}/_configuration/${ApplicationName}`,
    ApiAuthorizationPrefix: prefix,
    ApiAuthorizationIdentityPrefix: identityPrefix,
    Login: `${prefix}/${LoginActions.Login}`,
    LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
    LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
    Register: `${prefix}/${LoginActions.Register}`,
    Profile: `${prefix}/${LoginActions.Profile}`,
    LogOut: `${prefix}/${LogoutActions.Logout}`,
    LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
    LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
    IdentityLoginPath: `${identityPrefix}/Login`,
    IdentityRegisterPath: `${identityPrefix}/Register`,
    IdentityLogoutPath: `${identityPrefix}/Logout`,
    IdentityManagePath: `${identityPrefix}/Manage`
};


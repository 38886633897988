import React from 'react'
import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button } from '@mui/material'

const ReviewWarningsModal = ({showWarningUploadModal, setShowWarningUploadModal}) => {
    
    return (
        <Dialog
            open={showWarningUploadModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Upload Has Warnings
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {`Your submission has warnings. The data was persisted, but you may want to review the warnings.`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button style={{color: 'black', border: '2px solid black'}} onClick={() => setShowWarningUploadModal(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ReviewWarningsModal

import React, { useReducer } from "react"
import GlobalContext from "./GlobalContext"
import GlobalReducer from "./GlobalReducer"

const GlobalState = (props) => {
  const initialState = {
    selectedOrg: {},
    allOrgs: [],
    filteredOrgList: [],
    selectedFiscalCycle: {},
    fiscalCycles: [],
    activeFiscalCycle: {},
    rfiOrganizationsMapping: {},
    selectedTabRfiMappingKey: "",
    rfiDashboardOrgRfiMapping: {},
    rfiRecordCountMapping: {},
    fteDashboardOrgRfiMapping: {},
    canRunWorkloadProcedures: false,
    userObj: {},
    isLoggedIn: false,
  }

  const [state, dispatch] = useReducer(GlobalReducer, initialState)

  const setSelectedOrgContext = (organization) => {
    localStorage.setItem("selectedOrg", organization.name)
    dispatch({ type: "SELECTED_ORG", payload: organization })
  }

  const setAllOrgsContext = (orgs) => {
    dispatch({ type: "ORGS", payload: orgs })
  }

  const setFilteredOrgListContext = (orgs) => {
    dispatch({ type: "FILTERED_ORG_LIST", payload: orgs })
  }

  const setSelectedFiscalCycleContext = (fiscalCycle) => {
    dispatch({ type: "SELECTED_FISCAL_CYCLE", payload: fiscalCycle })
  }

  const setFiscalCyclesContext = (fiscalCycles) => {
    dispatch({ type: "FISCAL_CYCLES", payload: fiscalCycles })
  }

  const setActiveFiscalCycleContext = (activeFiscalCycle) => {
    dispatch({ type: "ACTIVE_FISCAL_CYCLE", payload: activeFiscalCycle })
  }

  const setRfiOrganizationsMappingContext = (rfiOrganizationsMapping) => {
    dispatch({ type: "RFI_ORGANIZATIONS_MAPPING", payload: rfiOrganizationsMapping })
  }

  const setSelectedTabRfiMappingKeyContext = (selectedTabRfiMappingKey) => {
    dispatch({ type: "SELECTED_TAB_RFI_MAPPING_KEY", payload: selectedTabRfiMappingKey })
  }

  const setRfiDashboardOrgRfiMappingContext = (rfiDashboardOrgRfiMapping) => {
    dispatch({ type: "RFI_DASHBOARD_ORG_RFI_MAPPING", payload: rfiDashboardOrgRfiMapping })
  }

  const setRfiRecordCountMappingContext = (rfiRecordCountMapping) => {
    dispatch({ type: "RFI_RECORD_COUNT_MAPPING", payload: rfiRecordCountMapping })
  }

  const setFteDashboardOrgRfiMappingContext = (fteDashboardOrgRfiMapping) => {
    dispatch({ type: "FTE_DASHBOARD_ORG_RFI_MAPPING", payload: fteDashboardOrgRfiMapping })
  }

  const setCanRunWorkloadProceduresContext = (canRunWorkloadProcedures) => {
    dispatch({ type: "CAN_RUN_WORKLOAD_PROCEDURES", payload: canRunWorkloadProcedures })
  }

  const setUserObjectContext = (user) => {
    dispatch({ type: "USER", payload: user })
  }

  const setIsLoggedInContext = (isLoggedIn) => {
    dispatch({ type: "IS_LOGGED_IN", payload: isLoggedIn })
  }

  return (
    <GlobalContext.Provider
      value={{
        selectedOrg: state.selectedOrg,
        allOrgs: state.allOrgs,
        filteredOrgList: state.filteredOrgList,
        selectedFiscalCycle: state.selectedFiscalCycle,
        fiscalCycles: state.fiscalCycles,
        activeFiscalCycle: state.activeFiscalCycle,
        rfiOrganizationsMapping: state.rfiOrganizationsMapping,
        selectedTabRfiMappingKey: state.selectedTabRfiMappingKey,
        rfiDashboardOrgRfiMapping: state.rfiDashboardOrgRfiMapping,
        rfiRecordCountMapping: state.rfiRecordCountMapping,
        fteDashboardOrgRfiMapping: state.fteDashboardOrgRfiMapping,
        canRunWorkloadProcedures: state.canRunWorkloadProcedures,
        userObj: state.userObj,
        isLoggedIn: state.isLoggedIn,
        setSelectedOrgContext,
        setAllOrgsContext,
        setFilteredOrgListContext,
        setSelectedFiscalCycleContext,
        setFiscalCyclesContext,
        setActiveFiscalCycleContext,
        setRfiOrganizationsMappingContext,
        setSelectedTabRfiMappingKeyContext,
        setRfiDashboardOrgRfiMappingContext,
        setRfiRecordCountMappingContext,
        setFteDashboardOrgRfiMappingContext,
        setCanRunWorkloadProceduresContext,
        setUserObjectContext,
        setIsLoggedInContext,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  )
}

export default GlobalState

class Supplemental_Workforce_Utils {
  static Tabs = {
    SUPPLEMENTAL_WORKFORCE_RFI: "Supplemental Workforce RFI",
    CIVILIAN_PERCENTAGES: "Civilian Percentages",
    BPC: "BPC CIV FTE Reduction",
  }

  static TableNames = {
    [this.Tabs.SUPPLEMENTAL_WORKFORCE_RFI]: "Hours Distribution Table",
    [this.Tabs.CIVILIAN_PERCENTAGES]: "Civilian Percentages Table",
  }

  static SupplementalWorkforceDataFields = {
    TOTAL_FTE_PERCENTAGE: "Total Percentage",
    TOTAL_FTE: "Total FTE",
    GROUP: "Group",
    LEAVE: "Leave",
    PRE_LOR: "Pre-LOR (CF1)",
    CASE_DEVELOPMENT: "Case Development (CF2)",
    CASE_EXECUTION: "Case Execution (CF3)",
    CASE_CLOSURE: "Case Closure (CF4)",
    BUSINESS_SUSTAINING: "Business Sustaining (CF5)",
  }

  static SupplementalWorkforceColumnHeaders = {
    DATA_FIELD: "Data Field",
    CIVILIANS: "Unaccounted for Civilians",
    CONTRACTORS: "Contractors",
    MILITARY: "Military",
  }

  static CivilianPercentagesColumnHeaders = {
    DATA_FIELD: "Data Field",
    CIVILIAN_PERCENTAGE: "Civilian Percentage",
  }

  static BPCColumnHeaders = {
    DATA_FIELD: "Data Field",
    BPC_FTE_CIV: "BPC FTE Civ",
  }

  static SupplementalWorkforceGroups = {
    UNACC_CIVILIANS: 0,
    CONTRACTORS: 1,
    MILITARY: 2,
    CIVILIAN_PERCENTAGE: 3,
  }

  static SupplementalWorkforceGroupsArr = [
    this.SupplementalWorkforceGroups.UNACC_CIVILIANS,
    this.SupplementalWorkforceGroups.CONTRACTORS,
    this.SupplementalWorkforceGroups.MILITARY,
    this.SupplementalWorkforceGroups.CIVILIAN_PERCENTAGE,
  ]
}

export default Supplemental_Workforce_Utils

import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material'
import PEO_PM_Utils from './PEO_PM_Utils'
import './css/PEO_PM.css'
import {apiCalls} from '../../DataService'

export const NewPEO_PMRecord = ({showDialog, closeDialog, selectedOrg, setShowSnackbarSuccess, setShowSnackbarError, setSnackbarMessage, refreshSelectedOrg}) => {
    const [disableSaveRecord, setDisableSaveRecord] = useState(true)
    const [userInput, setUserInput] = useState({
        [PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_ID]: '',
        [PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_LINE_NUMBER_ID]: '',
    })

    const saveNewRecord = async () => {
        //Make sure an organization is selected
        if (!selectedOrg.id) {
            closeDialog()
            setShowSnackbarError(true)
            setSnackbarMessage('Organization not selected, please select an organization from the Organization POCs page')
            return
        }
        // Associate DSAMS Preparing Activity Data Record with an Organization
        apiCalls.put(`DsamsPreparingActivityDatas/${userInput[PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_ID]}/${userInput[PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_LINE_NUMBER_ID]}/AssociateRecord/${selectedOrg.id}`, null).then(() => {
            refreshSelectedOrg()
            setShowSnackbarSuccess(true)
            setSnackbarMessage(`Successfully associated DSAMS Preparing Activity Data Record with the ${selectedOrg.name} organization.`)
        }).catch(e => {
            console.error(e)
            setShowSnackbarError(true)
            setSnackbarMessage(`No records could be found with the requested User Case Id ${userInput[PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_ID]} and User Case Line Number Id ${userInput[PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_LINE_NUMBER_ID]} in the ${selectedOrg.name} organization.`)
        }).finally(() => {
            closeDialog()
            clearUserInput()
        })
    }

    const handleCancel = () => {
        clearUserInput()
        closeDialog()
    }

    const handleFieldChange = (e, field) => {
        switch (field) {
        case PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_ID:
            setUserInput({...userInput, [PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_ID]: e.target.value})
            break
        case PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_LINE_NUMBER_ID:
            setUserInput({...userInput, [PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_LINE_NUMBER_ID]: e.target.value})
            break
        case PEO_PM_Utils.PEO_PM_DataFields.MILITARY_ARTICLE_SERVICE_CODE:
            setUserInput({...userInput, [PEO_PM_Utils.PEO_PM_DataFields.MILITARY_ARTICLE_SERVICE_CODE]: e.target.value})
            break
        case PEO_PM_Utils.PEO_PM_DataFields.ARTICLE_DESCRIPTION_TEXT:
            setUserInput({...userInput, [PEO_PM_Utils.PEO_PM_DataFields.ARTICLE_DESCRIPTION_TEXT]: e.target.value})
            break
        case PEO_PM_Utils.PEO_PM_DataFields.GENERIC_DESCRIPTION_TEXT:
            setUserInput({...userInput, [PEO_PM_Utils.PEO_PM_DataFields.GENERIC_DESCRIPTION_TEXT]: e.target.value})
            break
        default:
            break
        }
    }

    const clearUserInput = () => {
        setUserInput({
            [PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_ID]: '',
            [PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_LINE_NUMBER_ID]: '',
            [PEO_PM_Utils.PEO_PM_DataFields.MILITARY_ARTICLE_SERVICE_CODE]: '',
            [PEO_PM_Utils.PEO_PM_DataFields.ARTICLE_DESCRIPTION_TEXT]: '',
            [PEO_PM_Utils.PEO_PM_DataFields.GENERIC_DESCRIPTION_TEXT]: '',
        })
        setDisableSaveRecord(true)
    }

    useEffect(() => {
        if (userInput[PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_ID].length > 0 && userInput[PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_LINE_NUMBER_ID].length > 0) {
            setDisableSaveRecord(false)
        }
    }, [userInput])

    return (
        <div>
            <Dialog open={showDialog} style={{margin: 'auto'}}>
                <DialogTitle style={{backgroundColor: '#0e2d57', color: 'white', marginBottom: '1rem'}}>Associate DSAMS Preparing Activity Data Record with an Organization</DialogTitle>
                <DialogContent style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                        <h5>User Case Id:</h5>
                        <TextField style={{margin: '1rem'}} variant="outlined" width={'20%'} size="small" InputProps={{inputProps: {min: 0}}} InputLabelProps={{ shrink: true }} margin="dense" onChange={(e) => handleFieldChange(e, PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_ID)} value={userInput[PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_ID]} />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                        <h5>User Case Line Number Id:</h5>
                        <TextField style={{margin: '1rem'}} variant="outlined" width={'20%'} size="small" InputProps={{inputProps: {min: 0}}} InputLabelProps={{ shrink: true }} margin="dense" onChange={(e) => handleFieldChange(e, PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_LINE_NUMBER_ID)} value={userInput[PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_LINE_NUMBER_ID]}/>
                    </div>
                </DialogContent>
                <DialogActions style={{display: 'flex', justifyContent: 'center', padding: 0, marginBottom: '1rem'}}>
                    <Button onClick={() => saveNewRecord()} disabled={disableSaveRecord} variant="contained" style={!disableSaveRecord ? {backgroundColor: '#225782', color: 'white'} : {backgroundColor: 'gray', color: 'white'}} disableRipple>SAVE</Button>
                    <Button onClick={() => handleCancel()} variant="contained" style={{backgroundColor: '#225782', color: 'white'}} disableRipple>CANCEL</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

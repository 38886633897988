import React from 'react'
import WorkloadInstructions from '../WorkloadInstructions'
import CloseIcon from '@mui/icons-material/Close';
import '../RFI.css'

const ACC_Instructions_Modal = ({ disclaimerRFIModalOpen, setDisclaimerRFIModalOpen }) => {

    const background = "Background:   The Common Workforce Analysis Tool requires authoritative data traceable to each organization's workforce and workload in FY22 .  For Army Implementing Agencies and Security Assistance Management Directorates, this is found in DSAMS and CISIL at the case ID and line ID  level. Currently, there is no traceability at this level for your organization.  To support FY22 workload mapping for your organization, please complete Steps 1-2."
    const step1 = "Step 1:   In VCE or other databases as needed, please export a list of all workload for ACC from FY22, 1 Oct 2021 to 30 Sep 2022.  Add this information to the list  of requested data fields below."
    const step2 = "Step 2:  By 4 NOV 2022, Send your organization's completed FY22 Workload RFI to natalie.a.carr.civ@army.mil and albert.k.wong5.ctr@army.mil"

    return (
        <WorkloadInstructions
            open={disclaimerRFIModalOpen}
            setOpen={setDisclaimerRFIModalOpen}
            name={"ACC"}
            line1={background}
            line2={step1}
            line3={step2}
        />
    )
}

export default ACC_Instructions_Modal

import { Box } from "@mui/material"

const Banner = ({ data, position }) => {
  return (
    <Box
      height={25}
      style={{
        ...position,
        display: "flex",
        justifyContent: "center",
        color: "white",
        backgroundColor: data.classificationColor,
        width: "100%",
      }}
    >
      {data.classificationMarking}
    </Box>
  )
}
export default Banner

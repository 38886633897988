import React from 'react';
import { Drawer, List, ListItem, ListItemText, Box } from '@mui/material';
import { styled } from '@mui/system';

const StyledListItem = styled('div')(({ theme, selected }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: theme.spacing(2),
    margin: theme.spacing(1, 0),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: selected ? theme.palette.primary.main : 'transparent',
    color: selected ? theme.palette.primary.contrastText : theme.palette.text.primary,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: selected ? theme.palette.primary.dark : theme.palette.action.hover,
    },
    transition: 'background-color 0.3s, color 0.3s',

  }));

const Sidebar = ({ onGroupChange , currentSideTab }) => {
  const handleGroupClick = (group) => {
    onGroupChange(group);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        '& .MuiDrawer-paper': {
          position: 'fixed',
          width: '150px',
          height: 'calc(90vh - 300px)',
          marginTop: '225px'
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
          alignItems: 'center',
        }}
      >
         <List>
          {['CWAT', 'Forecasting Models', 'Workforce', 'Workload'].map((group) => (
            <StyledListItem
              key={group}
              onClick={() => handleGroupClick(group)}
              selected={group === currentSideTab}
            >
              <ListItemText primary={group} sx={{ textAlign: 'center' }} />
            </StyledListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;


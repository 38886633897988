import { useState, useContext, useEffect } from "react"

// Context
import GlobalContext from "../components/reducers/GlobalContext"

// Data
import { QueryParamTypes, apiCalls } from "../components/DataService"

// Libraries
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { BlurCircular, Circle } from "@mui/icons-material"

// Custom
import orgIconPlaceholder from "../CWATImages/OrganizationIconPlaceholder.png"
import { logosArr } from "../components/Organizations/OrganizationPOCs"
import { ApplicationPaths } from "../components/api-authorization/ApiAuthorizationConstants"

// Styles
import { makeStyles } from "@mui/styles"
import "./LandingPage.css"

const useStyles = makeStyles({
  root: {
    backgroundColor: "#333",
    color: "white",
    height: "calc(100vh - 20px)" /* Set a fixed value for padding or margin if needed */,
    maxHeight: "100vh",
    padding: "1rem",
    overflow: "auto",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "2rem",
  },
  title: {
    color: "white",
    textAlign: "center",
  },
  selectFunction: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "2rem",
  },
  selectFunctionTitle: {
    marginBottom: "1rem",
    color: "#1189bd",
    textAlign: "center",
  },
  userRoleTitle: {
    marginBottom: "0.75rem",
    color: "orange",
    textAlign: "center",
  },
  orgButtons: {
    color: "black",
    backgroundColor: "#aaa",
    padding: "0.5rem 1rem",
    border: "none",
    borderRadius: "5px",
    margin: "0.25rem 0",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  timeline: {
    marginTop: "4rem",
  },
  blurCircle: {
    backgroundColor: "#333",
    borderRadius: "50%",
    position: "relative",
    left: "17px",
  },
  orgLogos: {
    marginTop: "5rem",
  },
  cuiMarking: {
    width: "100%",
    position: "fixed",
    bottom: 25,
  },
})

const LandingPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const globalContext = useContext(GlobalContext)
  const [DASA_DECLogo, setDASA_DECLogo] = useState("")

  // ** --------------***     [ ~ VARS ~ ]     ***-------------- **
  const { allOrgs, setAllOrgsContext, setSelectedOrgContext, selectedOrg, userObj, setUserObjectContext, setIsLoggedInContext } = globalContext

  // ** --------------***     [ ~ EFFECTS ~ ]     ***-------------- **
  useEffect(() => {
    // console.log("TRIGGERED: 6")
  })

  useEffect(() => {
    loadLandingPageData()
  }, [])

  // ** --------------***     [ ~ FUNCTIONS ~ ]     ***-------------- **
  const loadLandingPageData = async () => {
    // let accountURL = getURL("Accounts", "me")
    // let accountFetch = fetchData(url)

    // TODO: convert to Promise.all and async/awaits

    apiCalls.getAll(`Accounts/me`, {}).then((loggedInUser) => {
      setUserObjectContext(loggedInUser)
      setIsLoggedInContext(!!loggedInUser?.sub)
      if (!loggedInUser?.sub) {
        window.location.replace(ApplicationPaths.IdentityLoginPath)
      }
    })

    apiCalls.getAll(`Organizations`, { [QueryParamTypes.ORDER_BY]: "name" }).then((tempOrgList) => {
      // Set DASA DE&C logo
      setDASA_DECLogo(logosArr.find((logo) => logo.name === "DASA DE&C"))
      // Add org logo relative path to data object
      if (!selectedOrg?.id) {
        tempOrgList = tempOrgList.map((org) => ({ ...org, logo: logosArr.find((logo) => logo.name === org.name) }))
        setAllOrgsContext(tempOrgList)
        const DASA_DEC_ORG = tempOrgList.find((org) => org.name === "DASA DE&C")
        apiCalls.getById(`Organizations`, DASA_DEC_ORG.id).then((selectedOrg) => {
          setSelectedOrgContext({ ...selectedOrg, logo: logosArr.find((logo) => logo.name === selectedOrg.name) })
        })
      }
    })
  }

  const setTargetOrganization = (targetOrg) => {
    apiCalls.getAll(`Organizations`, {}).then((orgs) => {
      const targetOrgAPI = orgs.find((org) => org.name === targetOrg)
      apiCalls.getById(`Organizations`, targetOrgAPI.id).then((org) => {
        setSelectedOrgContext({ ...org, logo: logosArr.find((logo) => logo.name === targetOrgAPI.name) })
      })
    })
  }

  // ** --------------***     [ ~ RENDER ~ ]     ***-------------- **
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {DASA_DECLogo.link.toString() ? (
          <img
            src={DASA_DECLogo.link.toString()}
            alt=""
            width="100"
            height="100"
            className="org-icon"
          />
        ) : (
          <img
            src={orgIconPlaceholder}
            alt=""
            width="100"
            height="100"
            className="org-icon"
          />
        )}
        <h1 className={classes.title}>Welcome to the Common Workforce Analysis Tool (CWAT)</h1>
        {selectedOrg?.logo ? (
          <img
            src={selectedOrg.logo.link}
            alt=""
            width="100"
            height="100"
            className="org-icon"
          />
        ) : (
          <img
            src={orgIconPlaceholder}
            alt=""
            width="100"
            height="100"
            className="org-icon"
          />
        )}
      </div>
      <div className={classes.selectFunction}>
        <div>
          <h2 className={classes.selectFunctionTitle}>Select Function</h2>
          {userObj?.role && <h5 className={classes.userRoleTitle}>{userObj.role}</h5>}
          <div className="select-function-grid">
            <button
              className="select-function-grid-item"
              onClick={() => {
                setTargetOrganization("USASAC")
                history.push({
                  pathname: `/DSAMS-CISIL-RFIs`,
                  state: { organization: "USASAC" },
                })
              }}
            >
              ANNUAL DATA UPDATE
            </button>
            <button
              className="select-function-grid-item"
              style={{ backgroundColor: "gray" }}
            >
              RUN CWAT REPORTS
            </button>
            <button
              className="select-function-grid-item"
              style={{ backgroundColor: "gray" }}
            >
              CWAT DISTRIBUTIONS
            </button>
            <button
              className="select-function-grid-item"
              style={{ backgroundColor: "gray" }}
            >
              ACCESS HISTORIC DATA
            </button>
            <button
              className="select-function-grid-item"
              onClick={() => history.push("/users")}
            >
              ADMINISTRATIVE MAINTENANCE
            </button>
            <button
              className="select-function-grid-item"
              style={{ backgroundColor: "gray" }}
            >
              TRAINING & USER SUPPORT
            </button>
          </div>
        </div>
      </div>
      <div className={classes.timeline}>
        <h2 className={classes.title}>FY23 CWAT & SAPBR Activity Timeline</h2>
        {/* TODO - Insert timeline here */}
        <div className="timeline-grid-container">
          <div
            className="timeline-bar-item"
            style={{ backgroundColor: "#333" }}
          >
            <div
              className="timeline-bar-item"
              style={{ backgroundColor: "#333", height: "100%" }}
            />
            <BlurCircular
              className={classes.blurCircle}
              style={{ height: 35, width: 35 }}
            />
            <div
              className="timeline-bar-item"
              style={{ height: "100%" }}
            />
          </div>
          <div className="timeline-bar-item">
            <Circle style={{ height: 24, width: 24 }} />
          </div>
          <div className="timeline-bar-item">
            <Circle style={{ height: 24, width: 24 }} />
          </div>
          <div className="timeline-bar-item">
            <Circle style={{ height: 24, width: 24 }} />
          </div>
          <div className="timeline-bar-item">
            <div
              className="timeline-bar-item"
              style={{ height: "100%" }}
            />
            <BlurCircular
              className={classes.blurCircle}
              style={{ height: 35, width: 35 }}
            />
            <div
              className="timeline-bar-item"
              style={{ backgroundColor: "#333", height: "100%" }}
            />
          </div>
          <div className="timeline-label-item">
            CWAT RFI Data Collection
            <br />
            10/1/2022
          </div>
          <div className="timeline-label-item">
            CWAT Release Date
            <br />
            2/15/2023
          </div>
          <div className="timeline-label-item">
            Run - SAPBR Submission
            <br />
            3/1/2023
          </div>
          <div className="timeline-label-item">
            SAPBR Review Period
            <br />
            4/1/2023
          </div>
          <div className="timeline-label-item">
            SAPBR Approval Date
            <br />
            5/1/2023
          </div>
        </div>
      </div>
      <div className={classes.orgLogos}>
        <div className="logo-container">
          {allOrgs &&
            allOrgs.map((org, key) => (
              <div
                key={key}
                className="logo-item"
              >
                <img
                  src={org?.logo?.link}
                  alt={org?.name}
                  width="80"
                  height="80"
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default LandingPage

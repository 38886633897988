import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'

const RunWorkforceWorkloadConfirmationModal = ({open, setOpen, title, message, runWorkloadOrWorkforce}) => {
    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{marginBottom: '1rem'}}>
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button style={{color: 'black', border: '2px solid black'}} onClick={() => setOpen(false)}>Cancel</Button>
                    <Button style={{backgroundColor: 'red', color: 'white', fontWeight: 'bold', border: '2px solid black'}} onClick={() => {
                        setOpen(false)
                        runWorkloadOrWorkforce()
                    }} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default RunWorkforceWorkloadConfirmationModal

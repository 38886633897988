import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import { SubmitStatusArr } from '../../Utils'

const FTEValidationChangeConfirmationModal = ({open, setOpen, modifiedCells, saveRFIChanges}) => {

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Status Change Confirmation
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{marginBottom: '1rem'}}>
                        Please confirm your intended status changes are correct
                    </DialogContentText>
                    <div>
                        {modifiedCells.map((cell, idx) => {
                            return (
                                <div key={idx}>
                                    <p style={{padding: 0, margin: 0}}><strong>{cell.organization}</strong></p>
                                    <ul>
                                        <li>Change status of <strong>{cell.organization}</strong> from <strong>{SubmitStatusArr[cell.prevValue]}</strong> to <strong>{SubmitStatusArr[cell.value]}</strong></li>
                                    </ul>
                                </div>
                            )
                        })}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button style={{color: 'black', border: '2px solid black'}} onClick={() => setOpen(false)}>Cancel</Button>
                    <Button style={{backgroundColor: 'red', color: 'white', fontWeight: 'bold', border: '2px solid black'}} onClick={() => {
                        setOpen(false)
                        saveRFIChanges()
                    }} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default FTEValidationChangeConfirmationModal

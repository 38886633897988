import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'

export const EditFCConfirmation = ({open, setOpen, fcToEdit, handleEditFiscalCycle}) => {

    if (fcToEdit?.fiscalYear?.length <= 0) return null

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Edit {fcToEdit?.fiscalYear} Fiscal Cycle
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText id="alert-dialog-description">
                    {`You are about to change the start date to ${fcToEdit?.startDate?.slice(0, 10)} and the end date to ${fcToEdit?.endDate?.slice(0, 10)} for ${fcToEdit?.fiscalYear}.`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button onClick={() => {
                    handleEditFiscalCycle()
                    setOpen(false)
                }} autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}

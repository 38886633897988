import { Box } from "@mui/material"

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <Box
      // className="fullWidth"
      style={{ display: "flex", width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  )
}

export default CustomTabPanel

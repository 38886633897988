import React from "react"
import { ArrowBack, ArrowForward } from "@mui/icons-material"
import { Button, Typography } from "@mui/material"

export const TableControls = ({ pageNumber, pageSize, numTotalTableRows, handleOnPageChange }) => {
  const pageOverLimit = () => {
    const overLimit = (pageNumber + 1) * pageSize + 1 > numTotalTableRows
    return overLimit
  }

  return (
    <div className="next-prev-page-arrows">
      <Typography className="table-row-display">
        {pageNumber * pageSize + 1} - {pageNumber * pageSize + pageSize < numTotalTableRows ? pageNumber * pageSize + pageSize : numTotalTableRows} /{" "}
        {numTotalTableRows}
      </Typography>
      <div>
        <Button
          className="page-arrow"
          onClick={() => handleOnPageChange(pageNumber - 1)}
          disabled={pageNumber <= 0}
        >
          <ArrowBack />
        </Button>
        <Button
          className="page-arrow"
          onClick={() => handleOnPageChange(pageNumber + 1)}
          disabled={pageOverLimit()}
        >
          <ArrowForward />
        </Button>

        {/* <button className='page-arrow' onClick={() => handleOnPageChange(pageNumber - 1)} disabled={pageNumber <= 0}><ArrowBack/></button>
                <button className='page-arrow' onClick={() => handleOnPageChange(pageNumber + 1)} disabled={pageOverLimit()}><ArrowForward/></button> */}
      </div>
    </div>
  )
}

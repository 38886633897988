import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'

export const DeleteFCConfirmation = ({open, setOpen, fcToDelete, handleDeleteFiscalCycle}) => {
    
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Delete Fiscal Cycle {fcToDelete?.fiscalYear ? fcToDelete.fiscalYear : ''}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {`Are you sure you would like to delete ${fcToDelete?.fiscalYear ? `Fiscal Cycle ${fcToDelete.fiscalYear}`: 'this Fiscal Cycle'}? You will no longer be able to see data submitted under this Fiscal Cycle.`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button onClick={() => {
                    handleDeleteFiscalCycle(fcToDelete.id)
                    setOpen(false)
                }} autoFocus>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
}

import React from 'react'
import { 
    Snackbar,
    Alert, 
    AlertTitle,
}  from "@mui/material"

const SnackbarMessages = ({showSnackbarSuccess, setShowSnackbarSuccess, showSnackbarError, setShowSnackbarError, snackbarMessage}) => {
  return (
    <div>
        <Snackbar open={showSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} onClose={() => setShowSnackbarSuccess(false)}>
            <Alert onClose={() => setShowSnackbarSuccess(false)} severity="success">
                <AlertTitle>{snackbarMessage}</AlertTitle>
            </Alert>
        </Snackbar>
        <Snackbar open={showSnackbarError} anchorOrigin={{vertical: 'top', horizontal: 'center'}} onClose={() => setShowSnackbarError(false)}>
            <Alert onClose={() => setShowSnackbarError(false)} severity="error">
                <AlertTitle>{snackbarMessage}</AlertTitle>
            </Alert>
        </Snackbar>
    </div>
  )
}

export default SnackbarMessages

import { Dialog, DialogActions, DialogTitle, DialogContent, Button, Typography } from "@mui/material"

export const SaveResultsDialog = ({ results, open, setOpen }) => {
  let { associations, disassociations } = results

  const getTitle = () => {
    if (associations?.errors?.length > 0) {
      return "There was a problem saving your changes."
    } else {
      return "Changes Saved Successfully"
    }
  }

  const getContent = () => {
    let disErrors
    let disSuccess

    let associateErrors

    if (disassociations?.errors?.length > 0) {
      disErrors = results.disassociations.errors.map((item) => {
        return `An error occurred. Item ${item.item1} has not been disassociated.`
      })
    }

    if (disassociations?.successes?.length > 0) {
      disSuccess = results.disassociations.successes.map((item) => {
        return `${item.item1} has been disassociated.`
      })
    }

    if (associations?.errors?.length > 0) {
      associateErrors = "There was an error saving some associations."
    }

    return {
      disErrors,
      disSuccess,
      associateErrors,
    }
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="dialogTitle">{getTitle()}</Typography>
      </DialogTitle>
      <DialogContent>
        {getContent().disSuccess && <Typography variant="dialogSubtitle">Removed Items:</Typography>}
        {getContent().disSuccess?.map((item) => {
          return <p style={styles.p}>{item}</p>
        })}

        {getContent().disErrors && <Typography variant="dialogSubtitle">Item Removal Errors:</Typography>}
        {getContent().disErrors?.map((item) => {
          return <p style={styles.p}>{item}</p>
        })}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  p: {
    fontSize: ".7rem",
    margin: "5px",
  },
}

import { withStyles } from "@mui/styles"
import "../RFI.css"
import { Tooltip } from "@mui/material"
import { Warning, Cancel } from "@mui/icons-material"

export function validateUserCaseId(value) {
  const REQD_LENGTH = 8
  if (value.length !== REQD_LENGTH) return false
  // #L-L-L###
  let pattern = /[0-9][A-Z]\u002D[A-Z]\u002D[A-Z]{3}/
  return pattern.test(String(value))
}

export function validateGenericCode(value) {
  const REQD_LENGTH = 3
  if (value.length !== REQD_LENGTH) return false
  // L#L
  let pattern = /[A-Z][0-9][A-Z]/
  return pattern.test(String(value))
}

export function validateSDRNumber(value) {
  const REQD_LENGTH = 5
  if (value.length !== REQD_LENGTH) return false
  // L####
  let pattern = /[A-Z][0-9]{4}/
  return pattern.test(String(value))
}

const WarningsErrorsTooltip = withStyles({
  tooltip: {
    color: "red",
    backgroundColor: "white",
    fontSize: 12,
  },
})(Tooltip)

class DSAMSTableUtils {
  static Tabs = {
    MILESTONES: "DSAMS Milestones",
    PREPARING_ACTIVITY_DATA: "DSAMS Preparing Activity Data",
    CISIL_CCOPES_LINE_DATA: "Cisil Ccopes Line Data",
    CISIL_CASES_CERT_CLOSURE: "Cisil Cases Cert Closure",
    CISIL_CASES_CERT_CLOSURE_WO_PRI_ONE_DATE: "CISIL Cases Cert Closure W/O Priority 1 DT",
    CISIL_REQUISITIONS: "Cisil Requisitions",
    CISIL_SDRs: "Cisil Sdrs",
    CISIL_DATA_DESCRIPTIONS: "Cisil Data Descriptions",
  }

  static TabCSVFileNames = {
    [this.Tabs.MILESTONES]: "DsamsMilestones.csv",
    [this.Tabs.PREPARING_ACTIVITY_DATA]: "DsamsPreparingActivityData.csv",
    [this.Tabs.CISIL_CCOPES_LINE_DATA]: "CisilCcopesLineData.csv",
    [this.Tabs.CISIL_CASES_CERT_CLOSURE]: "CisilCasesCertClosures.csv",
    [this.Tabs.CISIL_CASES_CERT_CLOSURE_WO_PRI_ONE_DATE]: "CisilCasesCertClosuresAll.csv",
    [this.Tabs.CISIL_REQUISITIONS]: "CisilRequisitions.csv",
    [this.Tabs.CISIL_SDRs]: "CisilSdrs.csv",
    [this.Tabs.CISIL_DATA_DESCRIPTIONS]: "CisilDataDescriptions.csv",
  }

  static MilestonesFields = {
    USER_CASE_ID: "UserCaseId",
    CUSTOMER_REQUEST_RECEIPT_DATE: "CustomerRequestReceiptDate",
    CASE_VERSION_TYPE_CODE: "CaseVersionTypeCode",
    CASE_VERSION_NUMBER_ID: "CaseVersionNumberId",
    MILESTONE_ID: "MilestoneId",
    CASE_VERSION_MILESTONE_DATE: "CaseVersionMilestoneDate",
    DATE_TYPE_CODE: "DateTypeCode",
    CREATE_DATE: "CreateDate",
  }

  static ActivityDataFields = {
    USER_CASE_ID: "UserCaseId",
    CUSTOMER_REQUEST_RECEIPT_DATE: "CustomerRequestReceiptDate",
    CASE_VERSION_TYPE_CODE: "CaseVersionTypeCode",
    CASE_VERSION_NUMBER_ID: "CaseVersionNumberId",
    USER_CASE_LINE_NUMBER: "UserCaseLineNumberId",
    CASE_MASTER_LINE_ID: "CaseMasterLineId",
    PARENT_CASE_MASTER_LINE_ID: "ParentCaseMasterLineId",
    USER_CASE_SUBLINE_TEXT: "UserCaseSublineText",
    CASE_LINE_MARK_FOR_DELETION_IN: "CaseLineMarkForDeletionIn",
    CASE_LINE_ACTIVITY_ID: "CaseLineActivityId",
    LINE_MANAGER_ID: "LineManagerId",
    CASE_MASTER_LINE_ACTIVITY_ID: "CaseMasterLineActivityId",
    SUPPORTING_ORGANIZATIO_TITLE_NAME: "SupportingOrganizationTitleName",
    MILITARY_ARTICLE_SERVICE_CODE: "MilitaryArticleServiceCode",
    ARTICLE_DESCRIPTION_TEXT: "ArticleDescriptionText",
    GENERIC_CODE: "GenericCode",
    GENERIC_DESCRIPTION_TEXT: "GenericDescriptionText",
  }

  // OFC,CTRY,IMPL,CASE,,LI,LI,,CCOP,LAST,NEXT,EST CERT,CLOSE,DSAA,,ULO,INHIB,,,TOT MATL,TOT CAS,TOT LSC,,UNLIQ ART,UNLIQ,OA CERT,CERT,
  // SYM,CD,AGENCY,DESIG,PRI 1 DT,NBR,MGR,RIC,LI ENTER DT,REV DT,CERT DT,COMP DT,CERT DT,PRI,PRIORITY,IND,CD,DISB VAL,TOT BILL VAL,BILL VAL,BILL VAL,BILL VAL,UNLIQ VAL,SRVC VAL,CAS VAL,VAL,OFCR,REMARKS
  static CcopesLineDataCSVFields = {
    COUNTRY_CODE: "CTRY",
    IMPLEMENTING_AGENCY: "IMPL",
    CASE_DESIGNATOR: "CASE",
    PRIORITY_ONE_DATE: "",
    LINE_NUMBER: "LI",
    RIC: "",
    CCOPES_LINE_ENTER_DATE: "CCOP",
    LAST_REVISION_DATE: "LAST",
    NEXT_CERTIFICATION_DATE: "NEXT",
    ESTIMATED_CERTIFICATION_DATE: "EST CERT",
    CLOSE_CERTIFICATION_DATE: "CLOSE",
    DSAA_PRIORITY: "DSAA",
    PRIORITY: "",
    ULO_INDICATOR: "ULO",
    INHIBITOR_CODE: "INHIB",
  }

  static CcopesLineDataFields = {
    COUNTRY_CODE: "CountryCode",
    IMPLEMENTING_AGENCY: "ImplementingAgency",
    CASE_DESIGNATOR: "CaseDesignator",
    PRIORITY_ONE_DATE: "PriorityOneDate",
    LINE_NUMBER: "LineNumber",
    RIC: "Ric",
    CCOPES_LINE_ENTER_DATE: "CcopesLineEnterDate",
    LAST_REVISION_DATE: "LastRevisionDate",
    NEXT_CERTIFICATION_DATE: "NextCertificationDate",
    ESTIMATED_CERTIFICATION_DATE: "EstimatedCertificationDate",
    CLOSE_CERTIFICATION_DATE: "CloseCertificationDate",
    DSAA_PRIORITY: "DsaaPriority",
    PRIORITY: "Priority",
    ULO_INDICATOR: "UloIndicator",
    INHIBITOR_CODE: "InhibitorCode",
  }

  static CisilCasesCertClosuresCSVFields = {
    COUNTRY_CODE: "CTRY_CD",
    IMPLEMENTING_AGENCY: "IMPL_AGENCY",
    CASE_DESIGNATOR: "CASE_DESIG",
    RIC: "RIC",
    NUMBER_OF_LINES: "TOT_LI_NBR",
    LOA_IMPLEMENTATION_DATE: "LOA_IMPL_DT",
    PRIORITY_ONE_DATE: "PRI_1_DT",
    CLOSE_CERTIFICATION_DATE: "CLOSE_CERT_DT",
    ESTIMATED_TOTAL_COST: "EST_TOT_CST",
    UNDELIVERED_VALUE: "UNDLVR_VAL",
  }

  static CisilCasesCertClosureFields = {
    COUNTRY_CODE: "CountryCode",
    IMPLEMENTING_AGENCY: "ImplementingAgency",
    CASE_DESIGNATOR: "CaseDesignator",
    RIC: "Ric",
    NUMBER_OF_LINES: "NumberOfLines",
    LOA_IMPLEMENTATION_DATE: "LoaImplementationDate",
    PRIORITY_ONE_DATE: "PriorityOneDate",
    CLOSE_CERTIFICATION_DATE: "CloseCertificationDate",
    ESTIMATED_TOTAL_COST: "EstimatedTotalCost",
    UNDELIVERED_VALUE: "UndeliveredValue",
  }

  static CisilRequisitionsFields = {
    COUNTRY_CODE: "CountryCode",
    IMPLEMENTING_AGENCY: "ImplementingAgency",
    CASE_DESIGNATOR: "CaseDesignator",
    LINE_NUMBER: "LineNumber",
    RIC: "Ric",
    REQUISITIONS_SHIPPED: "RequisitionsShipped",
  }

  static CisilSDRsFields = {
    COUNTRY_CODE: "CountryCode",
    IMPLEMENTING_AGENCY: "ImplementingAgency",
    CASE_DESIGNATOR: "CaseDesignator",
    LINE_NUMBER: "LineNumber",
    SDR_NUMBER: "SdrNumber",
    RIC: "Ric",
    PROGRESSION_DATA_CODE_A: "ProgressionDateCodeA",
    PROGRESSION_DATA_CODE_B: "ProgressionDateCodeB",
    PROGRESSION_DATA_CODE_C: "ProgressionDateCodeC",
    PROGRESSION_DATA_CODE_D: "ProgressionDateCodeD",
    PROGRESSION_DATA_CODE_E: "ProgressionDateCodeE",
    PROGRESSION_DATA_CODE_F: "ProgressionDateCodeF",
    PROGRESSION_DATA_CODE_G: "ProgressionDateCodeG",
    PROGRESSION_DATA_CODE_I: "ProgressionDateCodeI",
    PROGRESSION_DATA_CODE_J: "ProgressionDateCodeJ",
    PROGRESSION_DATA_CODE_K: "ProgressionDateCodeK",
    PROGRESSION_DATA_CODE_L: "ProgressionDateCodeL",
    PROGRESSION_DATA_CODE_M: "ProgressionDateCodeM",
    PROGRESSION_DATA_CODE_N: "ProgressionDateCodeN",
    PROGRESSION_DATA_CODE_O: "ProgressionDateCodeO",
    PROGRESSION_DATA_CODE_P: "ProgressionDateCodeP",
    PROGRESSION_DATA_CODE_Q: "ProgressionDateCodeQ",
    PROGRESSION_DATA_CODE_R: "ProgressionDateCodeR",
    PROGRESSION_DATA_CODE_W: "ProgressionDateCodeW",
    PROGRESSION_DATA_CODE_X: "ProgressionDateCodeX",
    PROGRESSION_DATA_CODE_Y: "ProgressionDateCodeY",
    PROGRESSION_DATA_CODE_Z: "ProgressionDateCodeZ",
  }
  static CisilDataDescriptionFields = {
    COUNTRY_CODE: "CountryCode",
    IMPLEMENTING_AGENCY: "ImplementingAgency",
    CASE_DESIGNATOR: "CaseDesignator",
    LINE_IMPLEMENTATION_DATE: "LineImplementationDate",
    LINE_NUMBER: "LineNumber",
    GENERIC_CODE: "GenericCode",
    MASL_STOCK_NUMBER: "MaslStockNumber",
    MASL_NOMENCLATURE: "MaslNomenclature",
    LINE_TOTAL_COST: "LineTotalCost",
    MDE_CODE: "MdeCode",
    RIC: "Ric",
  }

  static DSAMSTables = {
    MILESTONES_COLS: [
      {
        field: "Id",
        headerName: "Id",
        hide: true,
        headerClassName: "--grid-header",
      },
      {
        field: this.MilestonesFields.USER_CASE_ID,
        headerName: "User Case Id",
        flex: 1,
        minWidth: 125,
        headerClassName: "--grid-header",
      },
      {
        field: this.MilestonesFields.CUSTOMER_REQUEST_RECEIPT_DATE,
        headerName: "Customer Request Receipt Date",
        flex: 2,
        minWidth: 275,
        headerClassName: "--grid-header",
      },
      {
        field: this.MilestonesFields.CASE_VERSION_TYPE_CODE,
        headerName: "Case Version Type Code",
        flex: 2,
        minWidth: 225,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.MilestonesFields.CASE_VERSION_NUMBER_ID,
        headerName: "Case Version Number Id",
        flex: 2,
        minWidth: 225,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.MilestonesFields.MILESTONE_ID,
        headerName: "Milestone Id",
        flex: 2,
        minWidth: 300,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.MilestonesFields.CASE_VERSION_MILESTONE_DATE,
        headerName: "Case Version Milestone Date",
        flex: 2,
        minWidth: 250,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.MilestonesFields.DATE_TYPE_CODE,
        headerName: "Date Type Code",
        flex: 1,
        minWidth: 150,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.MilestonesFields.CREATE_DATE,
        headerName: "Create Date",
        flex: 2,
        minWidth: 200,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: "Warnings_Errors",
        headerName: "Warnings/Errors",
        sortable: false,
        flex: 3,
        minWidth: 350,
        headerClassName: "--RFI-grid-warning-header",
        headerAlign: "center",
        renderCell: (params) => {
          if (params?.value) {
            let tooltipMsg = ""
            let lineNumber = null
            if (params?.value?.errors?.length > 0) {
              lineNumber = params?.value?.errors[0]?.rowNumber
              tooltipMsg += `Line Number ${lineNumber}) `
            } else if (params?.value?.warnings?.length > 0) {
              lineNumber = params?.value?.warnings[0]?.rowNumber
              tooltipMsg += `Line Number ${lineNumber}) `
            }
            params?.value?.errors?.forEach((error, index) => (tooltipMsg += `E${index + 1}) ${error.message}\n`))
            params?.value?.warnings?.forEach((warning, index) => (tooltipMsg += `W${index + 1}) ${warning.message}\n`))
            return (
              <WarningsErrorsTooltip
                title={tooltipMsg}
                placement="bottom"
              >
                <div className="--RFI-warning-errors-icons">
                  {params?.value?.warnings?.length > 0 && <Warning />}
                  {params?.value?.errors?.length > 0 && <Cancel />}
                </div>
              </WarningsErrorsTooltip>
            )
          }
          return null
        },
      },
    ],
    PREPARING_ACTIVITY_DATA_COLS: [
      {
        field: "Id",
        headerName: "Id",
        hide: true,
        headerClassName: "--grid-header",
      },
      {
        field: this.ActivityDataFields.USER_CASE_ID,
        headerName: "User Case Id",
        flex: 1,
        minWidth: 150,
        headerClassName: "--grid-header",
      },
      {
        field: this.ActivityDataFields.CUSTOMER_REQUEST_RECEIPT_DATE,
        headerName: "Customer Request Receipt Date",
        flex: 2,
        minWidth: 275,
        headerClassName: "--grid-header",
      },
      {
        field: this.ActivityDataFields.CASE_VERSION_TYPE_CODE,
        headerName: "Case Version Type Code",
        flex: 2,
        minWidth: 250,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.ActivityDataFields.CASE_VERSION_NUMBER_ID,
        headerName: "Case Version Number Id",
        flex: 2,
        minWidth: 250,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.ActivityDataFields.USER_CASE_LINE_NUMBER,
        headerName: "User Case Line Number",
        flex: 2,
        minWidth: 250,
        headerClassName: "--grid-header",
      },
      {
        field: this.ActivityDataFields.CASE_MASTER_LINE_ID,
        headerName: "Case Master Line Id",
        flex: 2,
        minWidth: 200,
        headerClassName: "--grid-header",
      },
      {
        field: this.ActivityDataFields.PARENT_CASE_MASTER_LINE_ID,
        headerName: "Parent Case Master Line Id",
        flex: 2,
        minWidth: 250,
        headerClassName: "--grid-header",
      },
      {
        field: this.ActivityDataFields.USER_CASE_SUBLINE_TEXT,
        headerName: "User Case Subline Text",
        flex: 2,
        minWidth: 225,
        headerClassName: "--grid-header",
      },
      {
        field: this.ActivityDataFields.CASE_LINE_MARK_FOR_DELETION_IN,
        headerName: "Case Line Mark For Deletion In",
        flex: 2,
        minWidth: 275,
        headerClassName: "--grid-header",
        valueGetter: (params) => {
          if (params?.value != null) {
            return params.value === true ? 1 : 0
          }
          return null
        },
      },
      {
        field: this.ActivityDataFields.CASE_LINE_ACTIVITY_ID,
        headerName: "Case Line Activity Id",
        flex: 2,
        minWidth: 200,
        headerClassName: "--grid-header",
      },
      {
        field: this.ActivityDataFields.LINE_MANAGER_ID,
        headerName: "Line Manager Id",
        flex: 1,
        minWidth: 150,
        headerClassName: "--grid-header",
      },
      {
        field: this.ActivityDataFields.CASE_MASTER_LINE_ACTIVITY_ID,
        headerName: "CML Activity Id",
        flex: 1,
        minWidth: 150,
        headerClassName: "--grid-header",
      },
      {
        field: this.ActivityDataFields.SUPPORTING_ORGANIZATIO_TITLE_NAME,
        headerName: "Supporting Organization Title Name",
        flex: 3,
        minWidth: 300,
        headerClassName: "--grid-header",
      },
      {
        field: this.ActivityDataFields.MILITARY_ARTICLE_SERVICE_CODE,
        headerName: "Military Article Service Code",
        flex: 2,
        minWidth: 250,
        headerClassName: "--grid-header",
      },
      {
        field: this.ActivityDataFields.ARTICLE_DESCRIPTION_TEXT,
        headerName: "Article Description Text",
        flex: 2,
        minWidth: 250,
        headerClassName: "--grid-header",
      },
      {
        field: this.ActivityDataFields.GENERIC_CODE,
        headerName: "Generic Code",
        flex: 1,
        minWidth: 150,
        headerClassName: "--grid-header",
      },
      {
        field: this.ActivityDataFields.GENERIC_DESCRIPTION_TEXT,
        headerName: "Generic Description Text",
        flex: 3,
        minWidth: 300,
        headerClassName: "--grid-header",
      },
      {
        field: "Warnings_Errors",
        headerName: "Warnings/Errors",
        sortable: false,
        flex: 3,
        minWidth: 350,
        headerClassName: "--RFI-grid-warning-header",
        headerAlign: "center",
        renderCell: (params) => {
          if (params?.value) {
            let tooltipMsg = ""
            let lineNumber = null
            if (params?.value?.errors?.length > 0) {
              lineNumber = params?.value?.errors[0]?.rowNumber
              tooltipMsg += `Line Number ${lineNumber}) `
            } else if (params?.value?.warnings?.length > 0) {
              lineNumber = params?.value?.warnings[0]?.rowNumber
              tooltipMsg += `Line Number ${lineNumber}) `
            }
            params?.value?.errors?.forEach((error, index) => (tooltipMsg += `E${index + 1}) ${error.message}\n`))
            params?.value?.warnings?.forEach((warning, index) => (tooltipMsg += `W${index + 1}) ${warning.message}\n`))
            return (
              <WarningsErrorsTooltip
                title={tooltipMsg}
                placement="bottom"
              >
                <div className="--RFI-warning-errors-icons">
                  {params?.value?.warnings?.length > 0 && <Warning />}
                  {params?.value?.errors?.length > 0 && <Cancel />}
                </div>
              </WarningsErrorsTooltip>
            )
          }
          return null
        },
      },
    ],
    CISIL_CCOPES_LINE_DATA_COLS: [
      {
        field: "Id",
        headerName: "Id",
        hide: true,
        headerClassName: "--grid-header",
      },
      {
        field: this.CcopesLineDataFields.COUNTRY_CODE,
        headerName: "Country Code",
        flex: 1,
        minWidth: 150,
        headerClassName: "--grid-header",
      },
      {
        field: this.CcopesLineDataFields.IMPLEMENTING_AGENCY,
        headerName: "Implementing Agency",
        flex: 2,
        minWidth: 200,
        headerClassName: "--grid-header",
      },
      {
        field: this.CcopesLineDataFields.CASE_DESIGNATOR,
        headerName: "Case Designator",
        flex: 1,
        minWidth: 175,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CcopesLineDataFields.PRIORITY_ONE_DATE,
        headerName: "Priority 1 Date",
        flex: 2,
        minWidth: 200,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CcopesLineDataFields.LINE_NUMBER,
        headerName: "Line Number",
        flex: 1,
        minWidth: 150,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CcopesLineDataFields.RIC,
        headerName: "RIC",
        flex: 1,
        minWidth: 100,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CcopesLineDataFields.CCOPES_LINE_ENTER_DATE,
        headerName: "CCOP Line Enter Date",
        flex: 2,
        minWidth: 200,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CcopesLineDataFields.LAST_REVISION_DATE,
        headerName: "Last Revision Date",
        flex: 2,
        minWidth: 200,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CcopesLineDataFields.NEXT_CERTIFICATION_DATE,
        headerName: "Next Certification Date",
        flex: 2,
        minWidth: 200,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CcopesLineDataFields.ESTIMATED_CERTIFICATION_DATE,
        headerName: "Estimated Certification Date",
        flex: 2,
        minWidth: 250,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CcopesLineDataFields.CLOSE_CERTIFICATION_DATE,
        headerName: "Close Certification Date",
        flex: 2,
        minWidth: 200,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CcopesLineDataFields.DSAA_PRIORITY,
        headerName: "DSAA Priority",
        flex: 1,
        minWidth: 150,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CcopesLineDataFields.PRIORITY,
        headerName: "Priority",
        flex: 1,
        minWidth: 100,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CcopesLineDataFields.ULO_INDICATOR,
        headerName: "ULO Indicator",
        flex: 1,
        minWidth: 150,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CcopesLineDataFields.INHIBITOR_CODE,
        headerName: "Inhibitor Code",
        flex: 1,
        minWidth: 150,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: "Warnings_Errors",
        headerName: "Warnings/Errors",
        sortable: false,
        flex: 3,
        minWidth: 350,
        headerClassName: "--RFI-grid-warning-header",
        headerAlign: "center",
        renderCell: (params) => {
          if (params?.value) {
            let tooltipMsg = ""
            let lineNumber = null
            if (params?.value?.errors?.length > 0) {
              lineNumber = params?.value?.errors[0]?.rowNumber
              tooltipMsg += `Line Number ${lineNumber}) `
            } else if (params?.value?.warnings?.length > 0) {
              lineNumber = params?.value?.warnings[0]?.rowNumber
              tooltipMsg += `Line Number ${lineNumber}) `
            }
            params?.value?.errors?.forEach((error, index) => (tooltipMsg += `E${index + 1}) ${error.message}\n`))
            params?.value?.warnings?.forEach((warning, index) => (tooltipMsg += `W${index + 1}) ${warning.message}\n`))
            return (
              <WarningsErrorsTooltip
                title={tooltipMsg}
                placement="bottom"
              >
                <div className="--RFI-warning-errors-icons">
                  {params?.value?.warnings?.length > 0 && <Warning />}
                  {params?.value?.errors?.length > 0 && <Cancel />}
                </div>
              </WarningsErrorsTooltip>
            )
          }
          return null
        },
      },
    ],
    CISIL_CASES_CERT_CLOSURE_COLS: [
      {
        field: "Id",
        headerName: "Id",
        hide: true,
        headerClassName: "--grid-header",
      },
      {
        field: this.CisilCasesCertClosureFields.COUNTRY_CODE,
        headerName: "Country Code",
        flex: 1,
        minWidth: 150,
        headerClassName: "--grid-header",
      },
      {
        field: this.CisilCasesCertClosureFields.IMPLEMENTING_AGENCY,
        headerName: "Implementing Agency",
        flex: 2,
        minWidth: 200,
        headerClassName: "--grid-header",
      },
      {
        field: this.CisilCasesCertClosureFields.CASE_DESIGNATOR,
        headerName: "Case Designator",
        flex: 1,
        minWidth: 150,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilCasesCertClosureFields.RIC,
        headerName: "RIC",
        flex: 1,
        minWidth: 75,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilCasesCertClosureFields.NUMBER_OF_LINES,
        headerName: "Number Of Lines",
        flex: 1,
        minWidth: 175,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilCasesCertClosureFields.LOA_IMPLEMENTATION_DATE,
        headerName: "LOA Implementation Date",
        flex: 2,
        minWidth: 225,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilCasesCertClosureFields.PRIORITY_ONE_DATE,
        headerName: "Priority 1 Date",
        flex: 1,
        minWidth: 150,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilCasesCertClosureFields.CLOSE_CERTIFICATION_DATE,
        headerName: "Close Certification Date",
        flex: 2,
        minWidth: 225,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilCasesCertClosureFields.ESTIMATED_TOTAL_COST,
        headerName: "Estimated Total Cost",
        flex: 2,
        type: "number",
        minWidth: 200,
        headerClassName: "--RFI-grid-header",
        renderCell: (params) => {
          // Render in $ format
          if (typeof params?.value === "number") {
            return `$${params?.value.toFixed(2)}`
          }
          return null
        },
      },
      {
        field: this.CisilCasesCertClosureFields.UNDELIVERED_VALUE,
        headerName: "Undelivered Value",
        flex: 1,
        type: "number",
        minWidth: 175,
        headerClassName: "--RFI-grid-header",
        renderCell: (params) => {
          // Render in $ format
          if (typeof params?.value === "number") {
            return `$${params?.value.toFixed(2)}`
          }
          return null
        },
      },
      {
        field: "Warnings_Errors",
        headerName: "Warnings/Errors",
        sortable: false,
        flex: 3,
        minWidth: 350,
        headerClassName: "--RFI-grid-warning-header",
        headerAlign: "center",
        renderCell: (params) => {
          if (params?.value) {
            let tooltipMsg = ""
            let lineNumber = null
            if (params?.value?.errors?.length > 0) {
              lineNumber = params?.value?.errors[0]?.rowNumber
              tooltipMsg += `Line Number ${lineNumber}) `
            } else if (params?.value?.warnings?.length > 0) {
              lineNumber = params?.value?.warnings[0]?.rowNumber
              tooltipMsg += `Line Number ${lineNumber}) `
            }
            params?.value?.errors?.forEach((error, index) => (tooltipMsg += `E${index + 1}) ${error.message}\n`))
            params?.value?.warnings?.forEach((warning, index) => (tooltipMsg += `W${index + 1}) ${warning.message}\n`))
            return (
              <WarningsErrorsTooltip
                title={tooltipMsg}
                placement="bottom"
              >
                <div className="--RFI-warning-errors-icons">
                  {params?.value?.warnings?.length > 0 && <Warning />}
                  {params?.value?.errors?.length > 0 && <Cancel />}
                </div>
              </WarningsErrorsTooltip>
            )
          }
          return null
        },
      },
    ],
    CISIL_REQUISITIONS_COLS: [
      {
        field: "Id",
        headerName: "Id",
        hide: true,
        headerClassName: "--grid-header",
      },
      {
        field: this.CisilRequisitionsFields.COUNTRY_CODE,
        headerName: "Country Code",
        flex: 1,
        minWidth: 150,
        headerClassName: "--grid-header",
      },
      {
        field: this.CisilRequisitionsFields.IMPLEMENTING_AGENCY,
        headerName: "Implementing Agency",
        flex: 1,
        minWidth: 200,
        headerClassName: "--grid-header",
      },
      {
        field: this.CisilRequisitionsFields.CASE_DESIGNATOR,
        headerName: "Case Designator",
        flex: 1,
        minWidth: 150,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilRequisitionsFields.LINE_NUMBER,
        headerName: "Line Number",
        flex: 1,
        minWidth: 150,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilRequisitionsFields.RIC,
        headerName: "RIC",
        flex: 1,
        minWidth: 75,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilRequisitionsFields.REQUISITIONS_SHIPPED,
        headerName: "Requisitions Shipped",
        flex: 1,
        minWidth: 200,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: "Warnings_Errors",
        headerName: "Warnings/Errors",
        sortable: false,
        flex: 3,
        minWidth: 350,
        headerClassName: "--RFI-grid-warning-header",
        headerAlign: "center",
        renderCell: (params) => {
          if (params?.value) {
            let tooltipMsg = ""
            let lineNumber = null
            if (params?.value?.errors?.length > 0) {
              lineNumber = params?.value?.errors[0]?.rowNumber
              tooltipMsg += `Line Number ${lineNumber}) `
            } else if (params?.value?.warnings?.length > 0) {
              lineNumber = params?.value?.warnings[0]?.rowNumber
              tooltipMsg += `Line Number ${lineNumber}) `
            }
            params?.value?.errors?.forEach((error, index) => (tooltipMsg += `E${index + 1}) ${error.message}\n`))
            params?.value?.warnings?.forEach((warning, index) => (tooltipMsg += `W${index + 1}) ${warning.message}\n`))
            return (
              <WarningsErrorsTooltip
                title={tooltipMsg}
                placement="bottom"
              >
                <div className="--RFI-warning-errors-icons">
                  {params?.value?.warnings?.length > 0 && <Warning />}
                  {params?.value?.errors?.length > 0 && <Cancel />}
                </div>
              </WarningsErrorsTooltip>
            )
          }
          return null
        },
      },
    ],
    CISIL_SDRs_COLS: [
      {
        field: "Id",
        headerName: "Id",
        hide: true,
        headerClassName: "--grid-header",
      },
      {
        field: this.CisilSDRsFields.COUNTRY_CODE,
        headerName: "Country Code",
        flex: 1,
        minWidth: 150,
        headerClassName: "--grid-header",
      },
      {
        field: this.CisilSDRsFields.IMPLEMENTING_AGENCY,
        headerName: "Implementing Agency",
        flex: 2,
        minWidth: 200,
        headerClassName: "--grid-header",
      },
      {
        field: this.CisilSDRsFields.CASE_DESIGNATOR,
        headerName: "Case Designator",
        flex: 1,
        minWidth: 150,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilSDRsFields.LINE_NUMBER,
        headerName: "Line Number",
        flex: 1,
        minWidth: 150,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilSDRsFields.SDR_NUMBER,
        headerName: "SDR Number",
        flex: 1,
        minWidth: 150,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilSDRsFields.RIC,
        headerName: "RIC",
        flex: 1,
        minWidth: 75,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilSDRsFields.PROGRESSION_DATA_CODE_A,
        headerName: "Progression Date Code A",
        flex: 2,
        minWidth: 225,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilSDRsFields.PROGRESSION_DATA_CODE_B,
        headerName: "Progression Date Code B",
        flex: 2,
        minWidth: 225,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilSDRsFields.PROGRESSION_DATA_CODE_C,
        headerName: "Progression Date Code C",
        flex: 2,
        minWidth: 225,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilSDRsFields.PROGRESSION_DATA_CODE_D,
        headerName: "Progression Date Code D",
        flex: 2,
        minWidth: 225,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilSDRsFields.PROGRESSION_DATA_CODE_E,
        headerName: "Progression Date Code E",
        flex: 2,
        minWidth: 225,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilSDRsFields.PROGRESSION_DATA_CODE_F,
        headerName: "Progression Date Code F",
        flex: 2,
        minWidth: 225,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilSDRsFields.PROGRESSION_DATA_CODE_G,
        headerName: "Progression Date Code G",
        flex: 2,
        minWidth: 225,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilSDRsFields.PROGRESSION_DATA_CODE_I,
        headerName: "Progression Date Code I",
        flex: 2,
        minWidth: 225,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilSDRsFields.PROGRESSION_DATA_CODE_J,
        headerName: "Progression Date Code J",
        flex: 2,
        minWidth: 225,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilSDRsFields.PROGRESSION_DATA_CODE_K,
        headerName: "Progression Date Code K",
        flex: 2,
        minWidth: 225,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilSDRsFields.PROGRESSION_DATA_CODE_L,
        headerName: "Progression Date Code L",
        flex: 2,
        minWidth: 225,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilSDRsFields.PROGRESSION_DATA_CODE_M,
        headerName: "Progression Date Code M",
        flex: 2,
        minWidth: 225,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilSDRsFields.PROGRESSION_DATA_CODE_N,
        headerName: "Progression Date Code N",
        flex: 2,
        minWidth: 225,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilSDRsFields.PROGRESSION_DATA_CODE_O,
        headerName: "Progression Date Code O",
        flex: 2,
        minWidth: 225,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilSDRsFields.PROGRESSION_DATA_CODE_P,
        headerName: "Progression Date Code P",
        flex: 2,
        minWidth: 225,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilSDRsFields.PROGRESSION_DATA_CODE_Q,
        headerName: "Progression Date Code Q",
        flex: 2,
        minWidth: 225,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilSDRsFields.PROGRESSION_DATA_CODE_R,
        headerName: "Progression Date Code R",
        flex: 2,
        minWidth: 225,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilSDRsFields.PROGRESSION_DATA_CODE_W,
        headerName: "Progression Date Code W",
        flex: 2,
        minWidth: 225,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilSDRsFields.PROGRESSION_DATA_CODE_X,
        headerName: "Progression Date Code X",
        flex: 2,
        minWidth: 225,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilSDRsFields.PROGRESSION_DATA_CODE_Y,
        headerName: "Progression Date Code Y",
        flex: 2,
        minWidth: 225,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilSDRsFields.PROGRESSION_DATA_CODE_Z,
        headerName: "Progression Date Code Z",
        flex: 2,
        minWidth: 225,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: "Warnings_Errors",
        headerName: "Warnings/Errors",
        sortable: false,
        flex: 3,
        minWidth: 350,
        headerClassName: "--RFI-grid-warning-header",
        headerAlign: "center",
        renderCell: (params) => {
          if (params?.value) {
            let tooltipMsg = ""
            let lineNumber = null
            if (params?.value?.errors?.length > 0) {
              lineNumber = params?.value?.errors[0]?.rowNumber
              tooltipMsg += `Line Number ${lineNumber}) `
            } else if (params?.value?.warnings?.length > 0) {
              lineNumber = params?.value?.warnings[0]?.rowNumber
              tooltipMsg += `Line Number ${lineNumber}) `
            }
            params?.value?.errors?.forEach((error, index) => (tooltipMsg += `E${index + 1}) ${error.message}\n`))
            params?.value?.warnings?.forEach((warning, index) => (tooltipMsg += `W${index + 1}) ${warning.message}\n`))
            return (
              <WarningsErrorsTooltip
                title={tooltipMsg}
                placement="bottom"
              >
                <div className="--RFI-warning-errors-icons">
                  {params?.value?.warnings?.length > 0 && <Warning />}
                  {params?.value?.errors?.length > 0 && <Cancel />}
                </div>
              </WarningsErrorsTooltip>
            )
          }
          return null
        },
      },
    ],
    CISIL_DATA_DESCRIPTION_COLS: [
      {
        field: "Id",
        headerName: "Id",
        hide: true,
        headerClassName: "--grid-header",
      },
      {
        field: this.CisilDataDescriptionFields.COUNTRY_CODE,
        headerName: "Country Code",
        flex: 1,
        minWidth: 150,
        headerClassName: "--grid-header",
      },
      {
        field: this.CisilDataDescriptionFields.IMPLEMENTING_AGENCY,
        headerName: "Implementing Agency",
        flex: 2,
        minWidth: 200,
        headerClassName: "--grid-header",
      },
      {
        field: this.CisilDataDescriptionFields.CASE_DESIGNATOR,
        headerName: "Case Designator",
        flex: 1,
        minWidth: 150,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilDataDescriptionFields.LINE_IMPLEMENTATION_DATE,
        headerName: "Line Implementation Date",
        flex: 2,
        minWidth: 250,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilDataDescriptionFields.LINE_NUMBER,
        headerName: "Line Number",
        flex: 1,
        minWidth: 150,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilDataDescriptionFields.GENERIC_CODE,
        headerName: "Generic Code",
        flex: 1,
        minWidth: 150,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilDataDescriptionFields.MASL_STOCK_NUMBER,
        headerName: "MASL Stock Number",
        flex: 2,
        minWidth: 200,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilDataDescriptionFields.MASL_NOMENCLATURE,
        headerName: "MASL Nomenclature",
        flex: 2,
        minWidth: 200,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilDataDescriptionFields.LINE_TOTAL_COST,
        headerName: "Line Total Cost",
        flex: 1,
        type: "number",
        minWidth: 150,
        headerClassName: "--RFI-grid-header",
        renderCell: (params) => {
          // Render in $ format
          if (typeof params?.value === "number") {
            return `$${params?.value.toFixed(2)}`
          }
          return null
        },
      },
      {
        field: this.CisilDataDescriptionFields.MDE_CODE,
        headerName: "MDE Code",
        flex: 1,
        minWidth: 125,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: this.CisilDataDescriptionFields.RIC,
        headerName: "RIC",
        flex: 1,
        minWidth: 75,
        headerClassName: "--RFI-grid-header",
      },
      {
        field: "Warnings_Errors",
        headerName: "Warnings/Errors",
        sortable: false,
        flex: 3,
        minWidth: 350,
        headerClassName: "--RFI-grid-warning-header",
        headerAlign: "center",
        renderCell: (params) => {
          if (params?.value) {
            let tooltipMsg = ""
            let lineNumber = null
            if (params?.value?.errors?.length > 0) {
              lineNumber = params?.value?.errors[0]?.rowNumber
              tooltipMsg += `Line Number ${lineNumber}) `
            } else if (params?.value?.warnings?.length > 0) {
              lineNumber = params?.value?.warnings[0]?.rowNumber
              tooltipMsg += `Line Number ${lineNumber}) `
            }
            params?.value?.errors?.forEach((error, index) => (tooltipMsg += `E${index + 1}) ${error.message}\n`))
            params?.value?.warnings?.forEach((warning, index) => (tooltipMsg += `W${index + 1}) ${warning.message}\n`))
            return (
              <WarningsErrorsTooltip
                title={tooltipMsg}
                placement="bottom"
              >
                <div className="--RFI-warning-errors-icons">
                  {params?.value?.warnings?.length > 0 && <Warning />}
                  {params?.value?.errors?.length > 0 && <Cancel />}
                </div>
              </WarningsErrorsTooltip>
            )
          }
          return null
        },
      },
    ],
  }

  // TODO:  Update rfi to rfiName
  static UrlApiTypes = {
    [this.Tabs.MILESTONES]: {
      record: "DsamsMilestones",
      rfi: "DsamsMilestoneRfis",
      rfiId: "dsamsMilestoneRfiId",
      columns: this.DSAMSTables.MILESTONES_COLS,
      headers: this.MilestonesFields,
    },
    [this.Tabs.PREPARING_ACTIVITY_DATA]: {
      record: "DsamsPreparingActivityDatas",
      rfi: "DsamsPreparingActivityDataRfis",
      rfiId: "dsamsPreparingActivityDataRfiId",
      columns: this.DSAMSTables.PREPARING_ACTIVITY_DATA_COLS,
      headers: this.ActivityDataFields,
    },
    [this.Tabs.CISIL_CCOPES_LINE_DATA]: {
      record: "CisilCcopesLineDatas",
      rfi: "CisilCcopesLineDataRfis",
      rfiId: "cisilCcopesLineDataRfiId",
      columns: this.DSAMSTables.CISIL_CCOPES_LINE_DATA_COLS,
      headers: this.CcopesLineDataFields,
    },
    [this.Tabs.CISIL_CASES_CERT_CLOSURE]: {
      record: "CisilCasesCertClosures",
      rfi: "CisilCasesCertClosureRfis",
      rfiId: "cisilCasesCertClosureRfiId",
      columns: this.DSAMSTables.CISIL_CASES_CERT_CLOSURE_COLS,
      headers: this.CisilCasesCertClosureFields,
    },
    [this.Tabs.CISIL_CASES_CERT_CLOSURE_WO_PRI_ONE_DATE]: {
      record: "CisilCasesCertClosuresAll",
      rfi: "CisilCasesCertClosureAllRfis",
      rfiId: "cisilCasesCertClosureAllRfiId",
      columns: this.DSAMSTables.CISIL_CASES_CERT_CLOSURE_COLS,
      headers: this.CisilCasesCertClosureFields,
    },
    [this.Tabs.CISIL_REQUISITIONS]: {
      record: "CisilRequisitions",
      rfi: "CisilRequisitionRfis",
      rfiId: "cisilRequisitionRfiId",
      columns: this.DSAMSTables.CISIL_REQUISITIONS_COLS,
      headers: this.CisilRequisitionsFields,
    },
    [this.Tabs.CISIL_SDRs]: {
      record: "CisilSdrs",
      rfi: "CisilSdrRfis",
      rfiId: "cisilSdrRfiId",
      columns: this.DSAMSTables.CISIL_SDRs_COLS,
      headers: this.CisilSDRsFields,
    },
    [this.Tabs.CISIL_DATA_DESCRIPTIONS]: {
      record: "CisilDataDescriptions",
      rfi: "CisilDataDescriptionRfis",
      rfiId: "cisilDataDescriptionRfiId",
      columns: this.DSAMSTables.CISIL_DATA_DESCRIPTION_COLS,
      headers: this.CisilDataDescriptionFields,
    },
  }

  static NonNullableFields = {
    [this.Tabs.MILESTONES]: [
      this.MilestonesFields.USER_CASE_ID,
      this.MilestonesFields.CUSTOMER_REQUEST_RECEIPT_DATE,
      this.MilestonesFields.CASE_VERSION_MILESTONE_DATE,
      this.MilestonesFields.CREATE_DATE,
    ],
    [this.Tabs.PREPARING_ACTIVITY_DATA]: [this.ActivityDataFields.USER_CASE_ID, this.ActivityDataFields.CUSTOMER_REQUEST_RECEIPT_DATE],
    [this.Tabs.CISIL_CCOPES_LINE_DATA]: [
      this.CcopesLineDataFields.PRIORITY_ONE_DATE,
      this.CcopesLineDataFields.LINE_NUMBER,
      this.CcopesLineDataFields.CCOPES_LINE_ENTER_DATE,
      this.CcopesLineDataFields.DSAA_PRIORITY,
      this.CcopesLineDataFields.PRIORITY,
      this.CcopesLineDataFields.ULO_INDICATOR,
      this.CcopesLineDataFields.INHIBITOR_CODE,
      this.CcopesLineDataFields.COUNTRY_CODE,
      this.CcopesLineDataFields.IMPLEMENTING_AGENCY,
      this.CcopesLineDataFields.CASE_DESIGNATOR,
      this.CcopesLineDataFields.RIC,
    ],
    [this.Tabs.CISIL_CASES_CERT_CLOSURE]: [
      this.CisilCasesCertClosureFields.NUMBER_OF_LINES,
      this.CisilCasesCertClosureFields.LOA_IMPLEMENTATION_DATE,
      this.CisilCasesCertClosureFields.PRIORITY_ONE_DATE,
      this.CisilCasesCertClosureFields.CLOSE_CERTIFICATION_DATE,
      this.CisilCasesCertClosureFields.ESTIMATED_TOTAL_COST,
      this.CisilCasesCertClosureFields.UNDELIVERED_VALUE,
      this.CisilCasesCertClosureFields.COUNTRY_CODE,
      this.CisilCasesCertClosureFields.IMPLEMENTING_AGENCY,
      this.CisilCasesCertClosureFields.CASE_DESIGNATOR,
      this.CisilCasesCertClosureFields.RIC,
    ],
    [this.Tabs.CISIL_CASES_CERT_CLOSURE_WO_PRI_ONE_DATE]: [
      this.CisilCasesCertClosureFields.NUMBER_OF_LINES,
      this.CisilCasesCertClosureFields.LOA_IMPLEMENTATION_DATE,
      this.CisilCasesCertClosureFields.CLOSE_CERTIFICATION_DATE,
      this.CisilCasesCertClosureFields.ESTIMATED_TOTAL_COST,
      this.CisilCasesCertClosureFields.UNDELIVERED_VALUE,
      this.CisilCasesCertClosureFields.COUNTRY_CODE,
      this.CisilCasesCertClosureFields.IMPLEMENTING_AGENCY,
      this.CisilCasesCertClosureFields.CASE_DESIGNATOR,
      this.CisilCasesCertClosureFields.RIC,
    ],
    [this.Tabs.CISIL_REQUISITIONS]: [
      this.CisilRequisitionsFields.LINE_NUMBER,
      this.CisilRequisitionsFields.REQUISITIONS_SHIPPED,
      this.CisilRequisitionsFields.COUNTRY_CODE,
      this.CisilRequisitionsFields.IMPLEMENTING_AGENCY,
      this.CisilRequisitionsFields.CASE_DESIGNATOR,
      this.CisilRequisitionsFields.RIC,
    ],
    [this.Tabs.CISIL_SDRs]: [
      this.CisilSDRsFields.LINE_NUMBER,
      this.CisilSDRsFields.COUNTRY_CODE,
      this.CisilSDRsFields.IMPLEMENTING_AGENCY,
      this.CisilSDRsFields.CASE_DESIGNATOR,
      this.CisilSDRsFields.RIC,
    ],
    [this.Tabs.CISIL_DATA_DESCRIPTIONS]: [
      this.CisilDataDescriptionFields.LINE_IMPLEMENTATION_DATE,
      this.CisilDataDescriptionFields.LINE_NUMBER,
      this.CisilDataDescriptionFields.MASL_STOCK_NUMBER,
      this.CisilDataDescriptionFields.MASL_NOMENCLATURE,
      this.CisilDataDescriptionFields.LINE_TOTAL_COST,
      this.CisilDataDescriptionFields.COUNTRY_CODE,
      this.CisilDataDescriptionFields.IMPLEMENTING_AGENCY,
      this.CisilDataDescriptionFields.CASE_DESIGNATOR,
      this.CisilDataDescriptionFields.RIC,
    ],
  }

  static DateFields = {
    [this.Tabs.MILESTONES]: [
      this.MilestonesFields.CUSTOMER_REQUEST_RECEIPT_DATE,
      this.MilestonesFields.CASE_VERSION_MILESTONE_DATE,
      this.MilestonesFields.CREATE_DATE,
    ],
    [this.Tabs.PREPARING_ACTIVITY_DATA]: [this.ActivityDataFields.CUSTOMER_REQUEST_RECEIPT_DATE],
    [this.Tabs.CISIL_CCOPES_LINE_DATA]: [
      this.CcopesLineDataFields.PRIORITY_ONE_DATE,
      this.CcopesLineDataFields.CCOPES_LINE_ENTER_DATE,
      this.CcopesLineDataFields.LAST_REVISION_DATE,
      this.CcopesLineDataFields.NEXT_CERTIFICATION_DATE,
      this.CcopesLineDataFields.ESTIMATED_CERTIFICATION_DATE,
      this.CcopesLineDataFields.CLOSE_CERTIFICATION_DATE,
    ],
    [this.Tabs.CISIL_CASES_CERT_CLOSURE]: [
      this.CisilCasesCertClosureFields.LOA_IMPLEMENTATION_DATE,
      this.CisilCasesCertClosureFields.PRIORITY_ONE_DATE,
      this.CisilCasesCertClosureFields.CLOSE_CERTIFICATION_DATE,
    ],
    [this.Tabs.CISIL_CASES_CERT_CLOSURE_WO_PRI_ONE_DATE]: [
      this.CisilCasesCertClosureFields.LOA_IMPLEMENTATION_DATE,
      this.CisilCasesCertClosureFields.PRIORITY_ONE_DATE,
      this.CisilCasesCertClosureFields.CLOSE_CERTIFICATION_DATE,
    ],
    [this.Tabs.CISIL_REQUISITIONS]: [],
    [this.Tabs.CISIL_SDRs]: [
      this.CisilSDRsFields.PROGRESSION_DATA_CODE_A,
      this.CisilSDRsFields.PROGRESSION_DATA_CODE_B,
      this.CisilSDRsFields.PROGRESSION_DATA_CODE_C,
      this.CisilSDRsFields.PROGRESSION_DATA_CODE_D,
      this.CisilSDRsFields.PROGRESSION_DATA_CODE_E,
      this.CisilSDRsFields.PROGRESSION_DATA_CODE_F,
      this.CisilSDRsFields.PROGRESSION_DATA_CODE_G,
      this.CisilSDRsFields.PROGRESSION_DATA_CODE_I,
      this.CisilSDRsFields.PROGRESSION_DATA_CODE_J,
      this.CisilSDRsFields.PROGRESSION_DATA_CODE_K,
      this.CisilSDRsFields.PROGRESSION_DATA_CODE_L,
      this.CisilSDRsFields.PROGRESSION_DATA_CODE_M,
      this.CisilSDRsFields.PROGRESSION_DATA_CODE_N,
      this.CisilSDRsFields.PROGRESSION_DATA_CODE_O,
      this.CisilSDRsFields.PROGRESSION_DATA_CODE_P,
      this.CisilSDRsFields.PROGRESSION_DATA_CODE_Q,
      this.CisilSDRsFields.PROGRESSION_DATA_CODE_R,
      this.CisilSDRsFields.PROGRESSION_DATA_CODE_W,
      this.CisilSDRsFields.PROGRESSION_DATA_CODE_X,
      this.CisilSDRsFields.PROGRESSION_DATA_CODE_Y,
      this.CisilSDRsFields.PROGRESSION_DATA_CODE_Z,
    ],
    [this.Tabs.CISIL_DATA_DESCRIPTIONS]: [this.CisilDataDescriptionFields.LINE_IMPLEMENTATION_DATE],
  }

  static NumericFields = {
    [this.Tabs.MILESTONES]: [],
    [this.Tabs.PREPARING_ACTIVITY_DATA]: [this.ActivityDataFields.CASE_LINE_MARK_FOR_DELETION_IN],
    [this.Tabs.CISIL_CCOPES_LINE_DATA]: [this.CcopesLineDataFields.DSAA_PRIORITY, this.CcopesLineDataFields.PRIORITY],
    [this.Tabs.CISIL_CASES_CERT_CLOSURE]: [
      this.CisilCasesCertClosureFields.NUMBER_OF_LINES,
      this.CisilCasesCertClosureFields.ESTIMATED_TOTAL_COST,
      this.CisilCasesCertClosureFields.UNDELIVERED_VALUE,
    ],
    [this.Tabs.CISIL_CASES_CERT_CLOSURE_WO_PRI_ONE_DATE]: [
      this.CisilCasesCertClosureFields.NUMBER_OF_LINES,
      this.CisilCasesCertClosureFields.ESTIMATED_TOTAL_COST,
      this.CisilCasesCertClosureFields.UNDELIVERED_VALUE,
    ],
    [this.Tabs.CISIL_REQUISITIONS]: [this.CisilRequisitionsFields.REQUISITIONS_SHIPPED],
    [this.Tabs.CISIL_SDRs]: [],
    [this.Tabs.CISIL_DATA_DESCRIPTIONS]: [this.CisilDataDescriptionFields.LINE_TOTAL_COST],
  }

  static RFIInstructions = {
    [this.Tabs.MILESTONES]: [
      "Log into DSAMS Cognos",
      "Navigate to: DSAMS Ad Hoc Reports > Army > Army - USASAC - PMO",
      'Run "Milestones" report filter to specified timeframe, and export data to CSV file',
    ],
    [this.Tabs.PREPARING_ACTIVITY_DATA]: [
      "Log into DSAMS Cognos",
      "Navigate to: DSAMS Ad Hoc Reports > Army > Army - USASAC - PMO",
      'Run "Preparing Activity" report (direct link here), filter to specified timeframe, and export data to CSV file',
    ],
    [this.Tabs.CISIL_CCOPES_LINE_DATA]: [
      "Authoritative Source: CCOPES",
      "Location of data pull:  Army Reports: Team content/Army Reports/CISIL Reports/Shared Reports/USASAC/PMO/CWAT Reports/CWAT CCOPES FILE",
      "Instructions: All lines where CCOP LINE ENTER DT (BEGIN) is November 20, 1955 and CCOP LINE ENTER DATE (END) is the last day of the most recently completed FY",
    ],
    [this.Tabs.CISIL_CASES_CERT_CLOSURE]: [
      "Authoritative Source: CISIL",
      "Location of  data pull:  Army Reports: Team content/Army Reports/CISIL Reports/Shared Reports/USASAC/PMO/CWAT Reports/CWAT CISIL CASES CERT CLOSURE ALL",
      "Instructions: All cases with a Case Closed Cert Date as of the last day of the most recently completed FY. Note, this RFI is meant to capture those cases certified for closure without a Priority 1 Date.",
    ],
    [this.Tabs.CISIL_CASES_CERT_CLOSURE_WO_PRI_ONE_DATE]: [
      "Authoritative Source: CISIL",
      "Location of  data pull:  Army Reports: Team content/Army Reports/CISIL Reports/Shared Reports/USASAC/PMO/CWAT Reports/CWAT CISIL CASES CERT CLOSURE",
      "Instructions: All lines where PRIORITY 1 DATE BEGIN is November 20, 1955 and PRIORITY 1 DATE END is the last day of the most recently completed FY",
    ],
    [this.Tabs.CISIL_REQUISITIONS]: [
      "Authoritative Source: CISIL",
      "Location of data pull:  Army Reports: Team content/Army Reports/CISIL Reports/Shared Reports/USASAC/PMO/CWAT Reports/CWAT CISIL REQUISITIONS RFI TEMPLATE",
      "Instructions: All lines where LATEST SHP DATE (BEGIN) is November 20, 1955 and LATEST SHP DATE (END) is the last day of the most recently completed FY",
    ],
    [this.Tabs.CISIL_SDRs]: [
      "Authoritative Source: CISIL",
      "Location of data pull:  Army Reports: Team content/Army Reports/CISIL Reports/Shared Reports/USASAC/PMO/CWAT Reports/CWAT CISIL SDRS RFI",
      "Instructions: All SDRs where PROG DT CD A BEGIN is November 20, 1955 and PROG DT CD A END is the last day of the most recently completed FY",
    ],
    [this.Tabs.CISIL_DATA_DESCRIPTIONS]: [
      "Authoritative Source: CISIL",
      "Location of data pull:  Army Reports: Team content/Army Reports/CISIL Reports/Shared Reports/USASAC/PMO/CWAT Reports/CWAT CISIL DATA DESCRIPTION",
      "Instructions: All lines where LINE IMPL DATE BEGIN is November 20, 1955 and LINE IMPL DATE END is the last day of the most recently completed FY",
    ],
  }
}

export default DSAMSTableUtils

import DSAMSTableUtils from "./DSAMS_Table_Utils"

class DSAMS_CISIL_CSV_Data_Loader {
  static convertDataToTableRows(selectedTab, pageRecords) {
    const tmpTableRows = []
    switch (selectedTab) {
      case DSAMSTableUtils.Tabs.MILESTONES:
        pageRecords.forEach((data) => {
          tmpTableRows.push({
            Id: data.id,
            [DSAMSTableUtils.MilestonesFields.USER_CASE_ID]: data.userCaseId,
            [DSAMSTableUtils.MilestonesFields.CUSTOMER_REQUEST_RECEIPT_DATE]: data.customerRequestReceiptDate,
            [DSAMSTableUtils.MilestonesFields.CASE_VERSION_TYPE_CODE]: data.caseVersionTypeCode.replace("_AND_", "&"),
            [DSAMSTableUtils.MilestonesFields.CASE_VERSION_NUMBER_ID]: data.caseVersionNumberId,
            [DSAMSTableUtils.MilestonesFields.MILESTONE_ID]: data.milestoneId,
            [DSAMSTableUtils.MilestonesFields.CASE_VERSION_MILESTONE_DATE]: data.caseVersionMilestoneDate,
            [DSAMSTableUtils.MilestonesFields.DATE_TYPE_CODE]: data.dateTypeCode,
            [DSAMSTableUtils.MilestonesFields.CREATE_DATE]: data.createDate,
          })
        })
        break
      case DSAMSTableUtils.Tabs.PREPARING_ACTIVITY_DATA:
        pageRecords.forEach((data) => {
          tmpTableRows.push({
            Id: data.id,
            [DSAMSTableUtils.ActivityDataFields.USER_CASE_ID]: data.userCaseId,
            [DSAMSTableUtils.ActivityDataFields.CUSTOMER_REQUEST_RECEIPT_DATE]: data.customerRequestReceiptDate,
            [DSAMSTableUtils.ActivityDataFields.CASE_VERSION_TYPE_CODE]: data.caseVersionTypeCode.replace("_AND_", "&"),
            [DSAMSTableUtils.ActivityDataFields.CASE_VERSION_NUMBER_ID]: data.caseVersionNumberId,
            [DSAMSTableUtils.ActivityDataFields.USER_CASE_LINE_NUMBER]: data.userCaseLineNumberId,
            [DSAMSTableUtils.ActivityDataFields.CASE_MASTER_LINE_ID]: data.caseMasterLineId,
            [DSAMSTableUtils.ActivityDataFields.PARENT_CASE_MASTER_LINE_ID]: data.parentCaseMasterLineId,
            [DSAMSTableUtils.ActivityDataFields.USER_CASE_SUBLINE_TEXT]: data.userCaseSublineText,
            [DSAMSTableUtils.ActivityDataFields.CASE_LINE_MARK_FOR_DELETION_IN]: data.caseLineMarkForDeletionIn,
            [DSAMSTableUtils.ActivityDataFields.CASE_LINE_ACTIVITY_ID]: data.caseLineActivityId,
            [DSAMSTableUtils.ActivityDataFields.LINE_MANAGER_ID]:
              JSON.stringify(data.lineManagerId) != JSON.stringify("\u0000") ? data.lineManagerId : null,
            [DSAMSTableUtils.ActivityDataFields.CASE_MASTER_LINE_ACTIVITY_ID]: data.caseMasterLineActivityId,
            [DSAMSTableUtils.ActivityDataFields.SUPPORTING_ORGANIZATIO_TITLE_NAME]: data.supportingOrganizationTitleName,
            [DSAMSTableUtils.ActivityDataFields.MILITARY_ARTICLE_SERVICE_CODE]: data.militaryArticleServiceCode,
            [DSAMSTableUtils.ActivityDataFields.ARTICLE_DESCRIPTION_TEXT]: data.articleDescriptionText,
            [DSAMSTableUtils.ActivityDataFields.GENERIC_CODE]: data.genericCode,
            [DSAMSTableUtils.ActivityDataFields.GENERIC_DESCRIPTION_TEXT]: data.genericDescriptionText,
          })
        })
        break
      case DSAMSTableUtils.Tabs.CISIL_CCOPES_LINE_DATA:
        pageRecords.forEach((data) => {
          tmpTableRows.push({
            Id: data.id,
            [DSAMSTableUtils.CcopesLineDataFields.COUNTRY_CODE]: data.countryCode,
            [DSAMSTableUtils.CcopesLineDataFields.IMPLEMENTING_AGENCY]: data.implementingAgency,
            [DSAMSTableUtils.CcopesLineDataFields.CASE_DESIGNATOR]: data.caseDesignator,
            [DSAMSTableUtils.CcopesLineDataFields.PRIORITY_ONE_DATE]: data.priorityOneDate,
            [DSAMSTableUtils.CcopesLineDataFields.LINE_NUMBER]: data.lineNumber,
            [DSAMSTableUtils.CcopesLineDataFields.RIC]: data.ric,
            [DSAMSTableUtils.CcopesLineDataFields.CCOPES_LINE_ENTER_DATE]: data.ccopesLineEnterDate,
            [DSAMSTableUtils.CcopesLineDataFields.LAST_REVISION_DATE]: data.lastRevisionDate,
            [DSAMSTableUtils.CcopesLineDataFields.NEXT_CERTIFICATION_DATE]: data.nextCertificationDate,
            [DSAMSTableUtils.CcopesLineDataFields.ESTIMATED_CERTIFICATION_DATE]: data.estimatedCertificationDate,
            [DSAMSTableUtils.CcopesLineDataFields.CCOPES_LINE_ENTER_DATE]: data.closeCertificationDate,
            [DSAMSTableUtils.CcopesLineDataFields.DSAA_PRIORITY]: data.dsaaPriority,
            [DSAMSTableUtils.CcopesLineDataFields.PRIORITY]: data.priority,
            [DSAMSTableUtils.CcopesLineDataFields.ULO_INDICATOR]: data.uloIndicator,
            [DSAMSTableUtils.CcopesLineDataFields.INHIBITOR_CODE]: data.inhibitorCode,
          })
        })
        break
      case DSAMSTableUtils.Tabs.CISIL_CASES_CERT_CLOSURE:
      case DSAMSTableUtils.Tabs.CISIL_CASES_CERT_CLOSURE_WO_PRI_ONE_DATE:
        pageRecords.forEach((data) => {
          tmpTableRows.push({
            Id: data.id,
            [DSAMSTableUtils.CisilCasesCertClosureFields.COUNTRY_CODE]: data.countryCode,
            [DSAMSTableUtils.CisilCasesCertClosureFields.IMPLEMENTING_AGENCY]: data.implementingAgency,
            [DSAMSTableUtils.CisilCasesCertClosureFields.CASE_DESIGNATOR]: data.caseDesignator,
            [DSAMSTableUtils.CisilCasesCertClosureFields.RIC]: data.ric,
            [DSAMSTableUtils.CisilCasesCertClosureFields.NUMBER_OF_LINES]: data.numberOfLines,
            [DSAMSTableUtils.CisilCasesCertClosureFields.LOA_IMPLEMENTATION_DATE]: data.loaImplementationDate,
            [DSAMSTableUtils.CisilCasesCertClosureFields.PRIORITY_ONE_DATE]: data.priorityOneDate,
            [DSAMSTableUtils.CisilCasesCertClosureFields.CLOSE_CERTIFICATION_DATE]: data.closeCertificationDate,
            [DSAMSTableUtils.CisilCasesCertClosureFields.ESTIMATED_TOTAL_COST]: data.estimatedTotalCost,
            [DSAMSTableUtils.CisilCasesCertClosureFields.UNDELIVERED_VALUE]: data.undeliveredValue,
          })
        })
        break
      case DSAMSTableUtils.Tabs.CISIL_REQUISITIONS:
        pageRecords.forEach((data) => {
          tmpTableRows.push({
            Id: data.id,
            [DSAMSTableUtils.CisilRequisitionsFields.COUNTRY_CODE]: data.countryCode,
            [DSAMSTableUtils.CisilRequisitionsFields.IMPLEMENTING_AGENCY]: data.implementingAgency,
            [DSAMSTableUtils.CisilRequisitionsFields.CASE_DESIGNATOR]: data.caseDesignator,
            [DSAMSTableUtils.CisilRequisitionsFields.RIC]: data.ric,
            [DSAMSTableUtils.CisilRequisitionsFields.LINE_NUMBER]: data.lineNumber,
            [DSAMSTableUtils.CisilRequisitionsFields.REQUISITIONS_SHIPPED]: data.requisitionsShipped,
          })
        })
        break
      case DSAMSTableUtils.Tabs.CISIL_SDRs:
        pageRecords.forEach((data) => {
          tmpTableRows.push({
            Id: data.id,
            [DSAMSTableUtils.CisilSDRsFields.COUNTRY_CODE]: data.countryCode,
            [DSAMSTableUtils.CisilSDRsFields.IMPLEMENTING_AGENCY]: data.implementingAgency,
            [DSAMSTableUtils.CisilSDRsFields.CASE_DESIGNATOR]: data.caseDesignator,
            [DSAMSTableUtils.CisilSDRsFields.RIC]: data.ric,
            [DSAMSTableUtils.CisilSDRsFields.LINE_NUMBER]: data.lineNumber,
            [DSAMSTableUtils.CisilSDRsFields.SDR_NUMBER]: data.sdrNumber,
            [DSAMSTableUtils.CisilSDRsFields.PROGRESSION_DATA_CODE_A]: data.progressionDateCodeA,
            [DSAMSTableUtils.CisilSDRsFields.PROGRESSION_DATA_CODE_B]: data.progressionDateCodeB,
            [DSAMSTableUtils.CisilSDRsFields.PROGRESSION_DATA_CODE_C]: data.progressionDateCodeC,
            [DSAMSTableUtils.CisilSDRsFields.PROGRESSION_DATA_CODE_D]: data.progressionDateCodeD,
            [DSAMSTableUtils.CisilSDRsFields.PROGRESSION_DATA_CODE_E]: data.progressionDateCodeE,
            [DSAMSTableUtils.CisilSDRsFields.PROGRESSION_DATA_CODE_F]: data.progressionDateCodeF,
            [DSAMSTableUtils.CisilSDRsFields.PROGRESSION_DATA_CODE_G]: data.progressionDateCodeG,
            [DSAMSTableUtils.CisilSDRsFields.PROGRESSION_DATA_CODE_I]: data.progressionDateCodeI,
            [DSAMSTableUtils.CisilSDRsFields.PROGRESSION_DATA_CODE_J]: data.progressionDateCodeJ,
            [DSAMSTableUtils.CisilSDRsFields.PROGRESSION_DATA_CODE_K]: data.progressionDateCodeK,
            [DSAMSTableUtils.CisilSDRsFields.PROGRESSION_DATA_CODE_L]: data.progressionDateCodeL,
            [DSAMSTableUtils.CisilSDRsFields.PROGRESSION_DATA_CODE_M]: data.progressionDateCodeM,
            [DSAMSTableUtils.CisilSDRsFields.PROGRESSION_DATA_CODE_N]: data.progressionDateCodeN,
            [DSAMSTableUtils.CisilSDRsFields.PROGRESSION_DATA_CODE_O]: data.progressionDateCodeO,
            [DSAMSTableUtils.CisilSDRsFields.PROGRESSION_DATA_CODE_P]: data.progressionDateCodeP,
            [DSAMSTableUtils.CisilSDRsFields.PROGRESSION_DATA_CODE_Q]: data.progressionDateCodeQ,
            [DSAMSTableUtils.CisilSDRsFields.PROGRESSION_DATA_CODE_R]: data.progressionDateCodeR,
            [DSAMSTableUtils.CisilSDRsFields.PROGRESSION_DATA_CODE_W]: data.progressionDateCodeW,
            [DSAMSTableUtils.CisilSDRsFields.PROGRESSION_DATA_CODE_X]: data.progressionDateCodeX,
            [DSAMSTableUtils.CisilSDRsFields.PROGRESSION_DATA_CODE_Y]: data.progressionDateCodeY,
            [DSAMSTableUtils.CisilSDRsFields.PROGRESSION_DATA_CODE_Z]: data.progressionDateCodeZ,
          })
        })
        break
      case DSAMSTableUtils.Tabs.CISIL_DATA_DESCRIPTIONS:
        pageRecords.forEach((data) => {
          tmpTableRows.push({
            Id: data.id,
            [DSAMSTableUtils.CisilDataDescriptionFields.COUNTRY_CODE]: data.countryCode,
            [DSAMSTableUtils.CisilDataDescriptionFields.IMPLEMENTING_AGENCY]: data.implementingAgency,
            [DSAMSTableUtils.CisilDataDescriptionFields.CASE_DESIGNATOR]: data.caseDesignator,
            [DSAMSTableUtils.CisilDataDescriptionFields.RIC]: data.ric,
            [DSAMSTableUtils.CisilDataDescriptionFields.LINE_NUMBER]: data.lineNumber,
            [DSAMSTableUtils.CisilDataDescriptionFields.GENERIC_CODE]: data.genericCode,
            [DSAMSTableUtils.CisilDataDescriptionFields.LINE_IMPLEMENTATION_DATE]: data.lineImplementationDate,
            [DSAMSTableUtils.CisilDataDescriptionFields.MASL_STOCK_NUMBER]: data.maslStockNumber,
            [DSAMSTableUtils.CisilDataDescriptionFields.MASL_NOMENCLATURE]: data.maslNomenclature,
            [DSAMSTableUtils.CisilDataDescriptionFields.LINE_TOTAL_COST]: data.lineTotalCost,
            [DSAMSTableUtils.CisilDataDescriptionFields.MDE_CODE]: data.mdeCode,
          })
        })
        break
      default:
        break
    }

    return tmpTableRows
  }
}

export default DSAMS_CISIL_CSV_Data_Loader

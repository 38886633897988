import GFEBS_CEFMS_Table_Utils from "./GFEBS_CEFMS_Table_Utils"

class GFEBS_CEFMS_CSV_Data_Loader {
    static convertDataToTableRows(selectedTab, pageRecords) {
		const tmpTableRows = []
		switch (selectedTab) {
			case GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT1:
				pageRecords.forEach(data => tmpTableRows.push({
                    [GFEBS_CEFMS_Table_Utils.GFEBSDataFields.FUND]: data.fund,
                    [GFEBS_CEFMS_Table_Utils.GFEBSDataFields.FUNCTIONAL_AREA]: data.functionalArea,
                    [GFEBS_CEFMS_Table_Utils.GFEBSDataFields.FUNDS_CENTER]: data.fundsCenter,
                    [GFEBS_CEFMS_Table_Utils.GFEBSDataFields.PARTNER_FUND]: data.partnerFund,
                    [GFEBS_CEFMS_Table_Utils.GFEBSDataFields.PARTNER_FUNCTIONAL_AREA]: data.partnerFunctionalArea,
                    [GFEBS_CEFMS_Table_Utils.GFEBSDataFields.PARTNER_FUNDS_CENTER]: data.partnerFundsCenter,
                    [GFEBS_CEFMS_Table_Utils.GFEBSDataFields.PARTNER_FUNDS_CENTER_DESCRIPTION]: data.partnerFundsCenterDescription,
                    [GFEBS_CEFMS_Table_Utils.GFEBSDataFields.COST_CENTER]: data.costCenter,
                    [GFEBS_CEFMS_Table_Utils.GFEBSDataFields.PERSONNEL_NUMBER]: data.personnelNumber,
                    [GFEBS_CEFMS_Table_Utils.GFEBSDataFields.GRC_CODE]: data.grcTypeHourCode,
                    [GFEBS_CEFMS_Table_Utils.GFEBSDataFields.GRC_CODE_DESCRIPTION]: data.grcTypeHourCodeDescription,
                    [GFEBS_CEFMS_Table_Utils.GFEBSDataFields.COST_ELEMENT]: data.costElement,
                    [GFEBS_CEFMS_Table_Utils.GFEBSDataFields.PARTNER_WBS_ELEMENT]: data.partnerWbsElement,
                    [GFEBS_CEFMS_Table_Utils.GFEBSDataFields.FMS_WORK_CATEGORY]: data.fmsWorkCategory,
                    [GFEBS_CEFMS_Table_Utils.GFEBSDataFields.FMS_WORK_CLASSIFICATION]: data.fmsWorkClassification,
                    [GFEBS_CEFMS_Table_Utils.GFEBSDataFields.FMS_WORK_CATEGORY_ELEMENT]: data.fmsWorkCategoryElement,
                    [GFEBS_CEFMS_Table_Utils.GFEBSDataFields.FMS_WORK_CATEGORY_ELEMENT_DESCRIPTION]: data.fmsWorkCategoryElementDescription,
                    [GFEBS_CEFMS_Table_Utils.GFEBSDataFields.PAY_PERIOD_END_DATE]: data.payPeriodEndDate,
                    [GFEBS_CEFMS_Table_Utils.GFEBSDataFields.CHARGED_HOURS]: data.chargedHours,
                }))
				break
			case GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT2:
				pageRecords.forEach(data => tmpTableRows.push({
                    Id: data.id,
                    [GFEBS_CEFMS_Table_Utils.GFEBSR2DataFields.FUND]: data.fund,
                    [GFEBS_CEFMS_Table_Utils.GFEBSR2DataFields.FUNCTIONAL_AREA]: data.functionalArea,
                    [GFEBS_CEFMS_Table_Utils.GFEBSR2DataFields.FUNDS_CENTER]: data.fundsCenter,
                    [GFEBS_CEFMS_Table_Utils.GFEBSR2DataFields.FUNDS_CENTER_DESCRITION]: data.fundsCenterDescription,
                    [GFEBS_CEFMS_Table_Utils.GFEBSR2DataFields.PARTNER_FUND]: data.partnerFund,
                    [GFEBS_CEFMS_Table_Utils.GFEBSR2DataFields.PARTNER_FUNCTIONAL_AREA]: data.partnerFunctionalArea,
                    [GFEBS_CEFMS_Table_Utils.GFEBSR2DataFields.PARTNER_FUNDS_CENTER]: data.partnerFundsCenter,
                    [GFEBS_CEFMS_Table_Utils.GFEBSR2DataFields.COST_CENTER]: data.costCenter,
                    [GFEBS_CEFMS_Table_Utils.GFEBSR2DataFields.PERSONNEL_NUMBER]: data.personnelNumber,
                    [GFEBS_CEFMS_Table_Utils.GFEBSR2DataFields.GRC_CODE]: data.grcTypeHourCode,
                    [GFEBS_CEFMS_Table_Utils.GFEBSR2DataFields.COST_ELEMENT]: data.costElement,
                    [GFEBS_CEFMS_Table_Utils.GFEBSR2DataFields.PARTNER_WBS_ELEMENT]: data.partnerWbsElement,
                    [GFEBS_CEFMS_Table_Utils.GFEBSR2DataFields.FMS_WORK_CATEGORY_ELEMENT]: data.fmsWorkCategoryElement,
                    [GFEBS_CEFMS_Table_Utils.GFEBSR2DataFields.PAY_PERIOD_END_DATE]: data.payPeriodEndDate,
                    [GFEBS_CEFMS_Table_Utils.GFEBSR2DataFields.CHARGED_HOURS]: data.chargedHours,
				}))
				break
			case GFEBS_CEFMS_Table_Utils.Tabs.CEFMS_DATA:
				pageRecords.forEach(data => tmpTableRows.push({
                    Id: data.id,
                    [GFEBS_CEFMS_Table_Utils.CEFMSDataFields.AMSCO_CODE]: data.amscoCode,
                    [GFEBS_CEFMS_Table_Utils.CEFMSDataFields.PAY_PERIOD_END_DATE]: data.payPeriodEndDate,
                    [GFEBS_CEFMS_Table_Utils.CEFMSDataFields.EMPLOYEE_IDENTIFIER]: data.employeeIdentifier,
                    [GFEBS_CEFMS_Table_Utils.CEFMSDataFields.AMSCO_NAME]: data.amscoName,
                    [GFEBS_CEFMS_Table_Utils.CEFMSDataFields.POSITION]: data.position,
                    [GFEBS_CEFMS_Table_Utils.CEFMSDataFields.HOURS_TYPE_CODE]: data.hoursTypeCode,
                    [GFEBS_CEFMS_Table_Utils.CEFMSDataFields.WORK_CATEGORY_DESCRIPTION]: data.workCategoryDescription,
                    [GFEBS_CEFMS_Table_Utils.CEFMSDataFields.LABOR_HOURS]: data.laborHours,
                }))
				break
			default:
				break
		}

        return tmpTableRows
    }
}

export default GFEBS_CEFMS_CSV_Data_Loader
import React from "react"
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import "../RFI.css"

const SupplementalWorkforce_Instructions_Modal = ({ disclaimerRFIModalOpen, setDisclaimerRFIModalOpen }) => {
  return (
    <Dialog
      open={disclaimerRFIModalOpen}
      onClose={() => setDisclaimerRFIModalOpen(false)}
    >
      <DialogTitle className="instruction-title">RFI Instructions - BPC CIV FTE Reduction</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          If your organization cost transferred from FMS Admin to BPC in the most recently completed FY, please identify the corresponding civilian
          FTEs to reduce from your FMS Admin CIV FTE count.  Please only identify FTEs in this section if FMS Admin dollars transferred but the hours
          charged did not in the CIV time charging RFI response you uploaded.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDisclaimerRFIModalOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default SupplementalWorkforce_Instructions_Modal

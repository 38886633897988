// Imports
//
//
import { formatHeader } from "../../../Utils";
import DSAMSTableUtils from "./DSAMS_Table_Utils";

// This class is setup to analyze each row of the uploaded csv file.  This class functions to format values,
// check for a header, and to hold the array of uploaded data.  
//
export default class ResponseAnalyzer
{

    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////                               Class Variables                             //////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    #rfiName;
    #rfiHeaders;
    #knowsHeaderRow;
    #headerRow;
    #mappedResponseRowValues;
    #rowsAnalyzed;

    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////                              Class Constructor                            //////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    constructor(rfiName)
    {
        this.#rfiName = rfiName;

        this.#rfiHeaders = this.#getRfiHeaders(rfiName);

        this.#knowsHeaderRow = false;

        this.#headerRow = 0;

        this.#mappedResponseRowValues = [];

        this.#rowsAnalyzed = 0;

    }  

    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////                                Class Methods                              //////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////


                                        // ------   Getters   ------ //

    get rfiName() { return this.#rfiName }
    get rfiHeaders() { return this.#rfiHeaders }
    get knowsHeaderRow() { return this.#knowsHeaderRow }
    get headerRow() { return this.#headerRow }
    get responseData() { return this.#mappedResponseRowValues }


                                         // ------   Setters   ------ //

    set rfiName(rfiName) { this.#rfiName = rfiName; }
    set rfiHeaders(rfiHeaders) { this.#rfiHeaders = rfiHeaders; }
    set knowsHeaderRow(knowsHeaderRow) { this.#knowsHeaderRow = knowsHeaderRow; }
    set headerRow(headerRow) { this.#headerRow = headerRow; }
    set responseData(responseData) { this.#mappedResponseRowValues = responseData; }


                                    // ------   Constructor Methods   ------ //


    // Gets the necessary headers
    // Provides a statement for exceptions
    #getRfiHeaders(rfiName) {

        let rfiHeaders = [];

        // Get the appropriate header value from the 
        switch (rfiName) {

            case DSAMSTableUtils.Tabs.CISIL_CCOPES_LINE_DATA:

                rfiHeaders = Object.values(DSAMSTableUtils.CcopesLineDataCSVFields);
                break;

            case DSAMSTableUtils.Tabs.CISIL_CASES_CERT_CLOSURE_WO_PRI_ONE_DATE:
            case DSAMSTableUtils.Tabs.CISIL_CASES_CERT_CLOSURE:

                rfiHeaders = Object.values(DSAMSTableUtils.CisilCasesCertClosuresCSVFields);
                break;

            default:

                rfiHeaders = Object.values(DSAMSTableUtils.UrlApiTypes[rfiName].headers)

        } // End of switch

        return rfiHeaders;

    } // End of getRfiHeaders

                                      // ------   Public Methods   ------ //


    // Analyzes each row in the csv file.
    // This method formats the parsed values, searches for the header row, and adds each row to the class response data array
    // Called by the Papa Parse's config object's step property for each row
    // Assumes each row after the header is found is relevant data that is required for the response
    analyze(csvRow, parser) {

        // Update the amount of rows analyzed
        this.#rowsAnalyzed = this.#rowsAnalyzed + 1;

        // Format potential header values and remove whitespace from values
        csvRow = this.#formatCsvRowValues(csvRow);
        csvRow = csvRow.map((rowValue) => formatHeader(rowValue)?.replace(/\s/g, ""));

        // If the header row is not known, check for correct headers, if the header row is known, store the csv row
        if (this.#knowsHeaderRow == false) { this.#checkIfRowIsTheHeaderRow(csvRow); }
        if (this.#knowsHeaderRow == true) { this.#storeRow(csvRow); } 

    } // End of analyze


                                    // ------   Private Methods   ------ //


    // Formats each row value
    // Returns an array
    #formatCsvRowValues(csvRow) {

        // Set Variables
        let rowValues = []

        // Format Each Row Value (element value) in the Row Data Array
        // Do other processing of the row data
        switch (this.#rfiName) {

            case DSAMSTableUtils.Tabs.CISIL_CCOPES_LINE_DATA:

                // Grab only the headers that are needed for the CCOPES Line Data table, 
                // the files uploaded for this table contain other headers we do not have in our DB
                // This excludes headers at index 0, 6, and any headers at index 16 or greater

                let firstArrayOfRowValues = csvRow.data.slice(1, 6)
                let secondArrayOfRowValues = csvRow.data.slice(7, 16)
                let mergedArraysOfRowValues = firstArrayOfRowValues.concat(secondArrayOfRowValues)

                // Iterate through array content and format each value
                rowValues = mergedArraysOfRowValues.map((rowValue) => formatHeader(rowValue))

                break;

            case DSAMSTableUtils.Tabs.CISIL_SDRs:

                // In the Cisil SDRs table, the typecasecode and sdrsuffix headers are not in our DB, so we exclude them here if they are in the file
                const excludedCisilSdrHeaders = ["typecasecode", "sdrsuffix"];

                rowValues = csvRow.data.filter((rowValue) => { return !excludedCisilSdrHeaders.includes(rowValue.toLowerCase().replace(/\s/g, "")) }).map((rowValue) => formatHeader(rowValue))

                break;

            default:

                rowValues = csvRow.data.map((rowValue) => formatHeader(rowValue))

        } // End of switch

        return rowValues;

    } // End of formatCSVRowValues


    // Compares each row value to expected headers and determines if the row is the header row
    // Returns true or false
    #checkIfRowIsTheHeaderRow(csvRow) {

        let rowValuesMatched = 0;
        
        // Check Each Row Value
        csvRow.map((rowValue) => {

            // Check all Headers against the row value
            for (let index = 0; index < this.#rfiHeaders.length; index++) {

                let rfiHeader = this.#rfiHeaders[index];
              
                // Check if the row value is the expected header
                if (rowValue.toLowerCase() === rfiHeader.toLowerCase()) {

                    rowValuesMatched = rowValuesMatched + 1;

                } // End of if

                // If 75% of the headers match, assume the header row has been found
                if (rowValuesMatched >= Math.floor(this.#rfiHeaders.length * 0.75)) {

                    this.#knowsHeaderRow = true;

                    this.#headerRow = this.#rowsAnalyzed;

                    break;
                }

            } // End of for loop


        })  // End of csvRowValuesCleaned.map

        return;

    } // End of checkIfRowIsTheHeaderRow



    // Adds the row to the class array of rows
    // Creates a map of key value pairs with the key being the header name and the value being the row Value
    // Does some other stuff I'm not sure of
    #storeRow(csvRow) {

        let mappedRowValues = {}

        // Add each header name and value to the map object
        for (let index = 0; index < this.#rfiHeaders.length; index++) {

            // Get the row value
            let rowValue = csvRow[index] ? csvRow[index].trim() : "";

            // Add the Header name as the key and the row value as the value
            mappedRowValues[this.#rfiHeaders[index]] = rowValue === "#" ? null : rowValue.trim();

        } // End of for loop

        // Add the mapped row object to the class response data array 
        this.#mappedResponseRowValues.push(mappedRowValues);

        return;

    } // End of storeRow


} // End of class
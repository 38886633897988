import { useEffect } from "react"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { get } from "./api"

const QUERY_KEY = "FiscalCycles"

export const getQueryKey = (queryKey) => {
  return queryKey
}

export const useGet = (queryKey, endpoint) => {
  const query = useQuery({ queryKey: getQueryKey(queryKey), queryFn: () => get(endpoint) })
  return query
}

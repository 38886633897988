import { useState } from "react"

// MUI
import { Box, Tab, Tabs } from "@mui/material"

// Custom
import RFIStatusManagement from "./RFIApproveReject/RFIStatusManagement"
import FTEValidationDashboard from "./FTEValidationDashboard/FTEValidationDashboard"
import CustomTabPanel from "./custom/CustomTabPanel"
import Base from "./elements/layout/Base"

const RFIValidationDashboardMenu = () => {
  const tabs = ["RFI Dashboard", "FTE Validation Dashboard"]

  const [currentTab, setCurrentTab] = useState({
    num: 0,
    open: false,
    name: tabs[0],
  })
  const tabProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
      disableRipple: true,
      variant: "tabbedView",
    }
  }

  const handleTabChange = (e, newValue) => {
    setCurrentTab({
      num: newValue,
      name: tabs[newValue],
    })
  }

  return (
    <Base>
      <Box>
        {/* TABS */}
        <Tabs
          variant="scrollable"
          value={currentTab.num}
          onChange={handleTabChange}
          sx={{
            width: "100%",
            marginBottom: "5px",
            borderColor: "divider",
            ".MuiTabs-flexContainer": {
              justifyContent: "center",
            },
          }}
        >
          <Tab
            label="RFI Dashboard"
            {...tabProps(0)}
          />
          <Tab
            label="FTE Validation Dashboard"
            {...tabProps(1)}
          />
        </Tabs>

        {/* 1 - RFI DASHBOARD*/}
        <CustomTabPanel
          value={currentTab.num}
          index={0}
        >
          <RFIStatusManagement />
        </CustomTabPanel>

        {/* 2 - FTE VALIDATION DASHBOARD*/}
        <CustomTabPanel
          value={currentTab.num}
          index={1}
        >
          <FTEValidationDashboard />
        </CustomTabPanel>
      </Box>
    </Base>
  )
}

export default RFIValidationDashboardMenu

import React from 'react'
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button } from '@mui/material'
import PropTypes from 'prop-types'

function WorkloadInstructions(props) {
    const { open, name, setOpen, line1, line2, line3 } = props

    const handleClose = () => {
        setOpen(false);
    };

    // Using a simple implementation since I think all RFIs have 3 instruction lines.

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle className='--RFI-workload-instruction-title'>RFI Instruction - {name}</DialogTitle>
            <DialogContent dividers>
                <Typography className='--RFI-workload-instruction-line' gutterBottom>{line1}</Typography>
                <Typography className='--RFI-workload-instruction-line' gutterBottom>{line2}</Typography>
                <Typography className='--RFI-workload-instruction0line' gutterBottom>{line3}</Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

WorkloadInstructions.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.function,
    name: PropTypes.string,
    line1: PropTypes.string,
    line2: PropTypes.string,
    line3: PropTypes.string,
}

export default WorkloadInstructions
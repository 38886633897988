import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { apiCalls } from '../../DataService'
import { ActiveState } from '../../../Utils'
import { Close } from '@mui/icons-material'
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'
import { EditFCConfirmation } from './EditFCConfirmation'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const useStyles = makeStyles({
    form: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        marginTop: '2rem',
    },
    formFields: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
    },
    title: {
        textAlign: 'center',
    }
});

export const EditFiscalCycle = ({fcToEdit, setFCToEdit, isOpen, toggleModal, loadFiscalCyclesFromDB, setSnackbarMessage, setShowSnackbarError, setShowSnackbarSuccess}) => {
    const classes = useStyles()
    const [editFCConfirmationOpen, setEditFCConfirmationOpen] = useState(false)
    const [initStartDate, setInitStartDate] = useState(null)
    const [initEndDate, setInitEndDate] = useState(null)

    const handleEditFiscalCycle = () => {
        if (fcToEdit?.fiscalYear.length > 0) {
            const body = {
                startDate: fcToEdit.startDate,
                endDate: fcToEdit.endDate,
                fiscalYear: Number(fcToEdit?.fiscalYear.slice(2, 4)) + 2000,
                dataUpdateStatus: ActiveState.INACTIVE,
            }
            // Make POST or PUT DB call to add or edit a POC
            apiCalls.put(`FiscalCycles/${fcToEdit?.id}`, body).then(() => {
                setShowSnackbarSuccess(true)
                setSnackbarMessage('Fiscal Cycle Updated Successfully')
                loadFiscalCyclesFromDB()
            }).catch(e => {
                setShowSnackbarError(true)
                setSnackbarMessage('Error Updating Fiscal Cycle')
                console.error(e)
            }).finally(() => {
                toggleModal()
            })
        }
    }

    const handleFieldChange = (e, field) => {
        switch(field) {
            case 'startDate':
                setFCToEdit({
                    ...fcToEdit, 
                    startDate: e.toDate().toISOString(),
                })
                break
            case 'endDate':
                setFCToEdit({
                    ...fcToEdit, 
                    endDate: e.toDate().toISOString(),
                })
                break
            default:
                break
        }
        
    }

    const handleCancel = (e) => {
        e.preventDefault()
        toggleModal()
    }

    useEffect(() => {
        if (fcToEdit?.id) {
            const fcToEditStartDateSplit = fcToEdit?.startDate.split('-')
            const fcToEditEndDateSplit = fcToEdit?.endDate.split('-')
            setInitStartDate(new Date(Date.UTC(fcToEditStartDateSplit[0], Number(fcToEditStartDateSplit[1]) - 1, fcToEditStartDateSplit[2].slice(0, 2), 0, 0, 0)).toISOString())
            setInitEndDate(new Date(Date.UTC(fcToEditEndDateSplit[0], Number(fcToEditEndDateSplit[1]) - 1, fcToEditEndDateSplit[2].slice(0, 2), 23, 59, 59)).toISOString())
        }
    }, [fcToEdit])


    // TODO: still need to clean this up. Just removing reactstrap for now.
    return (
        <div>
            <Dialog
                open={Boolean(isOpen)}
            >
                <DialogTitle className={classes.title}>Edit {fcToEdit?.fiscalYear} Fiscal Cycle</DialogTitle>
                <DialogContent dividers>
                    <form className={classes.form}>
                        <div className={classes.formFields}>
                            <div style={{margin: '0 1rem'}}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        label='Start Date'
                                        value={dayjs(initStartDate)}
                                        onChange={(newValue) => handleFieldChange(newValue, 'startDate')}
                                        renderInput={(params) => <TextField {...params} />}
                                        timezone='UTC'
                                    />
                                </LocalizationProvider>
                            </div>
                            <div style={{margin: '0 1rem'}}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        label='End Date'
                                        value={dayjs(initEndDate)}
                                        onChange={(newValue) => handleFieldChange(newValue, 'endDate')}
                                        renderInput={(params) => <TextField {...params} />}
                                        timezone='UTC'
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                    </form>
                </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancel}>Cancel</Button>
                        <Button disabled={fcToEdit?.fiscalYear <= 0} className='poc-modal-btn' onClick={(e) => {
                            const fiscalYear = Number(fcToEdit?.fiscalYear.slice(2, 4)) + 2000
                            const endDateYear = Number(fcToEdit?.endDate.slice(0, 4))
                            if (fcToEdit?.endDate < fcToEdit?.startDate) {
                                setShowSnackbarError(true)
                                setSnackbarMessage('The Start Date must be before the End Date')
                                handleCancel(e)
                            } else if (fiscalYear !== endDateYear) {
                                setShowSnackbarError(true)
                                setSnackbarMessage('The End date must be within the calendar year of its fiscal year (e.g. the end date of FY23 must be in 2023)')
                                handleCancel(e)
                            } else {
                                e.preventDefault()
                                setEditFCConfirmationOpen(true)
                            }
                        }}>Confirm</Button>
                    </DialogActions>
            </Dialog>
            <EditFCConfirmation open={editFCConfirmationOpen} setOpen={setEditFCConfirmationOpen} fcToEdit={fcToEdit} handleEditFiscalCycle={handleEditFiscalCycle}/>
        </div>
        
    )
}

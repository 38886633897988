import React, { useState, useRef, useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import Papa from "papaparse"
import "../RFI.css"
import { v4 as uuidv4 } from "uuid"
import RFIGridToolbar from "../RFIGridToolbar"
import GFEBS_CEFMS_Table_Utils from "./GFEBS_CEFMS_Table_Utils"
import GlobalContext from "../../reducers/GlobalContext"
import { DataGrid } from "@mui/x-data-grid"
import GFEBS_CEFMS_Instructions_Modal from "./GFEBS_CEFMS_Instructions_Modal"
import GFEBS_CEFMS_CSV_Data_Loader from "./GFEBS-CEFMS_CSV_Data_Loader"
import { apiCalls, QueryParamTypes } from "../../DataService"
import { TableControls } from "../TableControls"
import {
  CSVBulkUploadReqHeaders,
  CSVUploadProgressMessageTypes,
  MinTableHeight,
  SelectedTabToRFIMapping,
  SubmitStatus,
  UploadSubmitEnabledStatuses,
  fetchPrevCsvFileData,
  formatHeader,
  getWarningsErrorsTableRowsFromUploadedTableRows,
  returnErrorMessageFromAPIError,
  updateMUITableWithWarningsErrorsRows,
  populateGlobalContext,
} from "../../../Utils"
import ReviewWarningsModal from "../ReviewWarningsModal"
import UploadProgressModal from "../UploadProgressModal"
import { SubmitRFIWarningModal } from "../SubmitRFIWarningModal"
import SnackbarMessages from "../../SnackbarMessages"
import { RequestResubmissionWarningModal } from "../RequestResubmissionWarningModal"
import { logosArr } from "../../Organizations/OrganizationPOCs"
import { fetchRFIDashboardObjectsFromDB, getRfiRecordCountMapping } from "../../DashboardMenu"
import AppContext from "../../../AppContext"
import Base from "../../elements/layout/Base"
import { TabContext, TabList } from "@mui/lab"
import { Box, Button, Stack, Tab, Typography } from "@mui/material"
import RfiStatusChip from "../../custom/RfiStatusChip"
import { Help } from "@mui/icons-material"

const GFEBS_CEFMS = () => {
  const matchedHeaderCount = useRef(0)

  const madeFirstSubmissionOfSession = useRef(false)
  const [selectedTab, setSelectedTab] = useState(GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT1)
  const [selectedTableCols, setSelectedTableCols] = useState(GFEBS_CEFMS_Table_Utils.GFEBS_R1_COLS)
  const [selectedTableRows, setSelectedTableRows] = useState([])
  const [uploadedTableRows, setUploadedTableRows] = useState([])
  const [warningsErrorsTableRows, setWarningsErrorsTableRows] = useState([])
  const [warningsErrorsTableRowsWarningsErrorsOnly, setWarningsErrorsTableRowsWarningsErrorsOnly] = useState([])
  const [showWarningUploadModal, setShowWarningUploadModal] = useState(false)
  const [selectedRFI, setSelectedRFI] = useState({})
  const renderingDBRows = useRef(true)
  const [MUITableHeight, setMUITableHeight] = useState(800)
  const location = useLocation()
  const [showRequestResubmissionWarningModal, setShowRequestResubmissionWarningModal] = useState(false)

  const [canUploadCSVData, setCanUploadCSVData] = useState({})
  const [canSubmitRFIData, setCanSubmitRFIData] = useState({})
  const [canExportRFIData, setCanExportRFIData] = useState({})
  const [submitStatus, setSubmitStatus] = useState({})

  const pageSize = useRef(100)
  const pageNumber = useRef(0)
  const [fileSize, setFileSize] = useState(0)
  const [uploadCompleted, setUploadCompleted] = useState(false)
  const onlyWarningsInUpload = useRef(false)
  const [MUISortModel, setMUISortModel] = useState([])
  const [MUIFilter, setMUIFilter] = useState({})
  const [showingWarningErrorUpload, setShowingWarningErrorUpload] = useState(false)
  const [toggleWarningsErrors, setToggleWarningsErrors] = useState(false)

  const numTotalTableRows = useRef({
    [GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT1]: 0,
    [GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT2]: 0,
    [GFEBS_CEFMS_Table_Utils.Tabs.CEFMS_DATA]: 0,
  })

  const [showSnackbarSuccess, setShowSnackbarSuccess] = useState(false)
  const [showSnackbarError, setShowSnackbarError] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [showSubmitRFIWarningModal, setShowSubmitRFIWarningModal] = useState(false)
  const [showUploadProgressModal, setShowUploadProgressModal] = useState(false)
  const [loadingTitle, setLoadingTitle] = useState("")

  const [disclaimerRFIModalOpen, setDisclaimerRFIModalOpen] = useState(false)
  const [showBusyDialog, setShowBusyDialog] = useState(false)

  const fileSelector = useRef(null)

  const globalContext = useContext(GlobalContext)
  const {
    allOrgs,
    selectedOrg,
    setSelectedOrgContext,
    activeFiscalCycle,
    userObj,
    rfiOrganizationsMapping,
    rfiRecordCountMapping,
    setRfiRecordCountMappingContext,
    setRfiDashboardOrgRfiMappingContext,
    setSelectedTabRfiMappingKeyContext,
  } = globalContext

  const context = useContext(AppContext)
  let { selectedFY } = context

  const getSelectedRFI = async () => {
    let queryParams = {}
    if (selectedTab === GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT1) {
      queryParams = { [QueryParamTypes.FILTER]: `reportType eq '${GFEBS_CEFMS_Table_Utils.ReportType.GFEBS_R1}'` }
    } else if (selectedTab === GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT2) {
      queryParams = { [QueryParamTypes.FILTER]: `reportType eq '${GFEBS_CEFMS_Table_Utils.ReportType.GFEBS_R2}'` }
    }
    const selectedTabRFIs = await apiCalls.getAll(GFEBS_CEFMS_Table_Utils.UrlApiTypes[selectedTab].rfi, queryParams)
    const RFI = selectedTabRFIs.find((RFI) => RFI?.organizationId === selectedOrg?.id && RFI?.fiscalCycleId === selectedFY?.id)
    if (!RFI) {
      setSelectedRFI({})
      return
    }
    setSelectedRFI(RFI)
    pageNumber.current = 0
  }

  const getTableSubmitStatus = async () => {
    setSubmitStatus({ ...submitStatus, [selectedTab]: selectedRFI?.status ? selectedRFI.status : SubmitStatus.NOT_SUBMITTED })
    const selectedFYActive = selectedFY?.id === activeFiscalCycle?.id
    setCanSubmitRFIData({
      ...canSubmitRFIData,
      [selectedTab]:
        selectedFYActive &&
        (renderingDBRows.current || onlyWarningsInUpload.current) &&
        (!selectedRFI?.status || UploadSubmitEnabledStatuses.includes(selectedRFI?.status)) &&
        selectedTableRows.length > 0
          ? true
          : false,
    })
    setCanUploadCSVData({
      ...canUploadCSVData,
      [selectedTab]: selectedFYActive && (!selectedRFI?.status || UploadSubmitEnabledStatuses.includes(selectedRFI?.status)) ? true : false,
    })
    setCanExportRFIData({ ...canExportRFIData, [selectedTab]: selectedTableRows.length > 0 })
  }

  const headerRowNumber = useRef(0)
  const handleReadGFEBSCSVFile = (file) => {
    setMUISortModel([])
    setMUIFilter({})
    setShowingWarningErrorUpload(false)
    setToggleWarningsErrors(false)
    setFileSize(file?.size)
    onlyWarningsInUpload.current = false
    headerRowNumber.current = 0
    madeFirstSubmissionOfSession.current = true
    let tmpParsedRows = []
    let parsedHeaders = []
    let parsedFormattedHeaders = []
    let headerRowFound = false
    // Ignore all other file types that are not 'text/csv'
    if (file.type === "text/csv") {
      setLoadingTitle(CSVUploadProgressMessageTypes.PREPARE)
      setShowUploadProgressModal(true)
      setUploadCompleted(false)
      Papa.parse(file, {
        header: false,
        worker: true,
        delimiter: "",
        skipEmptyLines: true,
        step: (row) => {
          const expectedHeaders =
            selectedTab === GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT1
              ? GFEBS_CEFMS_Table_Utils.ExpectedGFEBSR1Headers
              : GFEBS_CEFMS_Table_Utils.ExpectedGFEBSR2Headers
          if (!headerRowFound) {
            headerRowNumber.current += 1
            // Find the header row
            parsedHeaders = row.data.map((header) => formatHeader(header)?.replace(/\s/g, ""))
            // Convert empty strings to their corresponding header if in the right index
            if (selectedTab === GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT1) {
              parsedFormattedHeaders = parsedHeaders.map((potentialHeader, idx) => {
                if (potentialHeader === "") {
                  if (idx === 6 || idx === 10 || idx === 16 || idx === 18) {
                    return expectedHeaders[idx]
                  }
                }
                return potentialHeader
              })
            } else {
              parsedFormattedHeaders = parsedHeaders.map((potentialHeader, idx) => {
                if (potentialHeader === "") {
                  if (idx === 3 || idx === 14) {
                    return expectedHeaders[idx]
                  }
                }
                return potentialHeader
              })
            }
            // Filter out all other empty strings and '$'
            parsedFormattedHeaders = parsedFormattedHeaders.filter((header) => header.length > 0 && header !== "$")
            // Check for header row
            parsedFormattedHeaders.forEach((potentialHeader) => {
              for (let i = 0; i < expectedHeaders.length; i++) {
                if (expectedHeaders[i].toLowerCase() === potentialHeader.toLowerCase()) {
                  matchedHeaderCount.current += 1
                  if (matchedHeaderCount.current >= Math.floor(expectedHeaders.length * 0.75)) {
                    headerRowFound = true
                  }
                  break
                }
              }
            })
            matchedHeaderCount.current = 0
          } else {
            // Data Row
            let parsedRow = {}
            for (let i = 0; i < parsedFormattedHeaders.length; i++) {
              const fieldValue = row?.data[i] ? row.data[i].trim() : ""
              parsedRow[parsedFormattedHeaders[i]] = fieldValue === "#" ? null : fieldValue
            }
            tmpParsedRows.push(parsedRow)
          }
        },
        complete: () => {
          setTimeout(() => {
            const trimmedParsedRows = tmpParsedRows.filter((row) => Object.values(row).filter((value) => value !== "").length > 0)
            handleLoadCSVDataIntoTable(trimmedParsedRows)
            headerRowFound = false
          }, 1000)
        },
      })
    } else {
      setShowSnackbarError(true)
      setSnackbarMessage("Cannot upload data: The file type is not supported. Please upload a CSV file.")
    }
  }

  const handleReadCEFMSCSVFile = (file) => {
    setMUISortModel([])
    setMUIFilter({})
    setShowingWarningErrorUpload(false)
    setToggleWarningsErrors(false)
    setFileSize(file?.size)
    madeFirstSubmissionOfSession.current = true
    let tmpParsedRows = []
    let parsedHeaders = []
    let headerRowFound = false
    // Ignore all other file types that are not 'text/csv'
    if (file.type === "text/csv") {
      setLoadingTitle("Preparing CSV File...")
      setShowUploadProgressModal(true)
      Papa.parse(file, {
        header: false,
        worker: true,
        delimiter: "",
        skipEmptyLines: true,
        step: (row) => {
          if (!headerRowFound) {
            // Find the header row
            parsedHeaders = row.data.map((header) => formatHeader(header)?.replace(/\s/g, ""))
            parsedHeaders.map((potentialHeader) => {
              for (let i = 0; i < GFEBS_CEFMS_Table_Utils.ExpectedCEFMSHeaders.length; i++) {
                if (GFEBS_CEFMS_Table_Utils.ExpectedCEFMSHeaders[i].toLowerCase() === potentialHeader.toLowerCase()) {
                  matchedHeaderCount.current += 1
                  if (matchedHeaderCount.current >= Math.floor(GFEBS_CEFMS_Table_Utils.ExpectedCEFMSHeaders.length * 0.75)) {
                    headerRowFound = true
                  }
                  break
                }
              }
            })
            matchedHeaderCount.current = 0
          } else {
            // Data Row
            let parsedRow = {}
            for (let i = 0; i < GFEBS_CEFMS_Table_Utils.ExpectedCEFMSHeaders.length; i++) {
              const fieldValue = row?.data[i] ? row.data[i].trim() : ""
              parsedRow[GFEBS_CEFMS_Table_Utils.ExpectedCEFMSHeaders[i]] = fieldValue === "#" ? null : fieldValue
            }
            if (!parsedRow[GFEBS_CEFMS_Table_Utils.CEFMSDataFields.EMPLOYEE_IDENTIFIER].includes("Total")) {
              tmpParsedRows.push(parsedRow)
            }
          }
        },
        complete: () => {
          setTimeout(() => {
            const trimmedParsedRows = tmpParsedRows.filter((row) => Object.values(row).filter((value) => value !== "").length > 0)
            handleLoadCSVDataIntoTable(trimmedParsedRows)
            headerRowFound = false
          }, 1000)
        },
      })
    } else {
      setShowSnackbarError(true)
      setSnackbarMessage("Cannot upload data: The file type is not supported. Please upload a CSV file.")
    }
  }

  const handleLoadCSVDataIntoTable = (data) => {
    pageNumber.current = 0
    renderingDBRows.current = true
    setUploadedTableRows(data)
    setCanSubmitRFIData({ ...canSubmitRFIData, [selectedTab]: renderingDBRows.current })
  }

  // Overwrite any existing csv entries in the DB with the new data
  const submitRFIData = () => {
    setShowBusyDialog(true)
    apiCalls
      .put(`${GFEBS_CEFMS_Table_Utils.UrlApiTypes[selectedTab].rfi}/${selectedRFI.id}`, { ...selectedRFI, status: SubmitStatus.SUBMITTED })
      .then(() => {
        getSelectedRFI()
        setShowSnackbarSuccess(true)
        setSnackbarMessage("RFI Submitted Successfully!")
        fetchRFIDashboardObjectsFromDB(rfiOrganizationsMapping, allOrgs, selectedFY, rfiRecordCountMapping).then((data) => {
          setRfiDashboardOrgRfiMappingContext(data)
        })
      })
      .catch(() => {
        setShowSnackbarError(true)
        setSnackbarMessage("Failed to submit RFI data")
      })
      .finally(() => setShowBusyDialog(false))
  }

  const getRFIsFromDB = async () => {
    let queryParams = {}
    if (selectedTab === GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT1) {
      queryParams = { [QueryParamTypes.FILTER]: `reportType eq '${GFEBS_CEFMS_Table_Utils.ReportType.GFEBS_R1}'` }
    }
    if (selectedTab === GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT2) {
      queryParams = { [QueryParamTypes.FILTER]: `reportType eq '${GFEBS_CEFMS_Table_Utils.ReportType.GFEBS_R2}'` }
    }
    return await apiCalls.getAll(`${GFEBS_CEFMS_Table_Utils.UrlApiTypes[selectedTab].rfi}`, queryParams).catch((e) => {
      const errMessage = returnErrorMessageFromAPIError(e)
      setShowSnackbarError(true)
      if (errMessage) {
        setSnackbarMessage(errMessage)
      } else {
        setSnackbarMessage("Unable to load data, please refresh the page and try again")
      }
    })
  }

  const persistDataEntryRFI = async () => {
    if (selectedTab === GFEBS_CEFMS_Table_Utils.Tabs.CEFMS_DATA) {
      await apiCalls.post(`${GFEBS_CEFMS_Table_Utils.UrlApiTypes[selectedTab].rfi}`, {
        organizationId: selectedOrg.id,
        fiscalCycleId: selectedFY.id,
        poc: userObj.name,
      })
    } else {
      await apiCalls.post(`${GFEBS_CEFMS_Table_Utils.UrlApiTypes[selectedTab].rfi}`, {
        organizationId: selectedOrg.id,
        fiscalCycleId: selectedFY.id,
        reportType:
          selectedTab === GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT1
            ? GFEBS_CEFMS_Table_Utils.ReportType.GFEBS_R1
            : GFEBS_CEFMS_Table_Utils.ReportType.GFEBS_R2,
        poc: userObj.name,
      })
    }
  }

  const restorePrevDBData = async (tmpSelectedRFI) => {
    setShowUploadProgressModal(true)
  }

  const bulkUploadCSVDataToDB = () => {
    getRFIsFromDB().then((data) => {
      console.log("hey")
      const tmpSelectedRFI = data.find((RFI) => RFI.organizationId === selectedOrg.id && RFI.fiscalCycleId === selectedFY.id)
      let formData = new FormData()
      formData.append("FormFile", new Blob([fileSelector.current.files[0]]))
      // If the file has 2 header rows pass in headerRowNumber.current + 1 otherwise pass in headerRowNumber.current
      const headerRowNumberToPass = selectedTab === GFEBS_CEFMS_Table_Utils.Tabs.CEFMS_DATA ? headerRowNumber.current : headerRowNumber.current + 1
      let warningsList = null
      let errorsList = null
      console.log(headerRowNumberToPass)
      apiCalls
        .post(
          `${GFEBS_CEFMS_Table_Utils.UrlApiTypes[selectedTab].record}/${tmpSelectedRFI.id}/BulkUpload?replaceData=false`,
          formData,
          CSVBulkUploadReqHeaders
        )
        .then((res) => {
          warningsList = res.data.warningsList
          if (warningsList && warningsList.length > 0) {
            renderingDBRows.current = false
            onlyWarningsInUpload.current = true
            setShowingWarningErrorUpload(true)
            setShowWarningUploadModal(true)
          } else renderingDBRows.current = true
          setShowSnackbarSuccess(true)
          setSnackbarMessage(
            `Successfully uploaded ${selectedTab} data, please wait a few minutes before exporting the file. The system may need time to prepare the data for download.`
          )
          getSelectedRFI()
          if (selectedFY?.id) {
            getRfiRecordCountMapping(selectedFY).then((mapping) => {
              setRfiRecordCountMappingContext(mapping)
              fetchRFIDashboardObjectsFromDB(rfiOrganizationsMapping, allOrgs, selectedFY, mapping).then((data) => {
                setRfiDashboardOrgRfiMappingContext(data)
              })
            })
          }
          setUploadCompleted(true)
          setShowUploadProgressModal(false)
        })
        .catch((e) => {
          const errObj = e.response.data
          setShowSnackbarError(true)
          renderingDBRows.current = false
          restorePrevDBData(tmpSelectedRFI)
          const errMessage = returnErrorMessageFromAPIError(e)
          if (errMessage) {
            setSnackbarMessage(errMessage)
            return
          }
          if (errObj.topLevelError) {
            setSnackbarMessage(errObj.topLevelError)
            return
          }
          if (errObj.error) {
            setSnackbarMessage(errObj.error)
            return
          }
          if (errObj?.fileError) {
            setSnackbarMessage(errObj.fileError)
            return
          }
          if (errObj && errObj?.fileErrors) {
            // If there are file errors, display them in a snackbar message
            let msg = "Some or all of the headers are incorrect or missing in the uploaded file. Please check the file and try again."
            console.log(errObj)
            setSnackbarMessage(msg)
          } else {
            // If there are row errors, display them in the table
            if (errObj && errObj.length > 0) {
              errorsList = errObj.filter((err) => err.code === "Error")
              warningsList = errObj.filter((err) => err.code === "Warning")
              setShowingWarningErrorUpload(true)
              setSnackbarMessage(`Errors found in ${fileSelector.current.files[0].name}`)
            } else {
              setSnackbarMessage("unknown error: please check your internet connection and make sure your file is formatted correctly")
            }
          }
        })
        .finally(() => {
          if (errorsList || warningsList) {
            const { tmpWarningsErrorsTableRows, tmpWarningsErrorsTableRowsWarningsErrorsOnly } = getWarningsErrorsTableRowsFromUploadedTableRows(
              uploadedTableRows,
              errorsList,
              warningsList,
              headerRowNumberToPass
            )
            setWarningsErrorsTableRows(tmpWarningsErrorsTableRows)
            setWarningsErrorsTableRowsWarningsErrorsOnly(tmpWarningsErrorsTableRowsWarningsErrorsOnly)
          }
          setFileSize(0)
          fileSelector.current.value = null
        })
    })
  }

  // Overwrite any existing csv entries in the DB with the new data
  const persistDBData = () => {
    //Make sure an organization is selected
    if (!selectedOrg.id) {
      setShowSnackbarError(true)
      setSnackbarMessage("Organization not selected, please select an organization from the Organization POCs page")
      return
    }
    getRFIsFromDB().then((allRFIs) => {
      if (allRFIs.length <= 0) {
        persistDataEntryRFI().then(() => {
          setLoadingTitle(CSVUploadProgressMessageTypes.POST)
          bulkUploadCSVDataToDB()
        })
      } else {
        const orgFCIdPairs = allRFIs.map((data) => {
          return { id: data.id, orgId: data.organizationId, fcId: data.fiscalCycleId }
        })
        let orgFCIdPairExists = false
        let rfiId = null
        for (let i = 0; i < orgFCIdPairs.length; i++) {
          if (orgFCIdPairs[i].orgId === selectedOrg.id && orgFCIdPairs[i].fcId === selectedFY.id) {
            orgFCIdPairExists = true
            rfiId = orgFCIdPairs[i].id
            break
          }
        }
        if (!orgFCIdPairExists) {
          persistDataEntryRFI().then(() => {
            setLoadingTitle(CSVUploadProgressMessageTypes.POST)
            bulkUploadCSVDataToDB()
          })
        } else {
          setLoadingTitle(CSVUploadProgressMessageTypes.DELETE)
          apiCalls
            .delete(`${GFEBS_CEFMS_Table_Utils.UrlApiTypes[selectedTab].record}/${rfiId}/BulkDelete`)
            .then(() => {
              setLoadingTitle(CSVUploadProgressMessageTypes.POST)
              bulkUploadCSVDataToDB()
            })
            .catch(() => {
              setShowSnackbarError(true)
              setSnackbarMessage(
                "Server timeout when attempting to delete existing data. No data changes have been made. Check your connection and try again."
              )
              setShowUploadProgressModal(false)
            })
        }
      }
    })
  }

  let origionalWarningsErrorsTableRows = useRef([])
  const updateTableForTab = async (apiType, apiRFIType, rfiIdFieldName, skip, gfebsReportType) => {
    if (renderingDBRows.current) {
      const queryParams = {
        [QueryParamTypes.TOP]: pageSize.current,
        [QueryParamTypes.SKIP]: skip,
        [QueryParamTypes.FILTER]: `${rfiIdFieldName} eq ${selectedRFI.id}`,
      }
      if (MUISortModel[0]?.field) {
        queryParams[QueryParamTypes.ORDER_BY] = `${MUISortModel[0].field} ${MUISortModel[0].sort.toUpperCase()}`
      }
      let filterValue = GFEBS_CEFMS_Table_Utils.NumericFields.GFEBS_DATA.includes(MUIFilter.tableField) ? MUIFilter.value : `'${MUIFilter.value}'`
      if (selectedTab === GFEBS_CEFMS_Table_Utils.Tabs.CEFMS_DATA) {
        filterValue = GFEBS_CEFMS_Table_Utils.NumericFields.CFEMS_DATA.includes(MUIFilter.tableField) ? MUIFilter.value : `'${MUIFilter.value}'`
      }
      if (MUIFilter?.value) {
        switch (MUIFilter.operator) {
          case "contains":
            queryParams[QueryParamTypes.FILTER] += ` and contains(${MUIFilter.dataField.replace("/", "")}, ${filterValue})`
            break
          case "equals":
            queryParams[QueryParamTypes.FILTER] += ` and ${MUIFilter.dataField.replace("/", "")} eq ${filterValue}`
            break
          default:
            break
        }
      }
      let allRFIs = []
      if (gfebsReportType) {
        allRFIs = await apiCalls.getAll(apiRFIType, { [QueryParamTypes.FILTER]: `reportType eq '${gfebsReportType}'` })
      }
      if (selectedRFI?.id) {
        apiCalls
          .getAllCount(apiType, { [QueryParamTypes.FILTER]: queryParams[QueryParamTypes.FILTER] })
          .then((count) => {
            apiCalls
              .getAll(apiType, queryParams)
              .then((pageRows) => {
                let pageTableRows = []
                if (gfebsReportType) {
                  pageRows = pageRows.filter((row) => allRFIs.map((RFI) => RFI.id).includes(row[rfiIdFieldName]))
                }
                numTotalTableRows.current = { ...numTotalTableRows.current, [selectedTab]: count }
                pageTableRows = GFEBS_CEFMS_CSV_Data_Loader.convertDataToTableRows(selectedTab, pageRows)
                setShowBusyDialog(false)
                setSelectedTableRows(pageTableRows)
                if (MUIFilter?.value && MUIFilter.value.length > 0) {
                  setShowSnackbarSuccess(true)
                  setSnackbarMessage(`Filtered ${selectedTab} data`)
                }
              })
              .finally(() => setShowBusyDialog(false))
          })
          .catch((e) => {
            console.error(e)
            setShowSnackbarError(true)
            if (MUIFilter?.value && MUIFilter.value.length > 0) {
              setSnackbarMessage(
                `Failed to filter field: the entered value ${MUIFilter?.value ? `'${MUIFilter.value}'` : ""} is not allowed or the ${
                  MUIFilter?.operator ? `'${MUIFilter.operator}'` : ""
                } operator is not allowed for the ${MUIFilter?.tableField ? `'${MUIFilter.tableField}'` : ""} field.`
              )
            } else {
              setSnackbarMessage(`Failed to retrieve ${selectedTab} data`)
            }
          })
      } else {
        setSelectedTableRows([])
        pageNumber.current = 0
        numTotalTableRows.current = { ...numTotalTableRows.current, [selectedTab]: 0 }
        setShowBusyDialog(false)
      }
    } else {
      let selectedTabNumericFields = []
      if (selectedTab === GFEBS_CEFMS_Table_Utils.Tabs.CEFMS_DATA) {
        selectedTabNumericFields = GFEBS_CEFMS_Table_Utils.NumericFields.CFEMS_DATA
      } else {
        selectedTabNumericFields = GFEBS_CEFMS_Table_Utils.NumericFields.GFEBS_DATA
      }
      const warningsErrorsTableRowsForDisplay = toggleWarningsErrors ? warningsErrorsTableRowsWarningsErrorsOnly : warningsErrorsTableRows
      updateMUITableWithWarningsErrorsRows(
        MUIFilter,
        MUISortModel,
        warningsErrorsTableRowsForDisplay,
        origionalWarningsErrorsTableRows,
        numTotalTableRows,
        setSelectedTableRows,
        selectedTab,
        skip,
        pageSize,
        selectedTabNumericFields,
        setShowSnackbarError,
        setSnackbarMessage
      )
      setShowBusyDialog(false)
    }
  }

  const updateTableRows = () => {
    if (!selectedOrg?.id || !selectedTab || pageNumber?.current == null || pageNumber.current < 0 || pageSize?.current == null) {
      setShowBusyDialog(false)
      return
    }
    const skip = pageNumber.current * pageSize.current
    switch (selectedTab) {
      case GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT1:
        setSelectedTableCols(GFEBS_CEFMS_Table_Utils.GFEBS_R1_COLS)
        updateTableForTab(
          GFEBS_CEFMS_Table_Utils.UrlApiTypes[selectedTab].record,
          GFEBS_CEFMS_Table_Utils.UrlApiTypes[selectedTab].rfi,
          GFEBS_CEFMS_Table_Utils.UrlApiTypes[selectedTab].rfiId,
          skip,
          GFEBS_CEFMS_Table_Utils.ReportType.GFEBS_R1
        )
        break
      case GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT2:
        setSelectedTableCols(GFEBS_CEFMS_Table_Utils.GFEBS_R2_COLS)
        updateTableForTab(
          GFEBS_CEFMS_Table_Utils.UrlApiTypes[selectedTab].record,
          GFEBS_CEFMS_Table_Utils.UrlApiTypes[selectedTab].rfi,
          GFEBS_CEFMS_Table_Utils.UrlApiTypes[selectedTab].rfiId,
          skip,
          GFEBS_CEFMS_Table_Utils.ReportType.GFEBS_R2
        )
        break
      case GFEBS_CEFMS_Table_Utils.Tabs.CEFMS_DATA:
        setSelectedTableCols(GFEBS_CEFMS_Table_Utils.CEFMS_COLS)
        updateTableForTab(
          GFEBS_CEFMS_Table_Utils.UrlApiTypes[selectedTab].record,
          GFEBS_CEFMS_Table_Utils.UrlApiTypes[selectedTab].rfi,
          GFEBS_CEFMS_Table_Utils.UrlApiTypes[selectedTab].rfiId,
          skip,
          null
        )
        break
      default:
        break
    }
  }

  const handleOnPageSizeChange = (pgSize) => {
    pageSize.current = pgSize
    if (pageNumber.current * pgSize + 1 > numTotalTableRows.current[selectedTab]) {
      pageNumber.current = 0
    }
    handleOnPageChange(pageNumber.current)
  }

  const handleOnPageChange = (pgNumber) => {
    if (pgNumber < 0) pgNumber = 0
    pageNumber.current = pgNumber
    updateTableRows()
  }

  const adjustTableHeight = () => {
    setMUITableHeight(window.innerHeight - 400)
  }

  const requestResubmission = () => {
    setShowBusyDialog(true)
    apiCalls
      .put(`${GFEBS_CEFMS_Table_Utils.UrlApiTypes[selectedTab].rfi}/${selectedRFI.id}`, { ...selectedRFI, status: SubmitStatus.REQUEST_RESUBMISSION })
      .then(() => {
        getSelectedRFI()
        setShowSnackbarSuccess(true)
        setSnackbarMessage("Resubmission requested for RFI!")
        fetchRFIDashboardObjectsFromDB(rfiOrganizationsMapping, allOrgs, selectedFY, rfiRecordCountMapping).then((data) => {
          setRfiDashboardOrgRfiMappingContext(data)
        })
      })
      .catch(() => {
        setShowSnackbarError(true)
        setSnackbarMessage("Failed to request resubmission for RFI")
      })
      .finally(() => setShowBusyDialog(false))
  }

  const handleOnSortModelChange = (MUISortModel) => {
    if (!MUISortModel[0]) {
      setMUISortModel([{}])
    } else {
      if (renderingDBRows.current) {
        switch (MUISortModel[0].field) {
          case GFEBS_CEFMS_Table_Utils.GFEBSDataFields.GRC_CODE:
            setMUISortModel([{ ...MUISortModel[0], field: "GrcTypeHourCode" }])
            return
          case GFEBS_CEFMS_Table_Utils.GFEBSDataFields.GRC_CODE_DESCRIPTION:
            setMUISortModel([{ ...MUISortModel[0], field: "GrcTypeHourCodeDescription" }])
            return
          case GFEBS_CEFMS_Table_Utils.GFEBSDataFields.CHARGED_HOURS:
            setMUISortModel([{ ...MUISortModel[0], field: "ChargedHours" }])
            return
          default:
            break
        }
      }
      setMUISortModel(MUISortModel)
    }
  }

  const handleFilterRFIData = (tableField, dataField, operator, value) => {
    setMUIFilter({ tableField, dataField, operator, value })
  }

  const handleExportRFIData = async () => {
    //setLoadingTitle(CSVUploadProgressMessageTypes.EXPORT)
  }

  const setTargetOrganization = (targetOrg) => {
    apiCalls.getAll(`Organizations`, {}).then((orgs) => {
      const targetOrgAPI = orgs.find((org) => org.name === targetOrg)
      apiCalls.getById(`Organizations`, targetOrgAPI.id).then((org) => {
        setSelectedOrgContext({ ...org, logo: logosArr.find((logo) => logo.name === targetOrgAPI.name) })
      })
    })
  }

  useEffect(() => {
    populateGlobalContext(globalContext)
  }, [])

  useEffect(() => {
    if (MUIFilter?.value != null) {
      pageNumber.current = 0
      updateTableRows()
    }
  }, [MUIFilter])

  useEffect(() => {
    if (MUISortModel[0]) {
      pageNumber.current = 0
      setShowBusyDialog(true)
      updateTableRows()
    }
  }, [MUISortModel])

  useEffect(() => {
    if (activeFiscalCycle?.id && selectedFY?.id) {
      getTableSubmitStatus()
      const selectedFYActive = activeFiscalCycle?.id === selectedFY?.id
      if (!selectedFYActive) {
        setShowSnackbarError(true)
        setSnackbarMessage("RFI submission is disabled because the Data Update Fiscal Year is inactive.")
      }
    }
  }, [activeFiscalCycle, selectedFY])

  useEffect(() => {
    setWarningsErrorsTableRows([])
    if (selectedOrg?.id) {
      if (uploadedTableRows?.length > 0) {
        persistDBData()
      } else {
        if (madeFirstSubmissionOfSession.current) {
          setShowSnackbarError(true)
          setSnackbarMessage("Some or all of the headers are incorrect or missing in the uploaded file. Please check the file and try again.")
        }
        setShowUploadProgressModal(false)
      }
    }
  }, [uploadedTableRows])

  useEffect(() => {
    const warningErrorsTableRowsForDisplay = toggleWarningsErrors ? warningsErrorsTableRowsWarningsErrorsOnly : warningsErrorsTableRows
    pageNumber.current = 0
    const skip = 0
    let selectedTabNumericFields = []
    if (selectedTab === GFEBS_CEFMS_Table_Utils.Tabs.CEFMS_DATA) {
      selectedTabNumericFields = GFEBS_CEFMS_Table_Utils.NumericFields.CFEMS_DATA
    } else {
      selectedTabNumericFields = GFEBS_CEFMS_Table_Utils.NumericFields.GFEBS_DATA
    }
    updateMUITableWithWarningsErrorsRows(
      MUIFilter,
      MUISortModel,
      warningErrorsTableRowsForDisplay,
      origionalWarningsErrorsTableRows,
      numTotalTableRows,
      setSelectedTableRows,
      selectedTab,
      skip,
      pageSize,
      selectedTabNumericFields,
      setShowSnackbarError,
      setSnackbarMessage
    )
  }, [showingWarningErrorUpload, toggleWarningsErrors])

  useEffect(() => {
    if (warningsErrorsTableRows?.length > 0) {
      updateTableRows()
    }
  }, [warningsErrorsTableRows])

  useEffect(() => {
    getTableSubmitStatus()
  }, [selectedTableRows])

  useEffect(() => {
    renderingDBRows.current = true
    onlyWarningsInUpload.current = false
    setShowingWarningErrorUpload(false)
    if (selectedOrg?.id) {
      getSelectedRFI()
    }
    setMUISortModel([])
  }, [selectedOrg, selectedTab, selectedFY])

  useEffect(() => {
    setSelectedTabRfiMappingKeyContext(SelectedTabToRFIMapping[selectedTab])
    if (selectedTab === GFEBS_CEFMS_Table_Utils.Tabs.CEFMS_DATA) {
      setTargetOrganization("USACE")
    } else {
      if (selectedOrg?.name === "USACE") {
        setTargetOrganization("ACC")
      }
    }
  }, [selectedTab])

  useEffect(() => {
    setShowBusyDialog(true)
    if (MUIFilter?.value && MUIFilter.value.length > 0) {
      setMUIFilter({
        tableField: "",
        dataField: "",
        operator: "equals",
        value: "",
      })
    } else {
      updateTableRows()
    }
    getTableSubmitStatus()
  }, [selectedRFI])

  useEffect(() => {
    adjustTableHeight()
    if (location.state?.selectedTab) {
      setSelectedTab(location.state.selectedTab)
    }
    if (location.state?.organization) {
      setSelectedOrgContext(location.state.organization)
    }
  }, [location])

  useEffect(() => {
    window.addEventListener("resize", adjustTableHeight)
    if (selectedOrg?.id) {
      getSelectedRFI()
    }
    getTableSubmitStatus()
  }, [])

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  return (
    <Box
      container
      margin={2}
      spacing={2}
    >
      <Typography
        align="left"
        variant="h4"
      >
        GFEBS/CEFMS RFI CSV Upload
      </Typography>
      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            <Tab
              label={GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT1}
              value={GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT1}
            />
            <Tab
              label={GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT2}
              value={GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT2}
            />
            <Tab
              label={GFEBS_CEFMS_Table_Utils.Tabs.CEFMS_DATA}
              value={GFEBS_CEFMS_Table_Utils.Tabs.CEFMS_DATA}
            />
          </TabList>
        </Box>
        {/* TODO: Move the tables to their individual tabs 
        
        <TabPanel value={GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT1}>
          {GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT1}
        </TabPanel>
        <TabPanel value={GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT2}>
          {GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT2}
        </TabPanel>
        <TabPanel value={GFEBS_CEFMS_Table_Utils.Tabs.CEFMS_DATA}>
          {GFEBS_CEFMS_Table_Utils.Tabs.CEFMS_DATA}
        </TabPanel> 
        
        */}
      </TabContext>

      <div className="--RFI-table-actions">
        {/************************************************ [RFI Instructions Modal] ************************************************/}
        <GFEBS_CEFMS_Instructions_Modal
          selectedTab={selectedTab}
          disclaimerRFIModalOpen={disclaimerRFIModalOpen}
          setDisclaimerRFIModalOpen={setDisclaimerRFIModalOpen}
        />
        {/************************************************ [RFI Instructions Modal] ************************************************/}
        {/* <GFEBS_CEFMS_Instructions_Modal selectedTab={selectedTab} disclaimerRFIModalOpen={disclaimerRFIModalOpen} setDisclaimerRFIModalOpen={setDisclaimerRFIModalOpen}/> */}
        <Button
          variant="outlined"
          color="info"
          startIcon={<Help />}
          onClick={() => setDisclaimerRFIModalOpen(true)}
        >
          RFI Instructions
        </Button>
        {/************************************** Import CSV file here ********************************************************/}
        <Stack
          direction="row"
          spacing={1}
        >
          <Button
            variant="outlined"
            onClick={() => setShowRequestResubmissionWarningModal(true)}
            hidden={submitStatus[selectedTab] !== SubmitStatus.SUBMITTED}
          >
            REQUEST RESUBMISSION
          </Button>
          <Button
            variant="outlined"
            disabled={!canUploadCSVData[selectedTab]}
            onClick={() => fileSelector.current.click()}
          >
            UPLOAD CSV FILE
            {selectedTab === GFEBS_CEFMS_Table_Utils.Tabs.CEFMS_DATA ? (
              <input
                type="file"
                ref={fileSelector}
                onChangeCapture={(e) => handleReadCEFMSCSVFile(e.target.files[0])}
                hidden
              />
            ) : (
              <input
                type="file"
                ref={fileSelector}
                onChangeCapture={(e) => handleReadGFEBSCSVFile(e.target.files[0])}
                hidden
              />
            )}
          </Button>
          <Button
            variant="contained"
            onClick={() => setShowSubmitRFIWarningModal(true)}
            disabled={!canSubmitRFIData[selectedTab]}
          >
            SUBMIT RFI DATA
          </Button>
          <RfiStatusChip status={submitStatus[selectedTab] ? submitStatus[selectedTab] : SubmitStatus.NOT_SUBMITTED} />
        </Stack>
      </div>

      <div className="--RFI-table-container">
        {/* DataGrid */}
        <div style={{ minHeight: "500px", height: "calc(100vh - 350px)", width: "100%", display: "flex", flexDirection: "column" }}>
          {/* 
          <RFIGridToolbar
            selectedTab={selectedTab}
            selectedFiscalCycle={selectedFY}
            selectedOrgName={selectedOrg.name}
            headers={GFEBS_CEFMS_Table_Utils.UrlApiTypes[selectedTab].headers.filter(
              (h) => !GFEBS_CEFMS_Table_Utils.DateFields.GFEBS_DATA.includes(h)
            )}
            dataHeaders={null}
            handleExportRFIData={handleExportRFIData}
            handleFilterRFIData={handleFilterRFIData}
            showingWarningErrorUpload={showingWarningErrorUpload}
            toggleWarningsErrors={toggleWarningsErrors}
            setToggleWarningsErrors={setToggleWarningsErrors}
            warningsOnly={onlyWarningsInUpload.current}
            canExportRFIData={canExportRFIData[selectedTab]}
          />
 */}
          <DataGrid
            rows={selectedTableRows}
            columns={selectedTableCols}
            density="compact"
            sortingMode="server"
            onSortModelChange={handleOnSortModelChange}
            rowsPerPageOptions={[25, 40, 50, 60, 75, 100]}
            onPageSizeChange={(newPageSize) => handleOnPageSizeChange(newPageSize)}
            pagination
            loading={showBusyDialog}
            getRowId={() => uuidv4()}
            getRowClassName={(params) => {
              if (params.row.Warnings_Errors && params.row.Warnings_Errors.errors.length > 0) {
                return "--RFI-warnings-errors-row"
              } else if (params.row.Warnings_Errors && params.row.Warnings_Errors.warnings.length > 0) {
                return "--RFI-warnings-row"
              }
              if (params.indexRelativeToCurrentPage % 2 === 0) {
                return "even-row"
              } else return "odd-row"
            }}
            getCellClassName={(params) => {
              if (params.field === "Warnings_Errors") {
                return "--RFI-warning-errors-cell"
              }
            }}
          />
          <TableControls
            pageNumber={pageNumber.current}
            pageSize={pageSize.current}
            numTotalTableRows={numTotalTableRows.current[selectedTab]}
            handleOnPageChange={handleOnPageChange}
          />
        </div>
      </div>
      <UploadProgressModal
        open={showUploadProgressModal}
        title={loadingTitle}
        setTitle={setLoadingTitle}
        fileSize={fileSize}
        uploadCompleted={uploadCompleted}
        trackProgress={true}
      />
      <SnackbarMessages
        showSnackbarSuccess={showSnackbarSuccess}
        setShowSnackbarSuccess={setShowSnackbarSuccess}
        showSnackbarError={showSnackbarError}
        setShowSnackbarError={setShowSnackbarError}
        snackbarMessage={snackbarMessage}
      />
      <RequestResubmissionWarningModal
        open={showRequestResubmissionWarningModal}
        setOpen={setShowRequestResubmissionWarningModal}
        requestResubmission={requestResubmission}
      />
      <SubmitRFIWarningModal
        showSubmitRFIWarningModal={showSubmitRFIWarningModal}
        setShowSubmitRFIWarningModal={setShowSubmitRFIWarningModal}
        msg={`If you submit RFI data, you won't be able to reupload data unless an administrator puts the ${selectedTab} table back to a "Not Submitted" state in the ${selectedOrg.name} organization.`}
        submitRFIData={submitRFIData}
      />
      <ReviewWarningsModal
        showWarningUploadModal={showWarningUploadModal}
        setShowWarningUploadModal={setShowWarningUploadModal}
      />
    </Box>
  )
}

export default GFEBS_CEFMS

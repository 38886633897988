
class FTEValidationUtils {

    static FTEValidationDashboardHeaders = {
        ORGANIZATION: 'organization',
        CIV_SUM_HOURS: 'civilianSumHours',
        CIV_FTES: 'civFTEs',
        UNACCOUNTED_CIV_FTES: 'unaccountedCivFTEs',
        CTR_FTES: 'ctrFTEs',
        MIL_FTES: 'milFTEs',
        TOTAL_CIV_FTES: 'totalCivFTEs',
        TOTAL_FTES: 'totalFTEs',
        FUNDING_MEMO_CIV_WYS: 'fundingMemoCivWYS',
        OVER_UNDER_ACTUAL_CIV_FTES: 'overUnderActualCivFTEs',
        NOTES: 'notes',
        CEFMS_OR_GFEBS_R1: 'cefmsOrGFEBSR1',
        GFEBS_R2: 'gfebsR2',
        SUPPLEMENTAL_WORKFORCE: 'supplementalWorkforce',
        CIVILIAN_PERCENTAGES: 'civilianPercentages',
        BPC_REDUCTION: 'bpcReduction',
    }

    static UrlApiTypes = {
        SUPPLEMENTAL_WORKFORCE: 'SupplementalWorkforceRfis',
        BPC_REDUCTION: 'BpcReductionRfis',
        CEFMS: 'CefmsRfis',
        GFEBS: 'GfebsReportRfis',
    }

}

export default FTEValidationUtils
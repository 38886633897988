import React from 'react'
import WorkloadInstructions from '../WorkloadInstructions'
import CloseIcon from '@mui/icons-material/Close'
import DSAMSTableUtils from './DSAMS_Table_Utils'
import '../RFI.css'

const DSAMS_RFI_Instructions_Modal = ({ selectedTab, disclaimerRFIModalOpen, setDisclaimerRFIModalOpen }) => {
    return (
        <WorkloadInstructions
            open={disclaimerRFIModalOpen}
            setOpen={setDisclaimerRFIModalOpen}
            name={selectedTab}
            line1={"Step 1: " + DSAMSTableUtils.RFIInstructions[selectedTab][0]}
            line2={"Step 2: " + DSAMSTableUtils.RFIInstructions[selectedTab][1]}
            line3={"Step 3: " + DSAMSTableUtils.RFIInstructions[selectedTab][2]}
        />
    )
}

export default DSAMS_RFI_Instructions_Modal

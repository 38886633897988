import { useState, useEffect, useMemo } from "react"

// Data
import { apiCalls } from "./components/DataService"

// Context
import AppContext from "./AppContext"

// Routes
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants"
import AppRoutes from "./AppRoutes"

// Reducers
import GlobalState from "./components/reducers/GlobalState"

// Query Library
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

//Styles
import { Box, ThemeProvider } from "@mui/material"
import theme from "./theme"
import CssBaseline from "@mui/material/CssBaseline"

// MUI Licensing
import { LicenseInfo } from "@mui/x-license"
import { createFTEDashboardInitObj } from "./components/DashboardMenu"
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE)

const orgHierarchy = [
  {
    tier2: "AMC",
    organizations: [
      {
        organizationName: "ACC",
        id: "297a095b-b46a-4f21-b83e-74aa87d04de5",
      },
      {
        organizationName: "AMCOM",
        id: "abf6621a-8be6-456b-8c36-22eccccebce0",
      },
      {
        organizationName: "CECOM",
        id: "87603d41-8072-4d30-905e-238b0a2dfe98",
      },
      {
        organizationName: "JMC",
        id: "bf61b2c3-2979-46eb-8259-98160092dc94",
      },
      {
        organizationName: "SATMO",
        id: "b719f592-14a9-44a3-a5df-4633b4a85d25",
      },
      {
        organizationName: "TACOM",
        id: "bb2cb128-e8b0-4faa-8429-92a7fc0e942d",
      },
      {
        organizationName: "USAMMA",
        id: "94bc9af4-b2fd-4ecd-9722-0107c8d2251f",
      },
      {
        organizationName: "USASAC",
        id: "e3b06615-5fcc-41d1-ab53-f0107a8a5213",
      },
    ],
  },
  {
    tier2: "ASA-ALT",
    organizations: [
      {
        organizationName: "DASA DE&C",
        id: "092c4034-7f00-4451-bf1e-2902ec5729ef",
      },
    ],
  },
  {
    tier2: "PEOPM",
    organizations: [
      {
        organizationName: "JPEO A&A",
        id: "9a817529-534c-4766-a7c7-146d36e750f7",
      },
      {
        organizationName: "JPEO CBRND",
        id: "974156ab-b77a-446b-ad48-8b792f822a47",
      },
      {
        organizationName: "PEO AVN",
        id: "6ef8833c-804c-4260-b124-f9ebe36cc7bf",
      },
      {
        organizationName: "PEO MASPO",
        id: "ab066502-a2e7-4e1a-8b03-b51a680e5292",
      },
      {
        organizationName: "PEO CS&CSS",
        id: "f69e86ce-da31-450e-9f5d-1853c4e28691",
      },
      {
        organizationName: "PEO GCS",
        id: "e1db825a-a512-45da-acdb-306cfe7431e4",
      },
      {
        organizationName: "PEO IEW&S",
        id: "a6483974-a8b9-4dba-8f53-dfd67e5b8db4",
      },
      {
        organizationName: "PEO M&S",
        id: "3995c765-6848-46c6-a9ef-09360df78bc5",
      },
      {
        organizationName: "PEO Soldier",
        id: "1c8ae677-140c-4131-ada4-174721fba8e7",
      },
      {
        organizationName: "PEO STRI",
        id: "7d999610-2784-40d5-8498-ad192e4d9e20",
      },
    ],
  },
  {
    tier2: "TRADOC SATFA",
    organizations: [
      {
        organizationName: "SATFA",
        id: "96f261d5-c3b1-4241-a430-59ba466b959f",
      },
    ],
  },
  {
    tier2: "USACE",
    organizations: [
      {
        organizationName: "USACE",
        id: "ea9987c6-0300-4e37-9fed-8b74550de0f0",
      },
    ],
  },
]

const App = () => {
  // ** --------------***     [ ~ HOOKS ~ ]     ***-------------- **
  const [allOrganizations, setAllOrganizations] = useState("")
  const [allFiscalCycles, setAllFiscalCycles] = useState("")

  const getOrg = () => {
    let sessionData = sessionStorage.getItem("ORG")
    console.log("session: ", sessionData)
    if (!sessionData) {
      // sessionStorage.setItem("ORG", JSON.stringify(allOrganizations[0]))
      // return JSON.parse(sessionStorage.getItem("ORG"))
    }

    if (sessionData && sessionData !== "undefined") {
      return JSON.parse(sessionStorage.getItem("ORG"))
    }
  }

  const getFY = () => {
    let sessionData = sessionStorage.getItem("FY")
    let fyObj
    let slicedNum

    if (!sessionData) {
      slicedNum = Number(allFiscalCycles[0].fiscalYear.slice(2)) + 3

      fyObj = {
        ...allFiscalCycles[0],
        SAPBR: `FY${slicedNum}`,
      }

      // setSelectedFY(fyObj)
      sessionStorage.setItem("FY", JSON.stringify(fyObj))
      return fyObj
    }

    if (sessionData) {
      let parsed = JSON.parse(sessionData)
      slicedNum = Number(parsed.fiscalYear.slice(2)) + 3
      let sapbrStr = `FY${slicedNum}`

      fyObj = {
        ...parsed,
        SAPBR: sapbrStr,
      }
      return fyObj
      // sessionData && setSelectedFY(fyObj)
    }
  }

  const [loading, setLoading] = useState(true)

  const [clickTarget, setClickTarget] = useState(null)
  const [selectedOrg, setSelectedOrg] = useState(getOrg())

  const [classificationData, setClassificationData] = useState("")
  const [selectedFY, setSelectedFY] = useState("")
  const [rfiOrgMapping, setRfiOrgMapping] = useState("")

  const [drawerOpen, setDrawerOpen] = useState(false)

  // ** --------------***     [ ~ VARS ~ ]     ***-------------- **

  const context = {
    drawerOpen,
    setDrawerOpen,
    classificationData,
    clickTarget,
    allOrganizations,
    selectedOrg,
    setSelectedOrg,
    selectedFY,
    setSelectedFY,
    allFiscalCycles,
    rfiOrgMapping,
    orgHierarchy,
  }

  // ** --------------***     [ ~ EFFECTS ~ ]     ***-------------- **

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    allFiscalCycles.length > 1 && getFY()
  }, [allFiscalCycles])

  useEffect(() => {
    if (classificationData && selectedFY && allOrganizations.length > 1) {
      allOrganizations.length > 1 && getOrg()
      setLoading(false)
    }
  }, [classificationData, selectedFY, allOrganizations])

  // ** --------------***     [ ~ FUNCTIONS ~ ]     ***-------------- **
  const getUser = async () => {
    if (sessionStorage.getItem("currentUser")) {
      return
    }
    try {
      let response = await apiCalls.fetchData("Accounts/me")
      if (response.status === 200) {
        sessionStorage.setItem("currentUser", JSON.stringify(response.data))
      }
    } catch (e) {
      console.log("e: ", e)
      window.location.replace(ApplicationPaths.IdentityLoginPath)
    }
  }

  const sortAlpha = (arr) => {
    return arr.sort((a, b) => a.name.localeCompare(b.name))
  }

  const init = async () => {
    getUser()

    let classFetch = apiCalls.fetchData("Configurations/Classification")
    let fiscalFetch = apiCalls.fetchData("FiscalCycles")
    let rfiMapFetch = apiCalls.fetchData("RfiOrganizationsMappings")
    let orgFetch = apiCalls.fetchData("Organizations")

    let [classResponse, fiscalResponse, rfiMapResponse, orgResponse] = await Promise.all([classFetch, fiscalFetch, rfiMapFetch, orgFetch])

    let rfiMappingData = createFTEDashboardInitObj(rfiMapResponse.data)
    setRfiOrgMapping(rfiMappingData)
    setAllFiscalCycles(fiscalResponse.data)
    setClassificationData(classResponse.data)
    setAllOrganizations(orgResponse.data)
    sortAlpha(orgResponse.data)

    setSelectedFY(fiscalResponse.data[0])

    sessionStorage.setItem("classificationData", JSON.stringify(classResponse.data))
  }

  const queryClient = new QueryClient()

  // ** --------------***     [ ~ RENDER ~ ]     ***-------------- **
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalState>
        <AppContext.Provider value={context}>
          <QueryClientProvider client={queryClient}>
            <Box
              className="appContainer"
              onClick={(e) => setClickTarget(e.target)}
            >
              {!loading && <AppRoutes loading={loading} />}
            </Box>

            {/* <div
              className="App"
              onClick={(e) => setClickTarget(e.target)}
            >
              {!loading && <AppRoutes loading={loading} />}
            </div> */}
            {/* <ReactQueryDevtools initialIsOpen={true} /> */}
          </QueryClientProvider>
        </AppContext.Provider>
      </GlobalState>
    </ThemeProvider>
  )
}

export default App

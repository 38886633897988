import '../RFI.css'

class USACEUtils {

	static Tabs = {
		H_CASE_EXCEPTIONS: 'H Case Exceptions',
		USACE_SCIP: 'USACE - Not H Case Lines',
	}

	static HCaseExceptionFields = {
		CASE_ID: "CaseId",
		LINE_ID: "LineId",
		FY_RECORD_IDENTIFIED_IN_CWAT: "FYRecordIdentifiedInCWAT",
	}

	static USACE_SCIPFields = {
		CASE_ID: "CaseId",
		LINE_ID: "LineId",
        FY_RECORD_IDENTIFIED_IN_CWAT: "FYRecordIdentifiedInCWAT",
        REMOVE: "Remove",
	}

    static USACE_ExpectedHeaders = [this.HCaseExceptionFields.CASE_ID, this.HCaseExceptionFields.LINE_ID]

	static NonNullableFields = {
		[this.Tabs.H_CASE_EXCEPTIONS]: [this.HCaseExceptionFields.CASE_ID, this.HCaseExceptionFields.LINE_ID],
		[this.Tabs.USACE_SCIP]: [this.USACE_SCIPFields.CASE_ID, this.USACE_SCIPFields.LINE_ID,],
	}

	static DateFields = {
		[this.Tabs.H_CASE_EXCEPTIONS]: [this.HCaseExceptionFields.FY_RECORD_IDENTIFIED_IN_CWAT],
		[this.Tabs.USACE_SCIP]: [this.USACE_SCIPFields.FY_RECORD_IDENTIFIED_IN_CWAT],
	}

	static NumericFields = {
		[this.Tabs.H_CASE_EXCEPTIONS]: [this.HCaseExceptionFields.LINE_ID],
		[this.Tabs.USACE_SCIP]: [this.USACE_SCIPFields.LINE_ID],
	}

	static UrlApiTypes = {
		[this.Tabs.H_CASE_EXCEPTIONS]: {
			record: 'UsaceHCaseExceptionRecords',
			rfi: 'UsaceHCaseExceptionRfis',
			rfiId: 'usaceHCaseExceptionRfiId',
			headers: this.HCaseExceptionFields,
		},
		[this.Tabs.USACE_SCIP]: {
			record: 'UsaceScipRecords',
			rfi: 'UsaceScipRfis',
			rfiId: 'usaceScipRfiId',
			headers: this.USACE_SCIPFields,
		},
	}

	static RFIInstructions = {
		H_CASE_EXCEPTIONS: 'Identify all H case lines that should not be associated with USACE as workload. In other words, organizations other than USACE supported that case line. The exceptions for lines on USACE cases should be manually typed in this table. Historical records should be retained even if the case / case line is closed or supply service complete.',
		USACE_SCIP: 'The table below lists previously submitted Army cases and lines that are not H-cases that USACE supported at some point.  USACE should review the table displaying prior records submitted/validated.  Indicate x if anything needs to be permanently removed from USACE (e.g. they never supported it).  USACE should upload a new CSV file identifying non-H cases and lines USACE worked from the most recenlty completed FY.',
	}
	
}

export default USACEUtils



import { Chip } from "@mui/material"
import { ChangeCircleOutlined, CheckCircle, CheckCircleOutline, CircleOutlined, ErrorOutline, FlagCircle, PlayCircleOutlined } from "@mui/icons-material"


/* Here are the possible values for status:
  NOT_SUBMITTED: "NOT_SUBMITTED",
  SUBMITTED: "SUBMITTED",
  REQUEST_RESUBMISSION: "REQUEST_RESUBMISSION",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  NOT_GENERATED: "NOT_GENERATED",
  GENERATED: "GENERATED",
*/

const RfiStatusChip = (status) => {
    //const { children, value, index, ...other } = props
    //let color = "primary";
    let color;
    let icon;
    let label = status.status.replace('_', ' ');

    switch(status.status) {
        case "NOT_SUBMITTED":
            color = "info";
            icon = <CircleOutlined />
            break;
        case "SUBMITTED":
            color = "info";
            icon = <CheckCircleOutline />
            break;
        case "APPROVED":
            color = "success";
            icon = <CheckCircle />
            break;
        case "REJECTED":
            color = "warning";
            icon = <ErrorOutline />
            break;
        case "NOT_GENERATED":
            color = "secondary";
            icon = <ChangeCircleOutlined />
            break;
        case "GENERATED":
            color = "secondary";
            icon = <FlagCircle />
            break;
        default:
            color = "primary";

    }
    
    return (
        <Chip color={color} icon={icon} label={label} />
    )
  }

  export default RfiStatusChip;
class PEO_PM_Utils {
    static PEO_PM_DataFields = {
        USER_CASE_ID: 'userCaseId',
        USER_CASE_LINE_NUMBER_ID: 'userCaseLineNumberId',
        LINE_NUMBER_ID: 'lineNumberId',
        MILITARY_ARTICLE_SERVICE_CODE: 'militaryArticleServiceCode',
        ARTICLE_DESCRIPTION_TEXT: 'articleDescriptionText',
        GENERIC_DESCRIPTION_TEXT: 'genericDescriptionText',
        SUPPORTING_ORGANIZATION_TITLE_NAME: 'supportingOrganizationTitleName',
        VALIDATED_WORKLOADS: 'validatedWorkloads',
    }

    static PEO_PM_TableFields = {
        USER_CASE_ID: 'userCaseId',
        LINE_ID: 'userCaseLineNumberId',
        MASL: 'militaryArticleServiceCode',
        MASL_DESCRIPTION: 'articleDescriptionText',
        GENERIC_DESCRIPTION: 'genericDescriptionText',
        PM: 'supportingOrganizationTitleName',
        VALIDATED_WORKLOADS: 'validatedWorkloads',
        REMOVE: 'Remove',
    }

    static ExpectedPEOPMHeaders = [this.PEO_PM_TableFields.USER_CASE_ID, this.PEO_PM_TableFields.LINE_ID, this.PEO_PM_TableFields.MASL, this.PEO_PM_TableFields.MASL_DESCRIPTION, this.PEO_PM_TableFields.GENERIC_DESCRIPTION, this.PEO_PM_TableFields.PM, this.PEO_PM_TableFields.VALIDATED_WORKLOADS, this.PEO_PM_TableFields.REMOVE]

    static UrlApiTypes = {
        record: 'PeoPmRecords',
		rfi: 'PeoPmRfis',
		rfiId: 'peoPmRfiId',
    }

    
}

export default PEO_PM_Utils
import { useEffect, useState } from "react"

// MUI
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"

// Custom
import CustomForm from "../components/elements/form-maker"
import { getRequired } from "../components/elements/utils"
import { apiCalls } from "../components/DataService"
import { inputDefs } from "./referenceUtils"
import { reqVar } from "./referenceUtils"
import useReferenceUtils from "./referenceUtils"


const ReferenceDialog = ({ modal, setModal, form, setForm, currentTab, handleSnackbarSuccess }) => {
  const [submitDisabled, setSubmitDisabled] = useState(true)

  useEffect(() => {
    validateRequiredFields()
  }, [form])


  const getNewLists = (num) => {
    if(num == 1){
      return(useReferenceUtils().updatedColumnDefs)
    }
    if(num == 2){
      return(useReferenceUtils().updatedInputDefs)
    }
    if(num == 3){
      reqVar = useReferenceUtils().updatedReqVar;
      return reqVar
    }
    else{
      return(useReferenceUtils())
    }
  }

  const handleFormChange = (e) => {
    setForm((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      }
    })
  }

  const handleCancelClick = () => {
    setForm({})
    setModal((prevState) => {
      return {
        ...prevState,
        isOpen: false,
      }
    })
  }

  const validateRequiredFields = () => {
    let errors = getRequired(reqVar[currentTab.name], form)
    setSubmitDisabled(errors[0])
  }

  const handleSubmit = async () => {
    switch (modal.mode) {
      case "New":
        let postResponse = await apiCalls.testPost(currentTab.endpoint, form)
        handleSnackbarSuccess()

        break
      case "Edit":
        let putResponse = await apiCalls.cleanPut(currentTab.endpoint, form, form.id)
        handleSnackbarSuccess()

        break
      default:
        break
    }
  }

  return (
    <Dialog
      open={modal.isOpen}
      onClose={() =>
        setModal((prevState) => {
          return {
            ...prevState,
            isOpen: false,
          }
        })
      }
    >
      <DialogTitle>{modal.mode}</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", minWidth: "400px" }}>
        <CustomForm
          form={form}
          // formObj={inputDefs[currentTab.name]}
          formObj={getNewLists(2)[currentTab.name]}
          handleChange={handleFormChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleCancelClick}
        >
          Cancel
        </Button>
        <Button
          variant="submitBtn"
          disabled={submitDisabled}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReferenceDialog


import { useEffect, useState } from "react"

// Libraries
import CloseIcon from "@mui/icons-material/Close"
import {
  Checkbox,
  Modal,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Box,
  Typography,
  Select,
  MenuItem,
} from "@mui/material"

// Data
import { apiCalls, QueryParamTypes } from "../../DataService"

// Custom
import { AllOrganizationRoles } from "../../../Utils"

export const UserOrgRoleMultipleSelect = ({ isMultSelectModalOpen, toggleMultSelectModal, userParams, selectedUserId }) => {
  const [organizations, setOrganizations] = useState([])
  const [pocs, setPocs] = useState([])
  const [selectedUser, setSelectedUser] = useState({})
  const [selectedPOC, setSelectedPOC] = useState({})
  const [selectedOrgRoleMap, setSelectedOrgRoleMap] = useState({})
  const [orgRoleNewMappings, setOrgRoleNewMappings] = useState({})
  const [showAssignUserToOrgRolesWarningDialog, setShowAssignUserToOrgRolesWarningDialog] = useState(false)

  const handleChange = (e, field, orgName) => {
    switch (field) {
      case "role-select":
        setSelectedOrgRoleMap({
          ...selectedOrgRoleMap,
          [orgName]: {
            initialRole: selectedOrgRoleMap[orgName]?.initialRole ? selectedOrgRoleMap[orgName].initialRole : null,
            role: e.target.value,
            remove: false,
          },
        })
        break
      case "remove-org":
        setSelectedOrgRoleMap({
          ...selectedOrgRoleMap,
          [orgName]: {
            initialRole: selectedOrgRoleMap[orgName]?.initialRole ? selectedOrgRoleMap[orgName].initialRole : null,
            role: selectedOrgRoleMap[orgName]?.role,
            remove: !selectedOrgRoleMap[orgName]?.remove,
          },
        })
        break
      default:
        break
    }
  }

  const handleCancel = () => {
    toggleMultSelectModal()
  }

  const handleAssignRolesInOrgs = async () => {
    for (const orgName of Object.keys(orgRoleNewMappings)) {
      const orgId = organizations.find((org) => org.name === orgName).id
      if (selectedOrgRoleMap[orgName]?.remove) {
        // Remove user from the organization
        await apiCalls.delete(`Organizations/${orgId}/Pocs/${selectedPOC.id}`)
      } else {
        if (!selectedOrgRoleMap[orgName]?.initialRole) {
          // Add user to the organization with role
          await apiCalls.post(`Organizations/${orgId}/Pocs/${selectedPOC.id}/${selectedOrgRoleMap[orgName]?.role}`, null)
        } else {
          // Changes user's role in the organization
          await apiCalls.put(`Organizations/${orgId}/Pocs/${selectedPOC.id}?role=${selectedOrgRoleMap[orgName]?.role}`, null)
        }
      }
    }
    toggleMultSelectModal()
  }

  const isRoleSelected = (orgName, role) => {
    return Object.keys(selectedOrgRoleMap).includes(orgName) && role === selectedOrgRoleMap[orgName]?.role
  }

  const canAssignOrgsRoles = () => {
    let assignLocked = true
    for (let i = 0; i < Object.keys(selectedOrgRoleMap).length; i++) {
      const orgName = Object.keys(selectedOrgRoleMap)[i]
      if (selectedOrgRoleMap[orgName]?.initialRole !== selectedOrgRoleMap[orgName]?.role || selectedOrgRoleMap[orgName]?.remove) {
        assignLocked = false
        break
      }
    }
    return !assignLocked
  }

  const getOrgRoleNewMappings = () => {
    let tmpNewMappings = {}
    Object.keys(selectedOrgRoleMap).forEach((orgName) => {
      if (selectedOrgRoleMap[orgName]?.role !== selectedOrgRoleMap[orgName]?.initialRole || selectedOrgRoleMap[orgName]?.remove) {
        tmpNewMappings = {
          ...tmpNewMappings,
          [orgName]: {
            initialRole: selectedOrgRoleMap[orgName]?.initialRole,
            role: selectedOrgRoleMap[orgName]?.role,
            remove: selectedOrgRoleMap[orgName]?.remove,
          },
        }
      }
    })
    setOrgRoleNewMappings(tmpNewMappings)
  }

  const getAllOrgsAndPOCs = () => {
    apiCalls.getAll(`Organizations`, { [QueryParamTypes.ORDER_BY]: "name" }).then((data) => {
      setOrganizations(data)
    })
    apiCalls.getAll(`Pocs`, { [QueryParamTypes.ORDER_BY]: "lastName" }).then((data) => {
      setPocs(data)
    })
  }

  useEffect(() => {
    getOrgRoleNewMappings()
  }, [selectedOrgRoleMap])

  useEffect(() => {
    if (selectedPOC?.organizationList) {
      let tmpMap = {}
      selectedPOC.organizationList.forEach((orgRoleObj) => {
        tmpMap = {
          ...tmpMap,
          [orgRoleObj.organization.name]: {
            initialRole: orgRoleObj.role,
            role: orgRoleObj.role,
            remove: false,
          },
        }
      })
      setSelectedOrgRoleMap(tmpMap)
    }
  }, [selectedPOC])

  useEffect(() => {
    if (selectedUser.userName) {
      const tmpSelectedPOC = pocs.find((poc) => poc.userName === selectedUser.userName)
      setSelectedPOC(tmpSelectedPOC)
    }
  }, [selectedUser, pocs])

  useEffect(() => {
    if (selectedUserId) {
      apiCalls.getById(`Accounts`, selectedUserId).then((data) => {
        setSelectedUser(data)
      })
    }
  }, [selectedUserId])

  useEffect(() => {
    if (isMultSelectModalOpen) {
      getAllOrgsAndPOCs()
    }
  }, [isMultSelectModalOpen])

  useEffect(() => {
    if (isMultSelectModalOpen) {
      getAllOrgsAndPOCs()
    }
  }, [])

  return (
    <Box>
      <Modal
        open={isMultSelectModalOpen}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box className="user-modal-container">
          {/* HEADER */}
          <div className="user-modal-header">
            <Typography sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>
              Edit {userParams.firstName} {userParams.lastName}'s Roles:
            </Typography>
            <button onClick={handleCancel}>
              <CloseIcon sx={{ color: "#FFF" }} />
            </button>
          </div>

          {/* USER FORM */}
          <div className="user-form">
            {organizations.map((org, key) => (
              <div
                className="user-org-role-mult-select"
                key={key}
              >
                <div
                  className="user-input-field"
                  style={{ flex: 1 }}
                >
                  <Typography>{org.name}</Typography>
                </div>
                <div
                  className="user-input-field"
                  style={{ flex: 2 }}
                >
                  <Select
                    defaultValue="Select"
                    size="small"
                    value={selectedOrgRoleMap[org?.name]?.role ? selectedOrgRoleMap[org?.name].role : "Select"}
                    onChange={(e) => handleChange(e, "role-select", org.name)}
                  >
                    {AllOrganizationRoles.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>
                    })}
                  </Select>
                </div>
                <div
                  className="user-remove-checkbox"
                  style={{ flex: 2 }}
                >
                  {selectedPOC?.organizationList?.map((orgRoleObj) => orgRoleObj.organization.name)?.includes(org.name) && (
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={selectedOrgRoleMap[org.name]?.remove == undefined ? false : selectedOrgRoleMap[org.name]?.remove}
                        onChange={(e) => {
                          handleChange(e, "remove-org", org.name)
                        }}
                      />
                      <Typography>Remove from {org.name}</Typography>
                    </Box>
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* USER BTNS */}
          <div className="user-modal-buttons">
            <Button
              variant="outlined"
              onClick={handleCancel}
              sx={{ marginRight: "10px" }}
            >
              Cancel
            </Button>
            <Button
              variant="submitBtn"
              onClick={() => setShowAssignUserToOrgRolesWarningDialog(true)}
              disabled={!canAssignOrgsRoles()}
            >
              Assign
            </Button>
          </div>
        </Box>
      </Modal>
      <Dialog
        open={showAssignUserToOrgRolesWarningDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Role:</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: "5px" }}>
            This action will assign
            {selectedPOC?.firstName && selectedPOC?.lastName ? ` ${selectedPOC.firstName} ${selectedPOC.lastName} ` : " this POC "}
            to the selected roles in the following organizations:
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            <ul>
              {Object.keys(orgRoleNewMappings).map((orgName, idx) => {
                // Removing an org
                if (selectedOrgRoleMap[orgName]?.remove) {
                  return (
                    <li key={idx}>
                      Remove from <strong>{orgName}</strong>
                    </li>
                  )
                }
                // Adding an org
                if (!selectedOrgRoleMap[orgName]?.initialRole) {
                  return (
                    <li key={idx}>
                      Add to <strong>{orgName}</strong> as <strong>{selectedOrgRoleMap[orgName]?.role}</strong>
                    </li>
                  )
                } else {
                  // Changing roles in an org
                  return (
                    <li key={idx}>
                      Change role in <strong>{orgName}</strong> from <strong>{selectedOrgRoleMap[orgName]?.initialRole}</strong> to{" "}
                      <strong>{selectedOrgRoleMap[orgName]?.role}</strong>
                    </li>
                  )
                }
              })}
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: "black", border: "1px solid black" }}
            onClick={() => setShowAssignUserToOrgRolesWarningDialog(false)}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "red", color: "white", fontWeight: "bold", border: "1px solid black" }}
            onClick={() => {
              handleAssignRolesInOrgs()
              setShowAssignUserToOrgRolesWarningDialog(false)
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

import { withStyles } from '@mui/styles';
import { Tooltip } from '@mui/material';
import { Warning, Cancel } from '@mui/icons-material';

const WarningsErrorsTooltip = withStyles({
    tooltip: {
      color: 'red',
      backgroundColor: 'white',
      fontSize: 12,
    }
})(Tooltip);

function validateNumeric(value) {
    const LENGTH = value.length
    let extractedNum = Number(value)
    if (value[0] === '(' && value[LENGTH - 1] === ')') {
        // Convert (#) to a negative # if necessary
        extractedNum = -Number(value.slice(1, LENGTH - 2))
    }
    return Number.isInteger(Math.floor(extractedNum))
}

class SAPBR_Utils {

    static Tabs = {
        SAPBR: 'SAPBR Decision Data',
        RMD: 'Resource Management Decision',
    }

    static TabCSVFileNames = {
        [this.Tabs.SAPBR]: 'SapbrRecords.csv',
        [this.Tabs.RMD]: 'RmdRecords.csv',
    }

    static SAPBRTableFields = {
        FY: 'FY',
        ORGANIZATION: 'Organization',
        UIC: 'UIC',
        CWAT_ORGANIZATION: 'CWAT Organization',
        APPROVED_WORK_YEAR_ONE: '[FY] CIV Approved WYs Funding Memo (with BPC excluded)',
        APPROVED_WORK_YEAR_TWO: '[FY+1] CIV Approved WYs Increase (CIV only from RMD decisions)',
        APPROVED_WORK_YEAR_THREE: '1st Outyear ([FY+2])-Approved CIV WYs',
        APPROVED_WORK_YEAR_FOUR: 'DASA DECISION Approved WYs in [FY+2]',
        COMMENTS: 'Notes on 1st outyear',
    }
    
    static SAPBRDataFields = {
        FY: 'fiscalYear',
        ORGANIZATION: 'organization',
        UIC: 'uic',
        CWAT_ORGANIZATION: 'cwatOrganization',
        APPROVED_WORK_YEAR_ONE: 'approvedWorkYearsOne',
        APPROVED_WORK_YEAR_TWO: 'approvedWorkYearsTwo',
        APPROVED_WORK_YEAR_THREE: 'approvedWorkYearsThree',
        APPROVED_WORK_YEAR_FOUR: 'approvedWorkYearsFour',
        COMMENTS: 'comments',
    }

    static RMDTableFields = {
        ORGANIZATION: 'Organization',
        UIC: 'UIC',
        APPROVED_CIV_INCREASE: 'Approved CIV Increase',
        APPROVED_CTR_INCREASE: 'Approved CTR Increase',
        COMMENTS: 'AFG Notes',
    }
    
    static RMDDataFields = {
        FC_END_YEAR: 'fiscalCycleEndYear',
        ORGANIZATION: 'organization',
        UIC: 'uic',
        APPROVED_CIV_INCREASE: 'approvedCivIncrease',
        APPROVED_CTR_INCREASE: 'approvedCtrIncrease',
        COMMENTS: 'comments',
    }

    static SAPBR_COLS = [
        {
            field: 'Id',
            headerName: 'Id',
            hide: true,
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.SAPBRTableFields.FY, 
            headerName: 'FY', 
            sortable: false,
            flex: 2,
            minWidth: 75, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.SAPBRTableFields.ORGANIZATION, 
            headerName: 'Organization', 
            flex: 2,
            minWidth: 225, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.SAPBRTableFields.UIC, 
            headerName: 'UIC', 
            flex: 2,
            minWidth: 100, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.SAPBRTableFields.CWAT_ORGANIZATION, 
            headerName: 'CWAT Organization', 
            flex: 2,
            minWidth: 175, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.SAPBRTableFields.APPROVED_WORK_YEAR_ONE, 
            headerName: '[FY] App Wys Funding Memo (with BPC excluded)', 
            flex: 2,
            minWidth: 500, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.SAPBRTableFields.APPROVED_WORK_YEAR_TWO, 
            headerName: '[FY+1] Approved WYs Increase (CIV only from RMD decisions)', 
            flex: 2,
            minWidth: 525, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.SAPBRTableFields.APPROVED_WORK_YEAR_THREE, 
            headerName: '1st Outyear ([FY+2]) - approved CIV Wys', 
            flex: 2,
            minWidth: 350, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.SAPBRTableFields.APPROVED_WORK_YEAR_FOUR, 
            headerName: 'DASA DECISION Approved WYs in [FY+2]', 
            flex: 2,
            minWidth: 350, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.SAPBRTableFields.COMMENTS, 
            headerName: 'Notes on 1st outyear', 
            flex: 2,
            minWidth: 250, 
            headerClassName: '--RFI-grid-header',
        },
        {
            field: 'Warnings_Errors',
            headerName: 'Warnings/Errors',
            sortable: false,
            flex: 2,
            minWidth: 350,
            headerClassName: '--RFI-grid-warning-header',
            headerAlign: 'center',
            renderCell: (params) => {
                if (params?.value) {
                    let tooltipMsg = ''
                    let lineNumber = null
                    if (params?.value?.errors?.length > 0) {
                        lineNumber = params?.value?.errors[0]?.rowNumber
                        tooltipMsg += `Line Number ${lineNumber}) `
                    } else if (params?.value?.warnings?.length > 0) {
                        lineNumber = params?.value?.warnings[0]?.rowNumber
                        tooltipMsg += `Line Number ${lineNumber}) `
                    }
                    params?.value?.errors?.forEach((error, index) => tooltipMsg += `E${index + 1}) ${error.message}\n`)
                    params?.value?.warnings?.forEach((warning, index) => tooltipMsg += `W${index + 1}) ${warning.message}\n`)
                    return (
                        <WarningsErrorsTooltip title={tooltipMsg} placement='bottom'>
                            <div className="--RFI-warning-errors-icons">
                                {params?.value?.warnings?.length > 0 && <Warning/>}
                                {params?.value?.errors?.length > 0 && <Cancel/>}
                            </div>
                        </WarningsErrorsTooltip>
                    )
                } 
                return null
            },
        },
    ]

    static RMD_COLS = [
        {
            field: 'Id',
            headerName: 'Id',
            hide: true,
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.RMDTableFields.ORGANIZATION, 
            headerName: 'Organization', 
            flex: 2,
            minWidth: 225, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.RMDTableFields.UIC, 
            headerName: 'UIC', 
            flex: 2,
            minWidth: 100, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.RMDTableFields.APPROVED_CIV_INCREASE, 
            headerName: 'Approved CIV Increase', 
            flex: 2,
            minWidth: 200, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.RMDTableFields.APPROVED_CTR_INCREASE, 
            headerName: 'Approved CTR Increase', 
            flex: 2,
            minWidth: 200, 
            headerClassName: '--RFI-grid-header',
        },
        { 
            field: this.RMDTableFields.COMMENTS, 
            headerName: 'AFG Notes', 
            flex: 2,
            minWidth: 250, 
            headerClassName: '--RFI-grid-header',
        },
        {
            field: 'Warnings_Errors',
            headerName: 'Warnings/Errors',
            sortable: false,
            flex: 2,
            minWidth: 350,
            headerClassName: '--RFI-grid-warning-header',
            headerAlign: 'center',
            renderCell: (params) => {
                if (params?.value) {
                    let tooltipMsg = ''
                    let lineNumber = null
                    if (params?.value?.errors?.length > 0) {
                        lineNumber = params?.value?.errors[0]?.rowNumber
                        tooltipMsg += `Line Number ${lineNumber}) `
                    } else if (params?.value?.warnings?.length > 0) {
                        lineNumber = params?.value?.warnings[0]?.rowNumber
                        tooltipMsg += `Line Number ${lineNumber}) `
                    }
                    params?.value?.errors?.forEach((error, index) => tooltipMsg += `E${index + 1}) ${error.message}\n`)
                    params?.value?.warnings?.forEach((warning, index) => tooltipMsg += `W${index + 1}) ${warning.message}\n`)
                    return (
                        <WarningsErrorsTooltip title={tooltipMsg} placement='bottom'>
                            <div className="--RFI-warning-errors-icons">
                                {params?.value?.warnings?.length > 0 && <Warning/>}
                                {params?.value?.errors?.length > 0 && <Cancel/>}
                            </div>
                        </WarningsErrorsTooltip>
                    )
                } 
                return null
            },
        },
    ]

    static UrlApiTypes = {
        [this.Tabs.SAPBR]: {
            rfi: 'SapbrRfis',
            record: 'SapbrRecords',
            rfiId: 'sapbrRfiId',
            columns: this.SAPBR_COLS,
            headers: this.SAPBRTableFields,
            dataHeaders: this.SAPBRDataFields,
        },
        [this.Tabs.RMD]: {
            rfi: 'RmdRfis',
            record: 'RmdRecords',
            rfiId: 'rmdRfiId',
            columns: this.RMD_COLS,
            headers: this.RMDTableFields,
            dataHeaders: this.RMDDataFields,
        }
    }

    static correctFYInHeader = (field, selectedFiscalCycle) => {
		let tmpField = field
		switch (tmpField) {
			case this.SAPBRTableFields.APPROVED_WORK_YEAR_ONE:
				tmpField = tmpField.replace('[FY]', `${selectedFiscalCycle[this.SAPBRDataFields.FY]}`)
				break
			case this.SAPBRTableFields.APPROVED_WORK_YEAR_TWO:
				tmpField = tmpField.replace('[FY+1]', `FY${(Number(selectedFiscalCycle[this.SAPBRDataFields.FY].slice(-2)) + 1).toString()}`)
				break
			case this.SAPBRTableFields.APPROVED_WORK_YEAR_THREE:
				tmpField = tmpField.replace('[FY+2]', `FY${(Number(selectedFiscalCycle[this.SAPBRDataFields.FY].slice(-2)) + 2).toString()}`)
				break
			case this.SAPBRTableFields.APPROVED_WORK_YEAR_FOUR:
				tmpField = tmpField.replace('[FY+2]', `FY${(Number(selectedFiscalCycle[this.SAPBRDataFields.FY].slice(-2)) + 2).toString()}`)
				break
			default:
				break
		}
		return tmpField
	}
    
    static ExpectedHeaders = {
        [this.Tabs.SAPBR]: [this.SAPBRTableFields.ORGANIZATION, this.SAPBRTableFields.UIC, this.SAPBRTableFields.CWAT_ORGANIZATION, this.SAPBRTableFields.APPROVED_WORK_YEAR_ONE, this.SAPBRTableFields.APPROVED_WORK_YEAR_TWO, this.SAPBRTableFields.APPROVED_WORK_YEAR_THREE, this.SAPBRTableFields.APPROVED_WORK_YEAR_FOUR, this.SAPBRTableFields.COMMENTS],
        [this.Tabs.RMD]: [this.RMDTableFields.ORGANIZATION, this.RMDTableFields.UIC, this.RMDTableFields.APPROVED_CIV_INCREASE, this.RMDTableFields.APPROVED_CTR_INCREASE, this.RMDTableFields.COMMENTS],
    }

    static NonNullableFields = {
		[this.Tabs.SAPBR]: [this.SAPBRTableFields.ORGANIZATION, this.SAPBRTableFields.UIC, this.SAPBRTableFields.CWAT_ORGANIZATION, this.SAPBRTableFields.APPROVED_WORK_YEAR_ONE, this.SAPBRTableFields.APPROVED_WORK_YEAR_TWO, this.SAPBRTableFields.APPROVED_WORK_YEAR_THREE, this.SAPBRTableFields.APPROVED_WORK_YEAR_FOUR],
		[this.Tabs.RMD]: [this.RMDTableFields.ORGANIZATION, this.RMDTableFields.UIC, this.RMDTableFields.APPROVED_CIV_INCREASE, this.RMDTableFields.APPROVED_CTR_INCREASE],
    }

    static DateFields = {
        [this.Tabs.SAPBR]: [],
        [this.Tabs.RMD]: [],
    }

	static NumericFields = {
		[this.Tabs.SAPBR]: [this.SAPBRTableFields.APPROVED_WORK_YEAR_ONE, this.SAPBRTableFields.APPROVED_WORK_YEAR_TWO, this.SAPBRTableFields.APPROVED_WORK_YEAR_THREE, this.SAPBRTableFields.APPROVED_WORK_YEAR_FOUR, this.SAPBRDataFields.APPROVED_WORK_YEAR_ONE, this.SAPBRDataFields.APPROVED_WORK_YEAR_TWO, this.SAPBRDataFields.APPROVED_WORK_YEAR_THREE, this.SAPBRDataFields.APPROVED_WORK_YEAR_FOUR],
		[this.Tabs.RMD]: [this.RMDTableFields.APPROVED_CIV_INCREASE, this.RMDTableFields.APPROVED_CTR_INCREASE, this.RMDDataFields.APPROVED_CIV_INCREASE, this.RMDDataFields.APPROVED_CTR_INCREASE],
    }
}

export default SAPBR_Utils

import { Card, CardHeader } from "@mui/material"

const CustomCard = ({ header }) => {
  return (
    <Card
      sx={{
        boxShadow: "none",
        minWidth: "100%",
        padding: "1%",
        "& .MuiCardHeader-title": {
          fontSize: "1.8rem",
          fontWeight: 500,
        },
        "& .MuiCardHeader-subheader": {
          fontSize: "1rem",
          fontWeight: 300,
        },
      }}
    >
      <CardHeader
        title={header}
        sx={{
          "& .MuiTypography-root": {
            fontFamily: "Bitter",
            fontWeight: "bold",
          },
        }}
      />
    </Card>
  )
}

export default CustomCard

import React from 'react'
import { Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import GFEBS_CEFMS_Table_Utils from './GFEBS_CEFMS_Table_Utils';
import '../RFI.css'

const GFEBS_CEFMS_Instructions_Modal = ({disclaimerRFIModalOpen, setDisclaimerRFIModalOpen}) => {
    return (
        <Modal
            open={disclaimerRFIModalOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        > 
            <div className='--RFI-disclaimer-modal-container'>
                <div className='--RFI-disclaimer-modal-header'>
                    <h4>RFI Instructions - GFEBS/CEFMS</h4>
                    <CloseIcon fontSize='large' onClick={() => setDisclaimerRFIModalOpen(false)}/>
                </div>
                <div className='--RFI-disclaimer-modal-body'>
                    <div className='--RFI-disclaimer-modal-messages'>
                        <div>
                            <h3 style={{textAlign: 'center'}}>Login</h3>
                            {GFEBS_CEFMS_Table_Utils.RFIInstructions.LOGIN.map((instruction, idx) => <p key={idx} className='--RFI-disclaimer-message'>{`(${idx + 1})`} &emsp;&emsp;{instruction}</p>)}
                        </div>
                        <br/>
                        <div>
                            <h3 style={{textAlign: 'center'}}>Upload & Submission</h3>
                            {GFEBS_CEFMS_Table_Utils.RFIInstructions.UPLOAD_SUBMISSION.map((instruction, idx) => <p key={idx} className='--RFI-disclaimer-message'>{`(${idx + 1})`} &emsp;&emsp;{instruction}</p>)}
                        </div>
                        <br/>
                        <div>
                            <h3 style={{textAlign: 'center'}}>Resubmission</h3>
                            {GFEBS_CEFMS_Table_Utils.RFIInstructions.RESUBMISSION.map((instruction, idx) => <p key={idx} className='--RFI-disclaimer-message'>{`(${idx + 1})`} &emsp;&emsp;{instruction}</p>)}
                        </div>
                        <br/>
                        <div>
                            <h3 style={{textAlign: 'center'}}>Review</h3>
                            {GFEBS_CEFMS_Table_Utils.RFIInstructions.REVIEW.map((instruction, idx) => <p key={idx} className='--RFI-disclaimer-message'>{`(${idx + 1})`} &emsp;&emsp;{instruction}</p>)}
                        </div>
                    </div>
                    <button className='--RFI-close-RFI-modal-btn' onClick={() => setDisclaimerRFIModalOpen(false)}>Close</button>
                </div>
            </div>
        </Modal>
    )
}

export default GFEBS_CEFMS_Instructions_Modal

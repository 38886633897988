import { DataGrid } from "@mui/x-data-grid"

//Custom
import Base from "../components/elements/layout/Base"
import CustomPieChart from "../components/elements/charts/CustomPieChart"

// MUI
import { Box, Collapse, Divider, FormControl, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Typography } from "@mui/material"

// Vars
import { apiCalls } from "../components/DataService"
import { useContext, useEffect, useState } from "react"
import AppContext from "../AppContext"
import CustomTabPanel from "../components/custom/CustomTabPanel"

// Styles
import "./styles/LandingPage.css"

const getRandomNum = () => {
  let max = 300
  let min = 0
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const getNum = (num) => {
  return Number(num.slice(2)) + 3
}

const wpDetailsData = [
  { id: 1, caseID: "AL-B-PAB", caseDetail: 2 },
  { id: 2, caseID: "MX-B-PBS", caseDetail: 1 },
  { id: 3, caseID: "MX-B-PBS", caseDetail: 1 },

  { id: 4, caseID: "MX-B-PBS", caseDetail: 1 },
  { id: 5, caseID: "MX-B-PBS", caseDetail: 1 },
]

const wpDetailsCols = [
  {
    field: "caseID",
    headerName: "Case ID",
    headerClassName: "workloadCountsHeader",
    flex: 1,
  },
  {
    field: "caseDetail",
    headerName: "Case Detail",
    headerClassName: "workloadCountsHeader",
    flex: 1,
  },
]

const ClaimantDashboard = () => {
  // ** --------------***     [ ~ HOOKS ~ ]     ***-------------- **
  let context = useContext(AppContext)
  let { allOrganizations, allFiscalCycles, orgHierarchy } = context

  const sortedFC = allFiscalCycles.sort((itemA, itemB) => {
    return getNum(itemA.fiscalYear) - getNum(itemB.fiscalYear)
  })

  const [currentTab, setCurrentTab] = useState(1)
  const [currentDataTab, setCurrentDataTab] = useState(1)

  const [selectedOrg, setSelectedOrg] = useState(context.selectedOrg || allOrganizations[0])

  const [selectedTier2Org, setSelectedTier2Org] = useState(orgHierarchy[0])
  const [selectedTier3Org, setSelectedTier3Org] = useState("")

  const [selectedFY, setSelectedFY] = useState(sortedFC[0])
  const [workloadCountsData, setWorkloadCountsData] = useState("")

  // ** --------------***     [ ~ EFFECTS ~ ]     ***-------------- **
  useEffect(() => {
    if (selectedFY?.id) {
      fetchData()
    }
  }, [selectedTier2Org, selectedTier3Org, selectedOrg, selectedFY])

  useEffect(() => {
    if (selectedTier2Org) {
      setSelectedTier3Org("")
    }
  }, [selectedTier2Org])

  // ** --------------***     [ ~ FUNCTIONS ~ ]     ***-------------- **
  const fetchData = async () => {
    let workloadCountsFetch = await apiCalls.getById("WorkloadCounts", selectedFY.id)
    let filteredData
    let dataObj

    if (selectedTier3Org?.id) {
      filteredData = workloadCountsFetch.filter((item) => item.organizationId === selectedTier3Org.id)
      dataObj = getCasesData(filteredData)
      setWorkloadCountsData([dataObj])
    }

    if (!selectedTier3Org?.id && selectedTier2Org) {
      let arr = []
      selectedTier2Org.organizations.forEach((item) => {
        return workloadCountsFetch.find((wlData) => {
          if (wlData.organizationId === item.id) {
            arr.push(wlData)
          }
        })
      })

      dataObj = getCasesData(arr)
      setWorkloadCountsData([dataObj])
    }
    return dataObj
  }

  const getCasesData = (paramsArr) => {
    let casesInDevelopment = 0
    let casesInExecution = 0
    let linesInExecution = 0
    let casesInClosure = 0

    paramsArr.forEach((item) => {
      let { workPackage, stock0 } = item

      if (workPackage === "Implemented Lines") {
        linesInExecution = stock0
      }

      if (workPackage === "IMP") {
        casesInExecution = stock0
      }

      if (workPackage === "Closing Cases") {
        casesInClosure = stock0
      }

      if (workPackage === "LOA" || workPackage === "AMD" || workPackage === "MOD" || workPackage === "P&A") {
        casesInDevelopment += stock0
      }
    })

    return {
      id: getRandomNum(),
      casesInDevelopment,
      casesInExecution,
      linesInExecution,
      casesInClosure,
    }
  }

  const handleTier2Change = (e) => {
    setSelectedTier3Org("")
    let test = orgHierarchy.find((item) => item.tier2 === e.target.innerText)
    setSelectedTier2Org(test)
  }

  const handleTier3Change = (e) => {
    let test = selectedTier2Org.organizations.find((item) => item.organizationName === e.target.innerText)
    setSelectedTier3Org(test)
  }

  const handleDataTabChange = async (e, newValue) => {
    setCurrentDataTab(newValue)
  }

  const getFYOptions = () => {
    return sortedFC.map((item, index) => {
      let menuItem =
        index + 1 == sortedFC.length ? (
          <MenuItem
            value={item.id}
            sx={{ display: "flex", alignItems: "center", padding: "5px 100px 5px 25px" }}
          >
            {item.fiscalYear}
          </MenuItem>
        ) : (
          <MenuItem
            value={item.id}
            sx={{ display: "flex", alignItems: "center", padding: "5px 100px 5px 25px" }}
            divider
          >
            {item.fiscalYear}
          </MenuItem>
        )

      return menuItem
    })
  }

  const handleFYChange = (e) => {
    let selectedId = e.target.value
    setSelectedFY(sortedFC.find((item) => item.id === selectedId))
  }

  const getOrgMenu = () => {
    let arr = orgHierarchy.map((item) => {
      let { tier2, organizations } = item
      return (
        <>
          <MenuItem
            value={item}
            onClick={(e) => handleTier2Change(e)}
            selected={selectedTier2Org?.["tier2"] === tier2}
            sx={{ fontWeight: "bold", padding: "5px 15px" }}
          >
            {tier2}
          </MenuItem>
          <Collapse in={selectedTier2Org.tier2 === tier2}>
            {organizations.map((subOrg) => {
              return (
                <MenuItem
                  key={subOrg.organizationId}
                  value={subOrg.organizationId}
                  onClick={(e) => handleTier3Change(e)}
                  selected={selectedTier3Org?.organizationName ? selectedTier3Org.organizationName === subOrg.organizationName : false}
                >
                  {subOrg.organizationName}
                </MenuItem>
              )
            })}
          </Collapse>
        </>
      )
    })
    return (
      <FormControl
        variant="standard"
        sx={{ display: "flex", minWidth: "175px", marginRight: "15px", marginTop: "15px" }}
      >
        <InputLabel>Organization</InputLabel>
        <Select
          label="Organization"
          value={selectedTier2Org}
          renderValue={(params) => (selectedTier3Org ? params.tier2 + " - " + selectedTier3Org.organizationName : params.tier2)}
        >
          {arr}
        </Select>
      </FormControl>
    )
  }

  // ** --------------***     [ ~ RENDER ~ ]     ***-------------- **
  return (
    <Base>
      {/* DIVIDER */}
      <Divider sx={{ width: "100%", paddingTop: "5px" }} />

      <CustomTabPanel
        value={currentTab}
        index={1}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", minWidth: "100%" }}>
          {/* MUI SELECTS */}
          <Box sx={{ display: "flex", flexDirection: "column", marginRight: "150px", width: "5%" }}>
            {/* TODO: add handlechange */}
            {/* ORG SELECT */}

            {/* TIER 2 */}
            <Box sx={{ display: "flex" }}>{getOrgMenu()}</Box>

            {/* FISCAL YEAR SELECT */}
            <FormControl
              variant="standard"
              className="selectLabel"
              sx={{ display: "flex", minWidth: "175px", marginRight: "15px", marginTop: "15px" }}
            >
              <InputLabel>Fiscal Year</InputLabel>
              <Select
                label="Fiscal Year"
                value={selectedFY}
                renderValue={(params) => params.fiscalYear}
                onChange={handleFYChange}
                autoWidth
                sx={{
                  ".MuiSelect-select": {},
                }}
              >
                {getFYOptions()}
              </Select>
            </FormControl>
          </Box>

          {/* MUI DATA GRID CONTAINER */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              maxWidth: "100%",
              minWidth: "95%",
            }}
          >
            <Box
              className="countsTest"
              sx={{ height: "100%", width: "100%", marginBottom: "50px" }}
            >
              <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "15px" }}>
                <Typography variant="dataGridTitle">Workload Counts</Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  maxWidth: "100%",
                  width: "100%",
                }}
              >
                {workloadCountsData && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "30%",
                    }}
                  >
                    <TextField
                      label="Cases In Development"
                      value={workloadCountsData[0]?.casesInDevelopment || 0}
                      disabled
                      sx={{
                        marginBottom: "10px",
                        minWidth: "75%",
                      }}
                    />
                    <TextField
                      label="Cases In Execution"
                      value={workloadCountsData[0]?.casesInExecution || 0}
                      disabled
                      sx={{
                        marginBottom: "10px",
                        minWidth: "75%",
                      }}
                    />
                    <TextField
                      label="Lines In Execution"
                      value={workloadCountsData[0]?.linesInExecution || 0}
                      disabled
                      sx={{
                        marginBottom: "10px",
                        minWidth: "75%",
                      }}
                    />
                    <TextField
                      label="Cases In Closure"
                      value={workloadCountsData[0]?.casesInClosure || 0}
                      disabled
                      sx={{
                        minWidth: "75%",
                      }}
                    />
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    border: "1px solid rgb(224, 224, 224)",
                    borderRadius: "5px",
                    width: "70%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {workloadCountsData[0] && <CustomPieChart data={workloadCountsData[0]} />}
                </Box>
              </Box>
            </Box>

            {/* DATA GRID TITLE */}
            {selectedTier3Org && (
              <Box
                className="countsTest"
                sx={{ height: "100%", width: "100%" }}
              >
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: "15px" }}>
                  <Typography variant="dataGridTitle">Case and Work Package Details</Typography>
                </Box>

                <Tabs
                  value={currentDataTab}
                  onChange={handleDataTabChange}
                  sx={{
                    width: "100%",
                    marginBottom: "0px",
                    borderColor: "divider",
                    marginTop: "10px",
                    ".MuiTabs-flexContainer": {
                      justifyContent: "space-evenly",
                      overflow: "visible",
                      marginLeft: "0px",
                    },
                    ".MuiTabs-scrollableX": {
                      overflowX: "auto",
                    },
                    ".Mui-selected": {},
                  }}
                >
                  <Tab
                    label="P&As"
                    value={1}
                    disableFocusRipple
                    disableRipple
                  />
                  <Tab
                    label="LOR"
                    value={2}
                    disableFocusRipple
                    disableRipple
                  />
                  <Tab
                    label="Amendments"
                    value={3}
                    disableFocusRipple
                    disableRipple
                  />
                  <Tab
                    label="Modifications"
                    value={4}
                    disableFocusRipple
                    disableRipple
                  />
                  <Tab
                    label="Implemented Lines"
                    value={5}
                    disableFocusRipple
                    disableRipple
                  />
                  <Tab
                    label="Requisitions"
                    value={6}
                    disableFocusRipple
                    disableRipple
                  />
                  <Tab
                    label="SDRs"
                    value={7}
                    disableFocusRipple
                    disableRipple
                  />
                  <Tab
                    label="Closing Cases"
                    value={8}
                    disableFocusRipple
                    disableRipple
                  />
                </Tabs>

                <Box
                  sx={{
                    display: "flex",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                >
                  <DataGrid
                    loading={!wpDetailsData}
                    rows={wpDetailsData}
                    columns={wpDetailsCols}
                    getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
                    hideFooter
                    disableRowSelectionOnClick
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </CustomTabPanel>

      <CustomTabPanel
        value={currentTab}
        index={2}
      >
        <FormControl
          variant="standard"
          className="selectLabel"
          sx={{ display: "flex", minWidth: "150px", marginRight: "15px" }}
        >
          <InputLabel>Fiscal Year</InputLabel>
          <Select
            label="Fiscal Year"
            value={selectedFY}
            renderValue={(selected) => selected.fiscalYear}
            onChange={handleFYChange}
            autoWidth
          >
            {getFYOptions()}
          </Select>
        </FormControl>

        {selectedTier3Org && (
          <Box sx={{ maxWidth: "50%" }}>
            {/* <DataGrid
              loading={!data}
              rows={data}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
            /> */}
          </Box>
        )}
      </CustomTabPanel>
    </Base>
  )
}

export default ClaimantDashboard

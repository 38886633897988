import "./MessageBoard.css"

const MessageBoard = ({ messages }) => {
    return (
        <div className="message-board">
        <h1 className="message-header">Message board</h1>
          {messages.map((message) => (
            <div key={message.id} className="message-item">
              <div className="message-content">{message.content}</div>
              <div className="timestamp">{message.timestamp}</div>
            </div>
          ))}
        </div>
      );
    };

export default MessageBoard;  
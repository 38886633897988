import { withStyles } from "@mui/styles"
import { Tooltip } from "@mui/material"
import { Warning, Cancel } from "@mui/icons-material"

export function validateRecordedHours(hoursCharged) {
  return hoursCharged >= 0 && hoursCharged <= 80
}

const WarningsErrorsTooltip = withStyles({
  tooltip: {
    color: "red",
    backgroundColor: "white",
    fontSize: 12,
  },
})(Tooltip)

class GFEBS_CEFMS_Table_Utils {
  static Tabs = {
    GFEBS_DATA_REPORT1: "GFEBS Data Report 1",
    GFEBS_DATA_REPORT2: "GFEBS Data Report 2",
    CEFMS_DATA: "CEFMS Data",
  }

  static TabCSVFileNames = {
    [this.Tabs.GFEBS_DATA_REPORT1]: "REPORT ONE_GfebsRecords.csv",
    [this.Tabs.GFEBS_DATA_REPORT2]: "REPORT TWO_GfebsRecords.csv",
    [this.Tabs.CEFMS_DATA]: "Cefms.csv",
  }

  static ReportType = {
    GFEBS_R1: "ONE",
    GFEBS_R2: "TWO",
  }

  static CEFMSDataFields = {
    AMSCO_CODE: "AMSCOCode",
    PAY_PERIOD_END_DATE: "PayPeriodEndDate",
    EMPLOYEE_IDENTIFIER: "EmployeeIdentifier",
    AMSCO_NAME: "AMSCOName",
    POSITION: "Position",
    HOURS_TYPE_CODE: "HoursTypeCode",
    WORK_CATEGORY_DESCRIPTION: "WorkCategoryDescription",
    LABOR_HOURS: "LaborHours",
  }

  static GFEBSDataFields = {
    FUND: "Fund",
    FUNCTIONAL_AREA: "FunctionalArea",
    FUNDS_CENTER: "FundsCenter",
    PARTNER_FUND: "PartnerFund",
    PARTNER_FUNCTIONAL_AREA: "PartnerFunctionalArea",
    PARTNER_FUNDS_CENTER: "PartnerFundsCenter",
    PARTNER_FUNDS_CENTER_DESCRIPTION: "PartnerFundsCenterDescription",
    COST_CENTER: "CostCenter",
    PERSONNEL_NUMBER: "PersonnelNumber",
    GRC_CODE: "GRC/TypeHourCode",
    GRC_CODE_DESCRIPTION: "GRC/TypeHourCodeDescription",
    COST_ELEMENT: "CostElement",
    PARTNER_WBS_ELEMENT: "PartnerWBSElement",
    FMS_WORK_CATEGORY: "FMSWorkCategory",
    FMS_WORK_CLASSIFICATION: "FMSWorkClassification",
    FMS_WORK_CATEGORY_ELEMENT: "FMSWorkCategoryElement",
    FMS_WORK_CATEGORY_ELEMENT_DESCRIPTION: "FMSWorkCategoryElementDescription",
    PAY_PERIOD_END_DATE: "PayPeriodEndDate",
    CHARGED_HOURS: "ChargedHours<Time>",
    AMOUNT_CHARGED: "AmountCharged<Detail>",
    STANDARD_RATE_CHARGED: "StandardRateCharged",
  }

  static GFEBSR2DataFields = {
    FUND: "Fund",
    FUNCTIONAL_AREA: "FunctionalArea",
    FUNDS_CENTER: "FundsCenter",
    FUNDS_CENTER_DESCRITION: "FundsCenterDescription",
    PARTNER_FUND: "PartnerFund",
    PARTNER_FUNCTIONAL_AREA: "PartnerFunctionalArea",
    PARTNER_FUNDS_CENTER: "PartnerFundsCenter",
    COST_CENTER: "CostCenter",
    PERSONNEL_NUMBER: "PersonnelNumber",
    GRC_CODE: "GRC/TypeHourCode",
    COST_ELEMENT: "CostElement",
    PARTNER_WBS_ELEMENT: "PartnerWBSElement",
    FMS_WORK_CATEGORY_ELEMENT: "FMSWorkCategoryElement",
    PAY_PERIOD_END_DATE: "PayPeriodEndDate",
    CHARGED_HOURS: "ChargedHours<Time>",
    AMOUNT_CHARGED: "AmountCharged<Detail>",
    STANDARD_RATE_CHARGED: "StandardRateCharged",
  }

  static ExpectedGFEBSR1Headers = Object.values(this.GFEBSDataFields).slice(0, -2)
  static ExpectedGFEBSR2Headers = Object.values(this.GFEBSR2DataFields).slice(0, -2)

  static ExpectedCEFMSHeaders = [
    this.CEFMSDataFields.AMSCO_CODE,
    this.CEFMSDataFields.PAY_PERIOD_END_DATE,
    this.CEFMSDataFields.EMPLOYEE_IDENTIFIER,
    this.CEFMSDataFields.AMSCO_NAME,
    this.CEFMSDataFields.POSITION,
    this.CEFMSDataFields.HOURS_TYPE_CODE,
    this.CEFMSDataFields.WORK_CATEGORY_DESCRIPTION,
    this.CEFMSDataFields.LABOR_HOURS,
  ]

  static NonNullableFields = {
    GFEBS_DATA: [],
    CEFMS_DATA: [],
  }

  static DateFields = {
    GFEBS_DATA: [this.GFEBSDataFields.PAY_PERIOD_END_DATE],
    CFEMS_DATA: [this.CEFMSDataFields.PAY_PERIOD_END_DATE],
  }

  static NumericFields = {
    GFEBS_DATA: [this.GFEBSDataFields.CHARGED_HOURS],
    CFEMS_DATA: [this.CEFMSDataFields.LABOR_HOURS],
  }

  static GFEBS_R1_COLS = [
    {
      field: this.GFEBSDataFields.FUND,
      headerName: "Fund",
      flex: 1,
      minWidth: 150,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSDataFields.FUNCTIONAL_AREA,
      headerName: "Functional Area",
      flex: 1,
      minWidth: 150,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSDataFields.FUNDS_CENTER,
      headerName: "Funds Center",
      flex: 1,
      minWidth: 150,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSDataFields.PARTNER_FUND,
      headerName: "Partner Fund",
      flex: 1,
      minWidth: 150,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSDataFields.PARTNER_FUNCTIONAL_AREA,
      headerName: "Partner Functional Area",
      flex: 2,
      minWidth: 250,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSDataFields.PARTNER_FUNDS_CENTER,
      headerName: "Partner Funds Center",
      flex: 2,
      minWidth: 200,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSDataFields.PARTNER_FUNDS_CENTER_DESCRIPTION,
      headerName: "Partner Funds Center Description",
      flex: 3,
      minWidth: 300,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSDataFields.COST_CENTER,
      headerName: "Cost Center",
      flex: 1,
      minWidth: 150,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSDataFields.PERSONNEL_NUMBER,
      headerName: "Personnel Number",
      flex: 1,
      minWidth: 175,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSDataFields.GRC_CODE,
      headerName: "GRC/Type Hour Code",
      flex: 2,
      minWidth: 200,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSDataFields.GRC_CODE_DESCRIPTION,
      headerName: "GRC/Type Hour Code Description",
      flex: 3,
      minWidth: 300,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSDataFields.COST_ELEMENT,
      headerName: "Cost Element",
      flex: 1,
      minWidth: 150,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSDataFields.PARTNER_WBS_ELEMENT,
      headerName: "Partner WBS Element",
      flex: 2,
      minWidth: 200,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSDataFields.FMS_WORK_CATEGORY,
      headerName: "FMS Work Category",
      flex: 2,
      minWidth: 200,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSDataFields.FMS_WORK_CLASSIFICATION,
      headerName: "FMS Work Classification",
      flex: 2,
      minWidth: 225,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSDataFields.FMS_WORK_CATEGORY_ELEMENT,
      headerName: "FMS Work Category Element",
      flex: 2,
      minWidth: 250,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSDataFields.FMS_WORK_CATEGORY_ELEMENT_DESCRIPTION,
      headerName: "FMS Work Category Element Description",
      flex: 3,
      minWidth: 350,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSDataFields.PAY_PERIOD_END_DATE,
      headerName: "Pay Period End Date",
      flex: 2,
      minWidth: 200,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSDataFields.CHARGED_HOURS,
      headerName: "Charged Hours <Time>",
      flex: 2,
      minWidth: 225,
      type: "number",
      headerClassName: "--RFI-grid-header",
      renderCell: (params) => {
        // Render in $ format
        if (typeof params?.value === "number") {
          return `${params?.value.toFixed(2)}`
        }
        return null
      },
    },
    {
      field: "Warnings_Errors",
      headerName: "Warnings/Errors",
      sortable: false,
      flex: 3,
      minWidth: 350,
      headerClassName: "--RFI-grid-warning-header",
      headerAlign: "center",
      renderCell: (params) => {
        if (params?.value) {
          let tooltipMsg = ""
          let lineNumber = null
          if (params?.value?.errors?.length > 0) {
            lineNumber = params?.value?.errors[0]?.rowNumber
            tooltipMsg += `Line Number ${lineNumber}) `
          } else if (params?.value?.warnings?.length > 0) {
            lineNumber = params?.value?.warnings[0]?.rowNumber
            tooltipMsg += `Line Number ${lineNumber}) `
          }
          params?.value?.errors?.forEach((error, index) => (tooltipMsg += `E${index + 1}) ${error.message}\n`))
          params?.value?.warnings?.forEach((warning, index) => (tooltipMsg += `W${index + 1}) ${warning.message}\n`))
          return (
            <WarningsErrorsTooltip
              title={tooltipMsg}
              placement="bottom"
            >
              <div className="--RFI-warning-errors-icons">
                {params?.value?.warnings?.length > 0 && <Warning />}
                {params?.value?.errors?.length > 0 && <Cancel />}
              </div>
            </WarningsErrorsTooltip>
          )
        }
        return null
      },
    },
  ]

  static GFEBS_R2_COLS = [
    {
      field: this.GFEBSR2DataFields.FUND,
      headerName: "Fund",
      flex: 1,
      minWidth: 150,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSR2DataFields.FUNCTIONAL_AREA,
      headerName: "Functional Area",
      flex: 1,
      minWidth: 150,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSR2DataFields.FUNDS_CENTER,
      headerName: "Funds Center",
      flex: 1,
      minWidth: 150,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSR2DataFields.FUNDS_CENTER_DESCRITION,
      headerName: "Funds Center Description",
      flex: 3,
      minWidth: 300,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSR2DataFields.PARTNER_FUND,
      headerName: "Partner Fund",
      flex: 1,
      minWidth: 150,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSR2DataFields.PARTNER_FUNCTIONAL_AREA,
      headerName: "Partner Functional Area",
      flex: 2,
      minWidth: 250,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSR2DataFields.PARTNER_FUNDS_CENTER,
      headerName: "Partner Funds Center",
      flex: 2,
      minWidth: 200,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSR2DataFields.COST_CENTER,
      headerName: "Cost Center",
      flex: 1,
      minWidth: 150,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSR2DataFields.PERSONNEL_NUMBER,
      headerName: "Personnel Number",
      flex: 1,
      minWidth: 175,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSR2DataFields.GRC_CODE,
      headerName: "GRC/Type Hour Code",
      flex: 2,
      minWidth: 200,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSR2DataFields.COST_ELEMENT,
      headerName: "Cost Element",
      flex: 1,
      minWidth: 150,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSR2DataFields.PARTNER_WBS_ELEMENT,
      headerName: "Partner WBS Element",
      flex: 2,
      minWidth: 200,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSR2DataFields.FMS_WORK_CATEGORY_ELEMENT,
      headerName: "FMS Work Category Element",
      flex: 2,
      minWidth: 250,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSR2DataFields.PAY_PERIOD_END_DATE,
      headerName: "Pay Period End Date",
      flex: 2,
      minWidth: 200,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.GFEBSR2DataFields.CHARGED_HOURS,
      headerName: "Charged Hours <Time>",
      flex: 2,
      type: "number",
      minWidth: 225,
      headerClassName: "--RFI-grid-header",
      renderCell: (params) => {
        // Render in $ format
        if (typeof params?.value === "number") {
          return `$${params?.value.toFixed(2)}`
        }
        return null
      },
    },
    {
      field: "Warnings_Errors",
      headerName: "Warnings/Errors",
      sortable: false,
      flex: 3,
      minWidth: 350,
      headerClassName: "--RFI-grid-warning-header",
      headerAlign: "center",
      renderCell: (params) => {
        if (params?.value) {
          let tooltipMsg = ""
          let lineNumber = null
          if (params?.value?.errors?.length > 0) {
            lineNumber = params?.value?.errors[0]?.rowNumber
            tooltipMsg += `Line Number ${lineNumber}) `
          } else if (params?.value?.warnings?.length > 0) {
            lineNumber = params?.value?.warnings[0]?.rowNumber
            tooltipMsg += `Line Number ${lineNumber}) `
          }
          params?.value?.errors?.forEach((error, index) => (tooltipMsg += `E${index + 1}) ${error.message}\n`))
          params?.value?.warnings?.forEach((warning, index) => (tooltipMsg += `W${index + 1}) ${warning.message}\n`))
          return (
            <WarningsErrorsTooltip
              title={tooltipMsg}
              placement="bottom"
            >
              <div className="--RFI-warning-errors-icons">
                {params?.value?.warnings?.length > 0 && <Warning />}
                {params?.value?.errors?.length > 0 && <Cancel />}
              </div>
            </WarningsErrorsTooltip>
          )
        }
        return null
      },
    },
  ]

  static CEFMS_COLS = [
    {
      field: this.CEFMSDataFields.AMSCO_CODE,
      headerName: "AMSCO Code",
      flex: 1,
      minWidth: 150,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.CEFMSDataFields.PAY_PERIOD_END_DATE,
      headerName: "Pay Period End Date",
      flex: 2,
      minWidth: 200,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.CEFMSDataFields.EMPLOYEE_IDENTIFIER,
      headerName: "Employee Identifier",
      flex: 2,
      minWidth: 200,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.CEFMSDataFields.AMSCO_NAME,
      headerName: "AMSCO Name",
      flex: 3,
      minWidth: 300,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.CEFMSDataFields.POSITION,
      headerName: "Position",
      flex: 2,
      minWidth: 225,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.CEFMSDataFields.HOURS_TYPE_CODE,
      headerName: "Hours Type Code",
      flex: 1,
      minWidth: 175,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.CEFMSDataFields.WORK_CATEGORY_DESCRIPTION,
      headerName: "Work Category Description",
      flex: 2,
      minWidth: 250,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: this.CEFMSDataFields.LABOR_HOURS,
      headerName: "Labor Hours",
      type: "number",
      flex: 1,
      minWidth: 150,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: "Warnings_Errors",
      headerName: "Warnings/Errors",
      sortable: false,
      flex: 3,
      minWidth: 350,
      headerClassName: "--RFI-grid-warning-header",
      headerAlign: "center",
      renderCell: (params) => {
        if (params?.value) {
          let tooltipMsg = ""
          let lineNumber = null
          if (params?.value?.errors?.length > 0) {
            lineNumber = params?.value?.errors[0]?.rowNumber
            tooltipMsg += `Line Number ${lineNumber}) `
          } else if (params?.value?.warnings?.length > 0) {
            lineNumber = params?.value?.warnings[0]?.rowNumber
            tooltipMsg += `Line Number ${lineNumber}) `
          }
          params?.value?.errors?.forEach((error, index) => (tooltipMsg += `E${index + 1}) ${error.message}\n`))
          params?.value?.warnings?.forEach((warning, index) => (tooltipMsg += `W${index + 1}) ${warning.message}\n`))
          return (
            <WarningsErrorsTooltip
              title={tooltipMsg}
              placement="bottom"
            >
              <div className="--RFI-warning-errors-icons">
                {params?.value?.warnings?.length > 0 && <Warning />}
                {params?.value?.errors?.length > 0 && <Cancel />}
              </div>
            </WarningsErrorsTooltip>
          )
        }
        return null
      },
    },
  ]

  static UrlApiTypes = {
    [this.Tabs.GFEBS_DATA_REPORT1]: {
      record: "GfebsRecords",
      rfi: "GfebsReportRfis",
      rfiId: "gfebsReportRfiId",
      columns: this.GFEBS_R1_COLS,
      headers: Object.values(this.GFEBSDataFields).slice(0, -3),
    },
    [this.Tabs.GFEBS_DATA_REPORT2]: {
      record: "GfebsRecords",
      rfi: "GfebsReportRfis",
      rfiId: "gfebsReportRfiId",
      columns: this.GFEBS_R2_COLS,
      headers: Object.values(this.GFEBSR2DataFields).slice(0, -3),
    },
    [this.Tabs.CEFMS_DATA]: {
      record: "Cefms",
      rfi: "CefmsRfis",
      rfiId: "cefmsRfiId",
      columns: this.CEFMS_COLS,
      headers: Object.values(this.CEFMSDataFields),
    },
  }

  static RFIInstructions = {
    LOGIN: [
      "The user logs in to the CWAT-WEB System and navigates to the GFEBS/CEFMS RFI CSV Upload View.",
      "Ribbon style tab is immediately displayed showing GFEBS Class and CEFMS Class RFI upload options with an initial empty table of records. The “Submit RFI Data” button is disabled as no information has been uploaded by the CWAT Organization user.",
    ],
    UPLOAD_SUBMISSION: [
      "<include> Log In",
      "The user clicks on the RFI tab of choice (i.e., GFEBS DATA, CEFMS DATA).",
      "The user clicks on the “Upload CSV File” button and a “File Upload” dialog window is displayed prompting the user to navigate to the appropriate CSV file (for the selected RFI) located on the local (client) system.",
      "The user selects the CSV file for upload, and the file is uploaded for processing.",
      "The uploaded file is processed, and the table is populated with the appropriate CSV data.",
      "An error icon is displayed with records having errors and a warning icon is displayed with records having warnings.",
      "If there are errors/warnings that warrant correction, the user logs out of the system, resolves the data issues, exports a corrected CSV file, and starts the process over at step 1.",
    ],
    RESUBMISSION: [
      "<include> Log In",
      "The user clicks on the RFI tab of choice (i.e., GFEBS DATA, CEFMS DATA).",
      "The user clicks on the “STATUS” textual link to review the rejection details.",
      "The user resolves the issues outlined in the rejection details and creates a corrected RFI CSV data file. Note: The user must log out of the CWAT-WEB system and access the system that created the CSV file.",
      "<include> Log In",
      "The user clicks on the “Upload CSV File” button and a “File Upload” dialog window is displayed prompting the user to navigate to the corrected CSV file located on the local (client) system.",
      "The user selects the corrected CSV file for upload, and the file is uploaded for processing.",
      "The uploaded file is processed, and the table is populated with the CSV data.",
      "An error icon is displayed with records having errors and a warning icon is displayed with records having warnings.",
      "If there are errors/warnings, the user logs out of the system, resolves the data issues, exports a corrected CSV, logs back in and starts the process at step 6.",
    ],
    REVIEW: [
      "<include> Log In",
      "The user clicks on the RFI tab of choice to review the desired DSAMS/GFEBS RFI status.",
      "The user observes the current RFI status indicated with the “STATUS” textual link.",
    ],
  }
}

export default GFEBS_CEFMS_Table_Utils

import React from "react"
import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button } from "@mui/material"

export const RequestResubmissionWarningModal = ({ open, setOpen, requestResubmission }) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Request Resubmission?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          An admin will be notified that you are requesting a resubmission of this RFI. You will be able to resubmit once an admin has approved your
          request.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          onClick={() => {
            requestResubmission()
            setOpen(false)
          }}
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import { v4 as uuidv4 } from "uuid"
// import { DataGrid } from "@mui/x-data-grid"
import {
  DataGridPremium,
  useGridApiRef,
  GridColumnMenu,
  GridPagination,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
} from "@mui/x-data-grid-premium"

// Custom
import CustomGridToolbar from "../../../custom/CustomGridToolbar"

// Styles
import "./styles/SWRFITabStyles.css"
import { Box, Typography } from "@mui/material"

const SWRFITab = ({ gridTitle, selectedRows, selectedCols, showBusyDialog, handleRowUpdate, canEditCell }) => {
  const apiRef = useGridApiRef()

  // ** Functions
  const handleUpdateRow = (params) => {
    const rowIds = apiRef.current.getAllRowIds()
    let totalRowId = rowIds[rowIds.length - 1]
    apiRef.current.updateRows([{ id: totalRowId }])
  }

  const Toolbar = () => {
    // return <CustomGridToolbar title="Hours Distribution" />
    return (
      <Box style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", color: "white", backgroundColor: "#0e2d57" }}>
        <Typography variant="h5">{gridTitle}</Typography>
      </Box>
    )
  }

  return (
    <div style={{ width: "100%", minHeight: 550, padding: "10px" }}>
      <DataGridPremium
        apiRef={apiRef}
        rows={selectedRows}
        columns={selectedCols}
        loading={showBusyDialog}
        slots={{
          toolbar: Toolbar,
        }}
        hideFooter
        initialState={{
          columns: {
            columnVisibilityModel: { Id: false },
          },
          filter: {
            filterModel: {
              items: [],
              quickFilterExcludeHiddenColumns: true,
            },
          },
        }}
        sx={{
          fontFamily: "Roboto",
          minHeight: "100%",
          fontSize: "14px",
          "& .MuiDataGrid-columnHeaders": {},
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
          "& .MuiDataGrid-cell": {
            padding: "10px",
          },
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
        getRowId={() => uuidv4()}
        density="compact"
        isCellEditable={(params) => canEditCell(params)}
        processRowUpdate={handleRowUpdate}
        onProcessRowUpdateError={(e) => {
          if (e) console.error(e)
        }}
        getRowClassName={() => {
          return "--RFI-SATFA-grid-header"
        }}
        // sorting: { sortModel: sortModel },
        // experimentalFeatures={{ newEditingApi: true }}
        // editMode="row"
      />
    </div>
  )
}

export default SWRFITab

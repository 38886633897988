import { useContext, useEffect, useState } from "react"

// Libraries
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

// MUI Components
import { Drawer } from "./Drawer"
import {
  Box,
  Button,
  Collapse,
  Container,
  CssBaseline,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Slide,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material"

import { styled } from "@mui/material/styles"

import logo from "../../../CWATImages/dasadec-logo.png"

// MUI Icons
import { ChevronRight, ChevronLeft, AdminPanelSettings } from "@mui/icons-material"
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart"
import ContentPasteIcon from "@mui/icons-material/ContentPaste"
import ChecklistIcon from "@mui/icons-material/Checklist"
import MenuIcon from "@mui/icons-material/Menu"
import PeopleIcon from "@mui/icons-material/People"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import CircleIcon from "@mui/icons-material/Circle"

// Custom
import Banner from "../Banner"
import AppContext from "../../../AppContext"
import AppBar from "./AppBar"

// Styles
import "./styles/BaseStyles.css"

const DrawerHeader = styled("div")(({}) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  // necessary for content to be below app bar
}))

const Base = (props) => {
  const { children, organizationList } = props

  // ** --------------***     [ ~ VARS ~ ]     ***-------------- **
  const history = useHistory()
  const context = useContext(AppContext)
  const { drawerOpen, setDrawerOpen, allOrganizations, allFiscalCycles } = context
  const selectedPathname = history.location.pathname
  const classificationData = JSON.parse(sessionStorage.getItem("classificationData"))
  const menuOptions = [
    {
      title: "Claimant Dashboard",
      icon: <ContentPasteIcon />,
      pathname: "/dashboard",
      expand: false,
    },
    {
      title: "Workload",
      icon: <StackedBarChartIcon />,
      expand: true,
      expandOptions: [
        {
          title: "DSAMS/CISIL",
          pathname: "DSAMS-CISIL-RFIs",
        },
        {
          title: "ACC",
          pathname: "ACC-RFIs",
        },
        {
          title: "SATFA",
          pathname: "SATFA-RFIs",
        },
      ],
    },
    {
      title: "Workload Validation",
      icon: <ChecklistIcon />,
      expand: true,
      expandOptions: [
        {
          title: "USACE",
          pathname: "PEO-PM-RFIs",
        },
        {
          title: "PEO PM",
          pathname: "PEO-PM-RFIs",
        },
        {
          title: "SATMO",
          pathname: "PEO-PM-RFIs",
        },
      ],
    },
    {
      title: "Workforce",
      icon: <PeopleIcon />,
      expand: true,
      expandOptions: [
        {
          title: "GFEBS/CFEMS",
          pathname: "GFEBS-CEFMS-RFIs",
        },
        {
          title: "Supplemental Workforce",
          pathname: "SupplementalWorkforce-RFIs",
        },
      ],
    },
    {
      title: "Admin",
      icon: <AdminPanelSettings />,
      expand: true,
      expandOptions: [
        {
          title: "FMS Org POCs",
          pathname: "/organization-POCs",
        },
        {
          org: "DASA DE&C",
          pathname: "/users",
          title: "Users",
        },
        {
          org: "DASA DE&C",
          pathname: "/SAPBR",
          title: "SAPBR",
        },
        {
          org: "DASA DE&C",
          pathname: "/TDA-RFIs",
          title: "TDA",
        },
        {
          org: "DASA DE&C",
          pathname: "/FiscalCycles",
          title: "Fiscal Cycles",
        },
        {
          org: "DASA DE&C",
          pathname: "/Dashboards",
          title: "Dashboards",
        },
        {
          org: "DASA DE&C",
          pathname: "/Models",
          title: "All Models",
        },
        {
          org: "DASA DE&C",
          pathname: "/Reference",
          title: "Reference Tables",
        },
      ],
    },
  ]

  // ** --------------***     [ ~ HOOKS ~ ]     ***-------------- **
  // const [drawerOpen, setDrawerOpen] = useState(false)
  const [navItemExpand, setNavItemExpand] = useState({
    Workload: false,
    "Workload Validation": false,
    Workforce: false,
  })

  // ** --------------***     [ ~ FUNCTIONS ~ ]     ***-------------- **
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
  }

  const handleClick = (title, pathname) => {
    if (pathname) {
      return handleNavigate(pathname)
    }
    setNavItemExpand((prevState) => {
      return {
        ...prevState,
        [title]: !navItemExpand[title],
      }
    })
  }

  const getExpandIcon = (title) => {
    return navItemExpand[title] ? <ExpandLess /> : <ExpandMore />
  }

  const handleNavigate = (pathname) => {
    history.push({
      pathname: pathname,
    })
  }

  const getExpandMenuItems = (options) => {
    return options.map((item) => {
      return (
        <ListItemButton onClick={() => handleNavigate(item?.pathname)}>
          <ListItemIcon sx={{ display: "flex", justifyContent: "flex-end", marginRight: "8px" }}>
            <CircleIcon sx={{ fontSize: "5px" }} />
          </ListItemIcon>
          <ListItemText primary={item.title} />
        </ListItemButton>
      )
    })
  }

  const getMenuItems = (options) => {
    return options.map((item) => {
      let { title, icon, pathname, expand, expandOptions } = item
      return (
        <>
          <ListItemButton
            key={title}
            onClick={() => handleClick(title, pathname)}
            selected={pathname == selectedPathname}
            disableRipple
          >
            <ListItemIcon>
              <Tooltip
                title={title}
                placement="right"
                arrow
              >
                {icon}
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary={title} />
            {expand && getExpandIcon(title)}
          </ListItemButton>
          {expand && (
            <Collapse
              in={navItemExpand[title]}
              timeout="auto"
              unmountOnExit
            >
              <List
                component="div"
                disablePadding
              >
                {getExpandMenuItems(expandOptions)}
              </List>
            </Collapse>
          )}
        </>
      )
    })
  }

  const getOrgOptions = () => {
    // TODO: if currently selected org isn't on the filtered list we need to select the first org on the list
    //       trying to keep this small so I can get to work on the other pages but will make a ticket for this.
    if (organizationList === undefined) {
      return allOrganizations.map((item) => {
        return (
          <MenuItem
            sx={{ minWidth: "150px" }}
            value={item}
          >
            {item.name}
          </MenuItem>
        )
      })
    } else {
      return organizationList.map((item) => {
        return (
          <MenuItem
            sx={{ minWidth: "150px" }}
            value={item}
          >
            {item.name}
          </MenuItem>
        )
      })
    }
  }

  const getFYOptions = () => {
    return allFiscalCycles.map((item) => {
      return (
        <MenuItem
          sx={{ minWidth: "150px" }}
          value={item}
        >
          {item.fiscalYear}
        </MenuItem>
      )
    })
  }

  // ** --------------***     [ ~ RENDER ~ ]     ***-------------- **
  return (
    <>
      <Banner
        data={classificationData}
        // position={{ position: "fixed", top: "0" }}
      />
      <Box
        sx={{
          display: "flex",
        }}
      >
        <CssBaseline />

        {/* DRAWER */}
        <Drawer
          variant="permanent"
          open={drawerOpen}
          sx={{
            zIndex: 6,
          }}
        >
          <DrawerHeader>
            <IconButton onClick={toggleDrawer}>{!drawerOpen ? <ChevronRight /> : <ChevronLeft />}</IconButton>
          </DrawerHeader>
          <List component="nav">
            {drawerOpen && <ListSubheader component="div">Menu</ListSubheader>}
            {getMenuItems(menuOptions)}
          </List>
          <Toolbar
            sx={{
              minWidth: "100%",
              display: "flex",
            }}
          >
            <IconButton
              onClick={toggleDrawer}
              edge="start"
              sx={[
                {
                  marginRight: 5,
                },
                drawerOpen && { display: "none" },
              ]}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </Drawer>

        {/* APPBAR */}
        <AppBar
          position="fixed"
          open={drawerOpen}
          sx={{
            display: "flex",
            backgroundColor: "#3F616D",
            pr: "24px",
          }}
        >
          <Toolbar sx={{ display: "flex", justifyContent: "flex-end", maxHeight: "85px", zIndex: 5 }}>
            <Box sx={{ display: "flex", width: "100%", padding: "0.5% .5%" }}>
              {/* CWAT LOGO */}
              <img
                src={logo}
                style={{ height: "50px", marginRight: "10px" }}
              />

              {/* HEADER/SUBHEADER */}
              <Box
                className="flexColumn"
                sx={{ justifyContent: "center" }}
              >
                <Typography
                  variant="pageHeader"
                  className="titleTest"
                  sx={{
                    display: "flex",
                  }}
                >
                  Common Workforce Analysis Tool (CWAT)
                </Typography>
                <Typography variant="pageText">{JSON.parse(sessionStorage.getItem("ORG"))?.name}</Typography>
              </Box>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", maxHeight: "50px" }}>
              <FormControl
                sx={{
                  display: "flex",
                  minWidth: "150px",
                  marginRight: "15px",
                  maxHeight: "50px",
                  color: "white",
                }}
              >
                <InputLabel sx={{ color: "white" }}>Organization</InputLabel>
                <Select
                  label="Organization"
                  variant="outlined"
                  value={JSON.parse(sessionStorage.getItem("ORG"))}
                  // value={context.selectedOrg}
                  renderValue={(params) => params.name}
                  onChange={(e) => sessionStorage.setItem("ORG", JSON.stringify(e.target.value))}
                  // onChange={(e) => context.setSelectedOrg(e.target.value)}
                  autoWidth
                  sx={{
                    color: "white",
                    ".MuiSelect-select": {
                      maxHeight: "100%",
                      padding: "7px 15px",
                    },
                    ".MuiInputBase-root": {
                      borderColor: "white",
                    },
                  }}
                >
                  {getOrgOptions()}
                </Select>
              </FormControl>

              {context.allFiscalCycles.length > 1 && (
                <FormControl
                  sx={{
                    display: "flex",
                    minWidth: "150px",
                    marginRight: "15px",
                    maxHeight: "50px",
                    color: "white",
                  }}
                >
                  <InputLabel sx={{ color: "white" }}>Fiscal Cycle</InputLabel>
                  <Select
                    label="Fiscal Cycle"
                    variant="outlined"
                    value={JSON.parse(sessionStorage.getItem("FY"))}
                    // value={context.selectedFY || context.allFiscalCycles[0]}
                    renderValue={(params) => params.fiscalYear}
                    // onChange={(e) => context.setSelectedFY(e.target.value)}
                    onChange={(e) => sessionStorage.setItem("FY", JSON.stringify(e.target.value))}
                    autoWidth
                    className="selectTEST"
                    sx={{
                      color: "white",
                      ".MuiSelect-select": {
                        maxHeight: "100%",
                        padding: "7px 15px",
                      },
                      ".MuiInputBase-root": {
                        borderColor: "white",
                      },
                    }}
                  >
                    {getFYOptions()}
                  </Select>
                </FormControl>
              )}

              <Button
                variant="outlined"
                sx={{ color: "white", borderColor: "white" }}
              >
                Logout
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <Container
          component="main"
          sx={{ display: "flex", flexDirection: "column", flexGrow: 1, marginTop: "50px", marginLeft: "100px" }}
        >
          {children}
        </Container>

        <Banner
          data={classificationData}
          position={{ position: "fixed", bottom: "0" }}
        />
      </Box>
    </>
  )
}

export default Base

class MHWLUtils {
  static coreFunctions = {
    CF1: "Pre-Lor CF1",
    CF2: "Case Development CF2",
    CF3: "Case Execution CF3",
    CF4: "Case Closure CF4",
    CF5: "Business Sustainment CF5",
    CIVILIAN_MTX: "Civilian includes MTX",
  }

  static allCoreFunctions = [
    this.coreFunctions.CF1,
    this.coreFunctions.CF2,
    this.coreFunctions.CF3,
    this.coreFunctions.CF4,
    this.coreFunctions.CF5,
    this.coreFunctions.CIVILIAN_MTX,
  ]

  static gridFields = {
    ORGANIZATION: "Organization",
    CORE_FUNCTION: "Front Term",
    MH_FY: "MH FY(XX)",
    MH_FY_1: "MH FY(XX + 1)",
    MH_FY_2: "MH FY(XX + 2)",
    WL_FY: "WL FY(XX)",
    WL_FY_1: "WL FY(XX + 1)",
    WL_FY_2: "WL FY(XX + 2)",
    ABM: "ABM",
  }
}

export default MHWLUtils

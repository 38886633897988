import SAPBR_Utils from './SAPBR_Utils'
import { v4 as uuidv4 } from 'uuid';

class SAPBR_RMD_Data_Loader {

    static convertDataToTableRows(selectedTab, pageRecords, selectedFY) {
		const tmpTableRows = []
		switch (selectedTab) {
			case SAPBR_Utils.Tabs.SAPBR:
				pageRecords.forEach(d => {
					tmpTableRows.push({
						Id: d.id ? d.id : uuidv4(),
                        [SAPBR_Utils.SAPBRTableFields.FY]: selectedFY[SAPBR_Utils.SAPBRDataFields.FY],
                        [SAPBR_Utils.SAPBRTableFields.ORGANIZATION]: d[SAPBR_Utils.SAPBRDataFields.ORGANIZATION],
                        [SAPBR_Utils.SAPBRTableFields.UIC]: d[SAPBR_Utils.SAPBRDataFields.UIC],
                        [SAPBR_Utils.SAPBRTableFields.CWAT_ORGANIZATION]: d[SAPBR_Utils.SAPBRDataFields.CWAT_ORGANIZATION],
                        [SAPBR_Utils.SAPBRTableFields.APPROVED_WORK_YEAR_ONE]: d[SAPBR_Utils.SAPBRDataFields.APPROVED_WORK_YEAR_ONE],
                        [SAPBR_Utils.SAPBRTableFields.APPROVED_WORK_YEAR_TWO]: d[SAPBR_Utils.SAPBRDataFields.APPROVED_WORK_YEAR_TWO],
                        [SAPBR_Utils.SAPBRTableFields.APPROVED_WORK_YEAR_THREE]: d[SAPBR_Utils.SAPBRDataFields.APPROVED_WORK_YEAR_THREE],
                        [SAPBR_Utils.SAPBRTableFields.APPROVED_WORK_YEAR_FOUR]: d[SAPBR_Utils.SAPBRDataFields.APPROVED_WORK_YEAR_FOUR],
                        [SAPBR_Utils.SAPBRTableFields.COMMENTS]: d[SAPBR_Utils.SAPBRDataFields.COMMENTS],
					})
				})
				break
			case SAPBR_Utils.Tabs.RMD:
				pageRecords.forEach(d => {
					tmpTableRows.push({
						Id: d.id ? d.id : uuidv4(),
                        [SAPBR_Utils.RMDTableFields.ORGANIZATION]: d[SAPBR_Utils.RMDDataFields.ORGANIZATION],
                        [SAPBR_Utils.RMDTableFields.UIC]: d[SAPBR_Utils.RMDDataFields.UIC],
                        [SAPBR_Utils.RMDTableFields.APPROVED_CIV_INCREASE]: d[SAPBR_Utils.RMDDataFields.APPROVED_CIV_INCREASE],
                        [SAPBR_Utils.RMDTableFields.APPROVED_CTR_INCREASE]: d[SAPBR_Utils.RMDDataFields.APPROVED_CTR_INCREASE],
                        [SAPBR_Utils.RMDTableFields.COMMENTS]: d[SAPBR_Utils.RMDDataFields.COMMENTS],
					})
				})
				break
			default:
				break
		}

        return tmpTableRows
    }
}

export default SAPBR_RMD_Data_Loader
import { MenuItem } from "@mui/material"

export const getSelectItems = (options) => {
  return options.map((item) => {
    return <MenuItem value={item}>{item}</MenuItem>
  })
}

export const getRequired = (formObj, form) => {
  let emptyFields = []
  formObj.forEach((item) => {
    if (item.required && !form[item.name]) {
      emptyFields.push(item.label)
    }
  })
  return emptyFields
}

export default (state, action) => {
  switch (action.type) {
    case "SELECTED_ORG":
      return { ...state, selectedOrg: action.payload }
    case "ORGS":
      return { ...state, allOrgs: action.payload }
    case "FILTERED_ORG_LIST":
      return { ...state, filteredOrgList: action.payload }
    case "SELECTED_FISCAL_CYCLE":
      return { ...state, selectedFiscalCycle: action.payload }
    case "FISCAL_CYCLES":
      return { ...state, fiscalCycles: action.payload }
    case "ACTIVE_FISCAL_CYCLE":
      return { ...state, activeFiscalCycle: action.payload }
    case "RFI_ORGANIZATIONS_MAPPING":
      return { ...state, rfiOrganizationsMapping: action.payload }
    case "SELECTED_TAB_RFI_MAPPING_KEY":
      return { ...state, selectedTabRfiMappingKey: action.payload }
    case "RFI_DASHBOARD_ORG_RFI_MAPPING":
      return { ...state, rfiDashboardOrgRfiMapping: action.payload }
    case "RFI_RECORD_COUNT_MAPPING":
      return { ...state, rfiRecordCountMapping: action.payload }
    case "FTE_DASHBOARD_ORG_RFI_MAPPING":
      return { ...state, fteDashboardOrgRfiMapping: action.payload }
    case "CAN_RUN_WORKLOAD_PROCEDURES":
      return { ...state, canRunWorkloadProcedures: action.payload }
    case "USER":
      return { ...state, userObj: action.payload }
    case "IS_LOGGED_IN":
      return { ...state, isLoggedIn: action.payload }
    default:
      return state
  }
}

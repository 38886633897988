// MUI
import { FormControl, InputLabel, Select, TextField } from "@mui/material"

// Custom
import { getSelectItems } from "./utils"

// Styles
import "./styles/CustomFormStyles.css"

const CustomForm = ({ form, formObj, handleChange }) => {
  const getForm = (obj) => {
    let { type, label, name } = obj
    switch (type) {
      case "textfield":
        return (
          <TextField
            className="customFormInput"
            label={label}
            name={name}
            value={form[name]}
            required={obj.required}
            onChange={handleChange}
          />
        )
      case "textarea":
        return (
          <TextField
            className="customFormInput"
            label={label}
            name={name}
            value={form[name]}
            multiline
            minRows={2}
            required={obj.required}
            onChange={handleChange}
          />
        )
      case "singleSelect":
        let optionValues = getSelectItems(obj.optionValues)
        return (
          <FormControl className="customFormInput">
            <InputLabel>{label}</InputLabel>
            <Select
              label={label}
              name={name}
              value={form[name]}
              required={obj.required}
              onChange={handleChange}
            >
              {optionValues}
            </Select>
          </FormControl>
        )
      case "number":
        return (
          <TextField
            className="customFormInput"
            type="number"
            label={label}
            name={name}
            value={form[name]}
            required={obj.required}
            onChange={handleChange}
          />
        )

      default:
        break
    }
  }
  return formObj.map((item) => {
    return getForm(item)
  })
}

export default CustomForm

import { v4 as uuidv4 } from "uuid"
import { DataGridPremium } from "@mui/x-data-grid-premium"

const MHWLGrid = ({ columns, data }) => {
  return (
    <div style={{ width: "100%", minHeight: 550, padding: "10px" }}>
      <DataGridPremium
        rows={data}
        columns={columns}
        loading={!data}
        hideFooter
        initialState={{
          columns: {
            columnVisibilityModel: { Id: false },
          },
          filter: {
            filterModel: {
              items: [],
              quickFilterExcludeHiddenColumns: true,
            },
          },
        }}
        sx={{
          fontFamily: "Roboto",
          minHeight: "100px",
          height: "100%",
          maxHeight: "75vh",
          fontSize: "14px",
          "& .MuiDataGrid-columnHeaders": {},
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
          "& .MuiDataGrid-cell": {
            padding: "10px",
          },
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
          "& .MuiTablePagination-selectLabel": {
            marginBottom: "0px",
          },
        }}
        getRowId={() => uuidv4()}
        getRowClassName={(params) => {
          if (params.indexRelativeToCurrentPage % 2 === 0) {
            return "even-row"
          } else return "odd-row"
        }}
        density="compact"
      />
    </div>
  )
}

export default MHWLGrid

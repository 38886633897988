import React from "react"
import { Modal } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { v4 as uuidv4 } from "uuid"
import { Close } from "@mui/icons-material"

const OrganizationRFIColumnTableModal = ({ open, setOpen, selectedTableRows, tableName }) => {
  const organizationRFIFieldMappingTableCols = [
    {
      field: "Id",
      headerName: "Id",
      hide: true,
      headerClassName: "--grid-header",
    },
    {
      field: "rfiColumns",
      headerName: "RFI Columns",
      flex: 1,
      minWidth: 150,
      headerClassName: "--grid-header",
    },
    {
      field: "fieldCheck1",
      headerName: "Field Check 1",
      flex: 1,
      minWidth: 150,
      headerClassName: "--grid-header",
    },
    {
      field: "fieldCheck2",
      headerName: "Field Check 2",
      flex: 1,
      minWidth: 150,
      headerClassName: "--grid-header",
    },
    {
      field: "fieldCheck3",
      headerName: "Field Check 3",
      flex: 1,
      minWidth: 150,
      headerClassName: "--grid-header",
    },
  ]

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="--RFI-disclaimer-modal-container">
        <div className="--RFI-disclaimer-modal-header">
          <h4>{tableName} Field Checks</h4>
          <Close
            fontSize="large"
            onClick={() => setOpen(false)}
          />
        </div>
        <div className="--RFI-disclaimer-modal-body">
          <DataGrid
            rows={selectedTableRows}
            columns={organizationRFIFieldMappingTableCols}
            density="compact"
            getRowId={() => uuidv4()}
            sx={{
              border: 2,
              borderColor: "black",
            }}
            initialState={{
              columns: {
                columnVisibilityModel: { Id: false },
              },
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

export default OrganizationRFIColumnTableModal

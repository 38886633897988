import { DataGridPremium, gridClasses } from "@mui/x-data-grid-premium"
import { alpha, styled } from "@mui/material/styles"
import { v4 as uuidv4 } from "uuid"

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      "&:hover": {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        },
      },
    },
  },
}))

const CustomDataGrid = ({ data, columns, initialState, handleSortModelChange }) => {
  return (
    <StripedDataGrid
      disableColumnMenu
      columns={columns}
      rows={data}
      getRowId={() => uuidv4()}
      getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
      initialState={initialState}
      density="compact"
      onSortModelChange={handleSortModelChange}
      hideFooter
      sx={{
        fontSize: ".8rem",
        minHeight: "50vh",
        maxHeight: "60vh",
      }}
    />
  )
}

export default CustomDataGrid

import React from "react"
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import "../RFI.css"

const TDA_Instructions_Modal = ({ disclaimerRFIModalOpen, setDisclaimerRFIModalOpen }) => {
  return (
    <Dialog
      open={disclaimerRFIModalOpen}
      onClose={() => setDisclaimerRFIModalOpen(false)}
    >
      <DialogTitle className="instruction-title">RFI Instructions - TDA</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          Using FMSWeb, go to reports, personnel, and filter MDEP on JDFM. Manually generate and compile reports for each FY. You will need last FY,
          current FY, and next FY. Add the FY and UIC columns. FY is based on the last two characters of the CCNUM. UIC is the last 6 characters of
          DOCNO.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDisclaimerRFIModalOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default TDA_Instructions_Modal

import { Box, Button } from "@mui/material"

const MHWLBtns = ({ fetchData }) => {
  return (
    <Box style={{ display: "flex", justifyContent: "flex-end", width: "100%", marginRight: "3.5rem" }}>
      <Button
        onClick={() => fetchData()}
        variant="outlined"
      >
        RUN MODELS
      </Button>
    </Box>
  )
}

export default MHWLBtns
